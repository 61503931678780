import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const guarantor = "/kategoris";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(guarantor, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getKategori(kategoriId) {
        return Repository.get(`${guarantor}/${kategoriId}`, defaultConfigs);
    },
    createKategori(payload) {
        return Repository.post(`${guarantor}`, payload);
    },
    updateKategori(payload) {
        return Repository.put(`${guarantor}`, payload);
    },
    deleteKategori(kategoriId) {
        return Repository.delete(`${guarantor}/${kategoriId}`);
    }
}
