import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const units = "/units";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(units, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getUnit(unitId) {
        return Repository.get(`${units}/${unitId}`);
    },
    createUnit(payload) {
        return Repository.post(`${units}`, payload);
    },
    updateUnit(payload) {
        return Repository.put(`${units}`, payload);
    },
    deleteUnit(unitId) {
        return Repository.delete(`${units}/${unitId}`);
    }
}
