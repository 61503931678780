import Repository from "./Repository";
import TempRepository from "./TempRepository";

const Holiday = "holiday";
const error = {
    message: "Error",
    status: 500
};
export default {
    errorHandler(error) {
        let errorM = `Error`;
        let statusErr = "";
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error) {
                    errorM = error.response.data.error;
                } else if (error.response.data.message) {
                    errorM = !_.isEmpty(error.response.data.message)
                        ? error.response.data.message
                        : "An Unknown Error Has Occured";
                }
            }
            if (error.response.status) {
                statusErr = error.response.status;
            }
        }
        return {
            error: errorM,
            status: statusErr,
            message: error.message
        };
    },
    getHolidays({ params }, source) {
        const randomNumber = Math.random() * 10;
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         if (randomNumber > 0) {
        //             resolve(dummy);
        //         } else {
        //             reject(error);
        //         }
        //     }, 200);
        // });
        // return TempRepository.get("/holidays", params);
        const config = {
            method: "get",
            url: `/${Holiday}s`,
            params: params
        }
        if (source) {
            config.cancelToken = source.token;
        }
        return TempRepository(config);
    },
    async getHolidaysModal({ params }, cancelToken) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 5) {
            //             resolve({ status: 200, data: payload });
            //         } else {
            //             reject({ status: 500, message: "Error" });
            //         }
            //     }, 2000);
            // });
            let config = {
                method: "get",
                url: `/${Holiday}s`,
                params: params
            };
            if (cancelToken) {
                config.cancelToken = cancelToken.token;
            }
            const response = await TempRepository(config);
            // console.log(response.status, "response.status");
            // console.log(response, "postHolidays 304");
            if (response.status == 200) {
                return response;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post Holidays");
            return this.errorHandler(error);
        }
    },
    async postHolidays(payload) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 5) {
            //             resolve({ status: 200, data: payload });
            //         } else {
            //             reject({ status: 500, message: "Error" });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "post",
                url: `/${Holiday}`,
                data: payload
            });
            console.log(response.status, "response.status");
            console.log(response, "postHolidays 304");
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post Holidays");
            return this.errorHandler(error);
        }
    },
    async putHolidays(payload) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 5) {
            //             resolve({
            //                 status: 200,
            //                 data: payload
            //             });
            //         } else {
            //             reject({
            //                 status: 500,
            //                 message: "Error"
            //             });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "put",
                url: `/${Holiday}`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw "error put Holidays";
            }
        } catch (error) {
            console.log(error, "put Holidays");
            return this.errorHandler(error);
        }
    },
    async deleteHolidays(payload) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 5) {
            //             resolve({
            //                 status: 200,
            //                 data: payload
            //             });
            //         } else {
            //             reject({
            //                 status: 500,
            //                 message: "Error"
            //             });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "delete",
                url: `/${Holiday}/${payload}`
            });
            console.log(response, response.status, "120 response");
            if (response.status == 204 || response.status == 200) {
                return response.data;
            } else {
                throw "error delete Holidays";
            }
        } catch (error) {
            console.log(error, "delete Holidays");
            return this.errorHandler(error);
        }
    },
    async getDoctorList(params) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 2) {
            //             resolve({
            //                 status: 200,
            //                 data: [
            //                     { id: 1, nama: "Abhi Rama" },
            //                     { id: 2, nama: "natalia" }
            //                 ]
            //             });
            //         } else {
            //             reject({
            //                 status: 500,
            //                 message: "Error"
            //             });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "get",
                url: `/dokters/dropdown`,
                params: params
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get doctor List");
            return this.errorHandler(error);
        }
    },
    async getPlatformList() {
        try {
            const randomNumber = Math.random() * 10;
            console.log(randomNumber, "randomNumber");
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 2) {
                        resolve({
                            status: 200,
                            data: [
                                { id: 201, nama: "PCR Drive Thru REPO" },
                                { id: 202, nama: "Klinik Drive Thru REPO" }
                            ]
                        });
                    } else {
                        reject({
                            status: 500,
                            message: "Error"
                        });
                    }
                }, 2000);
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw "error get Platform List";
            }
        } catch (error) {
            console.log(error, "get Platform List");
            return this.errorHandler(error);
        }
    },
    async getHolidayById(id) {
        try {
            console.log(id, "getHolidayById repo");
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 5) {
            //             resolve({
            //                 status: 200,
            //                 data: {
            //                     id: 1,
            //                     tanggalMulai: "2020-01-01",
            //                     tanggalAkhir: "2020-02-02",
            //                     type: "exclude",
            //                     dokters: [
            //                         {
            //                             id: 1,
            //                             nama: "Abhi Rama"
            //                         },
            //                         {
            //                             id: 2,
            //                             nama: "Natalia Estu"
            //                         },
            //                         {
            //                             id: 3,
            //                             nama: "Rizki"
            //                         }
            //                     ],
            //                     platforms: [
            //                         {
            //                             id: 201,
            //                             nama: "PCR Drive Thru"
            //                         }
            //                     ]
            //                 }
            //             });
            //         } else {
            //             reject({
            //                 status: 500,
            //                 message: "Error"
            //             });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "get",
                url: `/${Holiday}/${id}`
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw "error get Holiday By Id";
            }
        } catch (error) {
            console.log(error, "get Holiday By Id");
            return this.errorHandler(error);
        }
    }
};
