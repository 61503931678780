import Repository from "./Repository";
// import createRequestOption from "./RequestParamUtil";
import TempRepository from "./TempRepository";

export default {
    errorHandler(error) {
        let errorM = `Error`;
        let statusErr = "";
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error) {
                    errorM = error.response.data.error;
                } else if (error.response.data.message) {
                    errorM = !_.isEmpty(error.response.data.message)
                        ? error.response.data.message
                        : "An Unknown Error Has Occured";
                }
            }
            if (error.response.status) {
                statusErr = error.response.status;
            }
        }
        return {
            error: errorM,
            status: statusErr,
            message: error.message
        };
    },
    getKliniks({ params }) {
        // const randomNumber = Math.random() * 10;
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         if (randomNumber > 0) {
        //             resolve(dummyKlinik);
        //         } else {
        //             reject(dummyError);
        //         }
        //     }, 200);
        // });
        // return TempRepository.get("/holidays", params);
        return TempRepository({
            method: "get",
            url: `/kliniks`,
            params: params
        });
    },
    async postKlinik(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 9) {
            //             resolve({ status: 201, data: payload });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const response = await TempRepository({
                method: "post",
                url: `/klinik`,
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post klinik");
            return this.errorHandler(error);
        }
    },
    async getOneKlinik(id) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(oneKlinik);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const response = await TempRepository({
                method: "get",
                url: `/klinik/${id}`
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get one klinik");
            return this.errorHandler(error);
        }
    },
    async getConfirmation(id) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(dummyConfirmation);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const response = await TempRepository({
                method: "delete",
                url: `/klinik/confirm`,
                data: {
                    klinikId: id
                }
            });
            if (response.status == 200 || response.status == 204) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get confirmation");
            return this.errorHandler(error);
        }
    },
    async delKlinik(payload) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve({ status: 200, data: id });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const response = await TempRepository({
                method: "delete",
                url: `/klinik`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "delete klinik");
            return this.errorHandler(error);
        }
    },
    async putKlinik(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 9) {
            //             resolve({ status: 200, data: payload });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const response = await TempRepository({
                method: "put",
                url: `/klinik`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "put klinik");
            return this.errorHandler(error);
        }
    }
};
