export default function createRequestOption(req) {
    var specialKey = {
        sort: "sort",
        enumStages: "enumStages",
        jejaksEnumStages: "jejaksEnumStages",
        jejakTerakhirStages: "jejakTerakhirStages",
        jejakTerakhirStagesIn: "jejakTerakhirStage.in",
        tujuanRujukanId: "tujuanRujukanId",
        perujukKategoriId: "perujukKategoriId",
        tindakanParentId: "parentId.in",
        tindakanParentNull: "parentId.specified",
        idIn: "id.in",
        createdDateGTE: "createdDate.greaterOrEqualThan",
        createdDateLTE: "createdDate.lessOrEqualThan",
        jejakTerakhirTimestampGTE: "jejakTerakhirTimestamp.greaterOrEqualThan",
        jejakTerakhirTimestampLTE: "jejakTerakhirTimestamp.lessOrEqualThan"
    };
    var options = new URLSearchParams();
    if (req) {
        Object.keys(req).forEach(key => {
            if (specialKey.hasOwnProperty(key)) {
                req[key] = Array.isArray(req[key]) ? req[key] : [req[key]];
                req[key].forEach(val => {
                    options.append(specialKey[key], val);
                });
            } else {
                options.set(key, req[key]);
            }
        });
    }
    var request = {
        params: options
    };

    return request;
}
