import Repository from './RepositoryMandayauaa';
import createRequestOption from './RequestParamUtil';
export default {
    async getAllPaginate(url, req, totalData = [] , pages = 0, resolve , reject) {
        var parse = require('parse-link-header');
        if(req) {
            if(req.page) {
                req.page = pages;
            } else {
                req['page'] = pages;
            }
        } else {
            req = { page : pages };
        }
        // if(pages == 1){
        //     url = "aa";
        // }
        const defaultConfigs =  createRequestOption(req);
        await Repository.get(url, defaultConfigs).then(response => {
            const retriveData = totalData.concat(response.data);
            var parsed = parse(response.headers.link);
            if(parsed.next){
                this.getAllPaginate(url, req, retriveData, parsed.next.page, resolve, reject)
            } else {
                resolve( retriveData );
            }
        }).catch( error => {
            if ( error.response ) {
                reject(error.response.data)
            } else {
                reject(error.message);
            }
        })
    }
}
