import Repository from './RepositoryMandayauaa';
import paginateRepository from './AllPaginateRepository';
const tenantUser = "/tenant-users";
const defaultConfigs = { params: { "size": 1000 } };
import createRequestOption from './RequestParamUtil';
export default {
  get(req, resolve, reject) {
    new Promise((resolve, reject) => {
      paginateRepository.getAllPaginate(tenantUser, req, [], 0, resolve, reject);
    }).then((response) => {
      resolve({ data: response });
    }).catch(error => {
      reject(error);
    });
  },
  fetch(req) {
    const defaultConfigs = createRequestOption(req);
    return Repository.get(`${tenantUser}`, defaultConfigs);
  },
}
