import Repository from './Repository';

const statusSchedule = "/status-jadwal-dokters";
import paginateRepository from './AllPaginateRepository';
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(statusSchedule, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getStatusSchedule(statusScheduleId) {
        return Repository.get(`${statusSchedule}/${statusScheduleId}`);
    },
    createStatusSchedule(payload) {
        return Repository.post(`${statusSchedule}`, payload);
    },
    updateStatusSchedule(payload) {
        return Repository.put(`${statusSchedule}`, payload);
    },
    deleteStatusSchedule(statusScheduleId) {
        return Repository.delete(`${statusSchedule}/${statusScheduleId}`);
    }
}