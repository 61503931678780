import Repository from "./Repository";
// import createRequestOption from "./RequestParamUtil";
import TempRepository from "./TempRepository";

const subspe = `subspesialisasi`;
export default {
    errorHandler(error) {
        let errorM = `Error`;
        let statusErr = "";
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error) {
                    errorM = error.response.data.error;
                } else if (error.response.data.message) {
                    errorM = !_.isEmpty(error.response.data.message)
                        ? error.response.data.message
                        : "An Unknown Error Has Occured";
                }
            }
            if (error.response.status) {
                statusErr = error.response.status;
            }
        }
        return {
            error: errorM,
            status: statusErr,
            message: error.message
        };
    },
    getSubpspesialisasi({ params }) {
        const randomNumber = Math.random() * 10;
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         if (randomNumber > 0) {
        //             resolve(dummy2);
        //         } else {
        //             reject(dummyError);
        //         }
        //     }, 200);
        // });
        // return TempRepository.get("/holidays", params);
        return TempRepository({
            method: "get",
            url: `/${subspe}s`,
            params: params
        });
    },
    async postSubpspesialisasi(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise(
            //     (resolve, reject) => {
            //         setTimeout(() => {
            //             if (randomNumber > 0) {
            //                 resolve({
            //                     status: 201,
            //                     data: payload
            //                 });
            //             } else {
            //                 reject(error);
            //             }
            //         }, 1000);
            //     }
            // );
            const response = await TempRepository({
                method: "post",
                url: `/${subspe}`,
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post subspesialisasi");
            return this.errorHandler(error);
        }
    },
    async getOneSubpspesialisasi(id) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise(
            //     (resolve, reject) => {
            //         setTimeout(() => {
            //             if (randomNumber > 0) {
            //                 resolve({
            //                     status: 200,
            //                     data: {
            //                         ID: 5,
            //                         CreatedAt:
            //                             "2022-08-02T10:58:39.53071+07:00",
            //                         UpdatedAt:
            //                             "2022-08-02T10:58:39.53071+07:00",
            //                         DeletedAt: null,
            //                         tenant_id: 0,
            //                         created_by: "",
            //                         last_modified_by: "",
            //                         namaIndo: "A Sub 2",
            //                         namaEng: "A Sub 2",
            //                         namaAwam: "A Sub 2",
            //                         namaAwamEng: "",
            //                         isPublish: false,
            //                         spesialisasis: []
            //                     }
            //                 });
            //             } else {
            //                 reject(dummyError);
            //             }
            //         }, 1000);
            //     }
            // );
            const response = await TempRepository({
                method: "get",
                url: `/${subspe}/${id}`
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "getOneSubpspesialisasi");
            return this.errorHandler(error);
        }
    },
    async putSubpspesialisasi(payload) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve({ status: 200, data: payload });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const response = await TempRepository({
                method: "put",
                url: `/${subspe}`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error);
        }
    },
    async delConfirmationDelete(id) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(dummyConfirmation);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const response = await TempRepository({
                method: "delete",
                url: `/${subspe}/confirm`,
                data: {
                    subSpesialisasiId: id
                }
            });
            console.log(response, "response");
            if (response.status == 200 || response.status == 204) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "804 error");
            return this.errorHandler(error);
        }
    },
    async delSubspesialisasi(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve({
            //                 status: 200,
            //                 data: { id }
            //             });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const response = await TempRepository({
                method: "delete",
                url: `/${subspe}`,
                data: payload
            });
            console.log(response, "response");
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "833 error");
        }
    }
};
