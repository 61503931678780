class menu {
    listMenuPermission() {
        const listMenu = [
            {
                name: "dashboard",
                permission: ["BackOffice.View.Menu.Dashboard"]
            },
            {
                name: "call-center-appointment",
                permission: ["BackOffice.View.Menu.CallCenter"]
            },
            {
                name: "reservasi",
                permission: ["BackOffice.View.Menu.Reservasi"]
            },
            {
                name: "histori-reservasi",
                permission: ["BackOffice.View.Menu.Reservasi"]
            },
            {
                name: "cancel-reservasi",
                permission: ["BackOffice.View.Menu.Reservasi"]
            },
            {
                name: "rekam-medik",
                permission: ["BackOffice.View.Menu.RekamMedik"]
            },
            {
                name: "farmasi",
                permission: ["BackOffice.View.Menu.Farmasi"]
            },
            {
                name: "antrian",
                permission: ["BackOffice.View.Menu.Antrian"]
            },
            {
                name: "tanggapan",
                permission: ["BackOffice.View.Menu.Tanggapan"]
            },
            {
                name: "dokter",
                permission: ["BackOffice.View.Menu.Dokter"]
            },
            {
                name: "dokter-new",
                permission: ["BackOffice.View.Menu.Doctor"]
            },
            {
                name: "dokter-new-detail",
                permission: ["DoctorResource.GET.DoctorProfile"]
            },
            {
                name: "absen-dokter",
                permission: [
                    "StatusJadwalDokterResource.GET.StatusJadwalDokter"
                ]
            },
            {
                name: "pic-farmasi",
                permission: ["BackOffice.View.Menu.PicFarmasi"]
            },
            {
                name: "pasien",
                permission: ["BackOffice.View.Menu.Pasien"]
            },
            {
                name: "unit",
                permission: ["BackOffice.View.Menu.Unit"]
            },
            {
                name: "unit-antrian",
                permission: ["BackOffice.View.Menu.UnitAntrian"]
            },
            {
                name: "tujuan-rujukan",
                permission: ["BackOffice.View.Menu.TujuanRujukan"]
            },
            {
                name: "jenis-penjamin",
                permission: ["BackOffice.View.Menu.JenisPenjamin"]
            },
            {
                name: "tugas",
                permission: ["BackOffice.View.Menu.Tugas"]
            },
            {
                name: "kategori",
                permission: ["BackOffice.View.Menu.Kategori"]
            },
            {
                name: "kelas",
                permission: ["BackOffice.View.Menu.Kelas"]
            },
            {
                name: "tindakan",
                permission: ["BackOffice.View.Menu.Tindakan"]
            },
            {
                name: "gallery",
                permission: ["BackOffice.View.Menu.Gallery"]
            },
            {
                name: "carousel",
                permission: ["BackOffice.View.Menu.Carousel"]
            },
            {
                name: "user-group",
                permission: ["BackOffice.View.Menu.GrupUser"]
            },
            {
                name: "entity-data-version",
                permission: ["BackOffice.View.Menu.EntityDataVersion"]
            },
            {
                name: "version-entity",
                permission: ["BackOffice.View.Menu.EntityDataVersion"]
            },
            {
                name: "user-management",
                permission: ["BackOffice.View.Menu.ManagementUser"]
            },
            {
                name: "report-daftar-perujuk",
                permission: ["BackOffice.View.Menu.Report.DaftarPerujuk"]
            },
            {
                name: "report-status-rujukan",
                permission: ["BackOffice.View.Menu.Report.StatusRujukan"]
            },
            {
                name: "report-status-fee-keuangan",
                permission: [
                    "BackOffice.View.Menu.Report.StatusReferralKeuangan"
                ]
            },
            {
                name: "report-detail-transaksi",
                permission: ["BackOffice.View.Menu.Report.DetailTransaksi"]
            },
            {
                name: "report-pendapatan-referral",
                permission: ["BackOffice.View.Menu.Report.PendapatanReferral"]
            },
            {
                name: "drive-thru-runner",
                permission: ["DriveThruResource.View.Runner"]
            },
            {
                name: "drive-thru-booking",
                permission: ["DriveThruResource.View.Kasir"]
            },
            {
                name: "drive-thru-lab",
                permission: ["DriveThruResource.View.LabStaff"]
            },
            {
                name: "drive-thru-swabber",
                permission: ["DriveThruResource.View.Swabber"]
            },
            {
                name: "drive-thru-view",
                permission: ["DriveThruResource.View.DetailBooking"]
            },
            {
                name: "holiday-set",
                permission: ["DoctorResource.View.Holiday"]
            },
            {
                name: "ketidakhadiran",
                permission: ["DoctorResource.View.Absence"]
            },
            {
                name: "klinik",
                permission: ["DoctorResource.View.Klinik"]
            },
            {
                name: "master-spesialisasi",
                permission: ["DoctorResource.View.Spesialisasi"]
            },
            {
                name: "master-subspesialisasi",
                permission: ["DoctorResource.View.Subspesialisasi"]
            },
            {
                name: "master-tarif",
                permission: ["DoctorResource.View.Tarif"]
            },
            {
                name: "skema-rujukan",
                permission: ["BackOffice.View.Menu.SkemaRujukan"]
            },
            {
                name: "skema-rujukan-add",
                permission: ["SkemaRujukanResource.POST.SkemaRujukan"]
            },
            {
                name: "skema-rujukan-edit",
                permission: ["SkemaRujukanResource.PUT.SkemaRujukan"]
            },
            {
                name: "skema-rujukan-view",
                permission: ["SkemaRujukanResource.GET.SkemaRujukan.id"]
            },
            {
                name: "app-config",
                permission: ["BackOffice.View.Menu.ApplicationConfiguration"]
            },
            {
                name: "chats",
                permission: ["BackOffice.View.Menu.Chat"]
            },
            {
                name: "perujuk",
                permission: ["BackOffice.View.Menu.Perujuk"]
            },
            {
                name: "pic",
                permission: ["BackOffice.View.Menu.Pic"]
            },
            {
                name: "transaksi-referral",
                permission: ["BackOffice.View.Menu.TransaksiReferral"]
            },
            {
                name: "transaksi-referral-add",
                permission: ["TransaksiReferralResource.POST.TransaksiReferral"]
            },
            {
                name: "transaksi-referral-edit",
                permission: ["TransaksiReferralResource.PUT.TransaksiReferral"]
            },
            {
                name: "transaksi-referral-view",
                permission: [
                    "TransaksiReferralResource.GET.TransaksiReferral.id"
                ]
            },
            {
                name: "elastic",
                permission: ["BackOffice.View.Menu.Elastic"]
            },
            {
                name: "chat-user",
                permission: ["BackOffice.View.Menu.ChatUser"]
            },
            {
                name: "sms-template",
                permission: ["BackOffice.View.Menu.SmsTemplate"]
            },
            {
                name: "sms-history",
                permission: ["BackOffice.View.Menu.SmsHistory"]
            },
            {
                name: "histori-reservasi-pasien",
                permission: ["ReportResource.GET.Report.reservasiPasien"]
            },
            {
                name: "report-time-tracking-reservasi",
                permission: [
                    "BackOffice.View.Menu.Report.TimeTrackingReservation"
                ]
            },
            {
                name: "doctor-appointment",
                permission: ["BackOffice.View.Menu.DoctorAppointment"]
            },
            {
                name: "caller",
                permission: ["BackOffice.View.Menu.Caller"]
            },
            // Loyalty Menu
            {
                name: "kategori-promo",
                permission: ["LoyaltyPromotionCategoriesResource.GET.index"]
            },
            {
                name: "loyalty-tier",
                permission: ["LoyaltyLoyaltyTierResource.GET.index"]
            },
            {
                name: "members",
                permission: ["LoyaltyMembersResource.GET.index"]
            },
            {
                name: "coupons",
                permission: ["LoyaltyCouponResource.GET.index"]
            },
            {
                name: "coupon-usages",
                permission: ["LoyaltyTransactionCouponResource.GET.index"]
            },
            {
                name: "history-point",
                permission: ["LoyaltyTransactionPointResource.GET.index"]
            },
            {
                name: "claim-point",
                permission: ["LoyaltyTransactionClaimResource.GET.index"]
            },
            {
                name: "custom-point",
                permission: ["LoyaltyCustomPointResource.GET.index"]
            },
            {
                name: "transaction-point-type",
                permission: ["LoyaltyTransactionPointTypeResource.GET.index"]
            },
            {
                name: "formula-loyalty",
                permission: ["LoyaltyFormulaLoyaltyResource.GET.index"]
            },
            {
                name: "room-management",
                permission: ["BackOffice.View.Menu.RoomManagement"]
            },
            {
                name: "outpatient-dokter",
                permission: ["BackOffice.View.Menu.OutPatientDokter"]
            },
            {
                name: "tarif",
                permission: ["BackOffice.View.Menu.Tarif"]
            }
        ];
        return listMenu;
    }
    listMenuNonPermission() {
        return [
            {
                name: "error"
            },
            {
                name: 'link'
            },
            {
                name: "change-password"
            },
            {
                name: 'notSupport'
            }
        ];
    }
}
export default (menu = new menu());
