import { RepositoryFactory } from "../repositories/RepositoryFactory";
// import createRequestOption from '../repositories/RequestParamUtil';
// const HolidayRepository = RepositoryFactory.get("holiday");
export default class Holiday {
    static async getHoliday(date, source) {
        // try {
            const HolidayRepository = RepositoryFactory.get("holiday");
            let params = { dateBetween: date };
            // const defaultConfigs = createRequestOption(params);
            // defaultConfigs.cancelToken = source.token
            const holidays = await HolidayRepository.getHolidays({params} , source);
            return holidays.data;
        // } catch (error) {
        //     console.log(error , "getHoliday service");
        // }
    }
    static holidayDates(dokter, holidayDates) {
        const dokterId = dokter.id;
        let dataDokter = dokter;
        let dayHoliday = holidayDates
        dayHoliday.sort(function(a,b){
            return   new Date(a.UpdatedAt) - new Date(b.UpdatedAt);
        })
        dayHoliday.forEach(date => {
            // console.log(dataDokter, "dataDokter", date);
             
            if (date.type == "INCLUDE") {
                dataDokter.holiday = true;
                dataDokter.holidayRemarks = date.description
            } else if (date.type == "EXCLUDE") {
                let isNotHoliday = false
                date.detailHoliday.forEach(detail => {
                    if (detail.dokter) {
                        // console.log(detail.dokter.ID  , dokterId , "dokterId" , dokter);
                        if (detail.dokter.ID == dokterId) {
                            isNotHoliday = true;
                        }
                    }
                });
                if(!isNotHoliday){
                    dataDokter.holiday = true;
                    dataDokter.holidayRemarks = date.description
                }else{
                    dataDokter.holiday = false;
                }
            }
        });
        return dataDokter;
    }
    static async doctorHolidayMap(doctorData, date, source) {
        try {
            const holidayDates = await this.getHoliday(date,source);
            let doctorDataMap = doctorData.map(dokter => {
                return {
                    ...dokter,
                    holiday: false
                };
            });
            doctorDataMap = doctorDataMap.map(dokter => {
                return this.holidayDates(dokter, holidayDates);
            });
            return doctorDataMap;
            // doctorDataMap = doctorDataMap.map(dokter => {

            // });
        } catch (error) {
            console.log(error , "doctorHolidayMap");
        }
    }
}
