import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const tarif = "/tarifs";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(tarif, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    createTarif(payload) {
        return Repository.post(`${tarif}`, payload);
    },
    updateTarif(payload) {
        return Repository.put(`${tarif}`, payload);
    },
    patchTarif(payload) {
        return Repository.patch(`${tarif}`, payload);
    },
    deleteTarif(tarifId) {
        return Repository.delete(`${tarif}/${tarifId}`);
    }

}
