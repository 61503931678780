let dummyError = {
    response: {
        data: {
            message: {}
        }
    },
    status: 500
};

import Repository from "./Repository";
import TempRepository from "./TempRepository";
const statJadwal = `status-jadwal`;
export default {
    errorHandler(error) {
        let errorM = `Error`;
        let statusErr = "";
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error) {
                    errorM = error.response.data.error;
                } else if (error.response.data.message) {
                    errorM = !_.isEmpty(error.response.data.message)
                        ? error.response.data.message
                        : "An Unknown Error Has Occured";
                }
            }
            if (error.response.status) {
                statusErr = error.response.status;
            }
        }
        return {
            error: errorM,
            status: statusErr,
            message: error.message
        };
    },
    getKetidakhadiran({ params }) {
        // const randomNumber = Math.random() * 10;
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         if (randomNumber > 0) {
        //             resolve(dummy2);
        //         } else {
        //             reject(dummyError);
        //         }
        //     }, 200);
        // });
        // return TempRepository.get("/holidays", params);
        // return TempRepository({
        //     method: "get",
        //     url: `/status-jadwals`,
        //     params: params
        // });
        return TempRepository({
            method: "get",
            url: `/status-jadwals`,
            params: params
        });
    },
    async postKetidakhadiran(payload) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve({ status: 201, data: payload });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            // const response = await TempRepository({
            //     method: "post",
            //     url: `/status-jadwal-dokters`,
            //     data: payload
            // });
            const response = await TempRepository({
                method: "post",
                url: `/${statJadwal}`,
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post Ketidakhadiran");
            return this.errorHandler(error);
        }
    },
    async getDoctorList(params) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 2) {
            //             resolve({
            //                 status: 200,
            //                 data: [
            //                     { id: 1, nama: "Abhi Rama" },
            //                     { id: 2, nama: "natalia" }
            //                 ]
            //             });
            //         } else {
            //             reject({
            //                 status: 500,
            //                 message: "Error"
            //             });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "get",
                url: `/dokters/dropdown`,
                params: params
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get doctor List");
            return this.errorHandler(error);
        }
    },
    async getKetidakhadiranByDokterId(id) {
        try {
            const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(jadwalByDokter2);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 5000);
            // });
            const response = await TempRepository({
                method: "get",
                url: `/dokter/${id}`
                // params: params
            });
            if (response.status == 200) {
                return response.data.jadwals;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get Ketidakhadiran By Dokter Id");
            return this.errorHandler(error);
        }
    },
    async delKetidakhadiran(id) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 9) {
            //             resolve({ status: 200, data: id });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const response = await TempRepository({
                method: "delete",
                url: `/${statJadwal}/${id}`
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "del Ketidakhadiran");
            return this.errorHandler(error);
        }
    }
};
