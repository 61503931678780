import Repository from './RepositoryMandayauaa';
import paginateRepository from './AllPaginateRepositoryMandayauaa';
const user = "/user-groups";
const permission = "/user-group-permissions";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(user, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getUserGroup(userId) {
        return Repository.get(`${user}/${userId}`);
    },
    createUserGroup(payload) {
        return Repository.post(`${user}`, payload);
    },
    updateUserGroup(payload) {
        return Repository.put(`${user}`, payload);
    },
    deleteUserGroup(userId) {
        return Repository.delete(`${user}/${userId}`);
    },
    getPermission(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(permission, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    deletePermission(id) {
        return Repository.delete(`${permission}/${id}`);
    },
}
