import state from "./memberDialogState";
import mutations from "./memberDialogMutations";
import actions from "./memberDialogActions";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters : {},
};
