import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const tindakan = "/tindakans";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(tindakan, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getTindakan(tindakanId) {
        return Repository.get(`${tindakan}/${tindakanId}`);
    },
    createTindakan(payload) {
        return Repository.post(`${tindakan}`, payload);
    },
    updateTindakan(payload) {
        return Repository.put(`${tindakan}`, payload);
    },
    deleteTindakan(tindakanId) {
        return Repository.delete(`${tindakan}/${tindakanId}`);
    }
}
