import Repository from './Repository';
const carousel = '/carousels';

export default {
    get(params) {
        return Repository.get(`${carousel}`, { params: params });
    },
    getCarousel(carouselId) {
        return Repository.get(`${carousel}/${carouselId}`);
    },
    createCarousel(payload) {
        return Repository.post(`${carousel}`, payload);
    },
    updateCarousel(payload) {
        return Repository.put(`${carousel}`, payload);
    },
    deleteCarousel(carouselId) {
        return Repository.delete(`${carousel}/${carouselId}`);
    }
};
