 <template></template>

<script>
export default {
  methods: {
    show: function (swalParam, showingCancelButton) {
      var vx = this;

      this.$mount();
      swal({
        title: swalParam.swalTitle,
        html: swalParam.swalText,
        type: swalParam.swalType,
        allowOutsideClick: false,
        reverseButtons: (swalParam.reverseButtons) ? swalParam.reverseButtons : false,
        showCancelButton: showingCancelButton,
        confirmButtonText: (swalParam.textConfirm) ? swalParam.textConfirm : "OK",
        cancelButtonText: (swalParam.textCancel) ? swalParam.textCancel : "Cancel"
      }).then(function (result) {
        if (result.value) {
          swalParam.onConfirmButton();
        } else {
          if (result.dismiss == "overlay") {
            swalParam.onConfirmButton();
          }
          else {
            swalParam.onCancelButton();
          }
        }
      });
    },
    showInfo: function (swalParam) {
      this.show(swalParam, false);
    },
    showConfirm: function (swalParam) {
      this.show(swalParam, true);
    }
  }
};
</script>
