import Repository from './Repository';
import createRequestOption from './RequestParamUtil';
const auditEntityAll = "/audits/entity/all";
const entityChangeLastVersion = '/audits/entity/changes/last-version'
const entityPrevVersion = '/audits/entity/changes/version/previous'

export default {
    getAllAuditEntity() {
        return Repository.get(`${auditEntityAll}`)
    },
    fetch(req, cancelToken) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${entityChangeLastVersion}`, {...defaultConfigs, ...cancelToken});
    },
    previousAudit(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${entityPrevVersion}`, defaultConfigs);
    }
}
