import Repository from "./Repository";
// import createRequestOption from "./RequestParamUtil";
import TempRepository from "./TempRepository";
const spe = `spesialisasi`;
const subSpe = `subspesialisasi`;
export default {
    errorHandler(error) {
        let errorM = `Error`;
        let statusErr = "";
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error) {
                    errorM = error.response.data.error;
                } else if (error.response.data.message) {
                    errorM = !_.isEmpty(error.response.data.message)
                        ? error.response.data.message
                        : "An Unknown Error Has Occured";
                }
            }
            if (error.response.status) {
                statusErr = error.response.status;
            }
        }
        return {
            error: errorM,
            status: statusErr,
            message: error.message
        };
    },
    getSpesialisasi({ params }) {
        // const randomNumber = Math.random() * 10;
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         if (randomNumber > 0) {
        //             resolve(spesialisasiDummy);
        //         } else {
        //             reject(dummyError);
        //         }
        //     }, 2000);
        // });
        // return TempRepository.get("/holidays", params);
        return TempRepository({
            method: "get",
            url: `/${spe}s`,
            params: params
        });
    },
    async getSupsesialisasiDropdown(params) {
        try {
            const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(subspesialisasiDummy);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const resp = await TempRepository({
                url: `/${subSpe}s`,
                method: "get",
                params: params
            });
            console.log(resp.status, "status", resp.data);
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getSupsesialisasiDropdown Error -`);
                }
                return resp.data;
            }
            throw new Error(
                `getSupsesialisasiDropdown Error - Status Code: ${resp.status}`
            );
        } catch (err) {
            return this.errorHandler(err);
        }
    },
    async postDataSpesialisasi(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve({ data, status: 201 });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const resp = await TempRepository({
                url: `/${spe}`,
                method: "post",
                data: payload
            });
            console.log(resp.status, "status", resp.data);
            if (resp.status == 201) {
                if (!resp.data) {
                    throw new Error(`getDoctorDataDropdown Error -`);
                }
                return resp.data;
            }
            throw new Error(
                `getDoctorDataDropdown Error - Status Code: ${resp.status}`
            );
        } catch (err) {
            return this.errorHandler(err);
        }
    },
    async postSubpspesialisasi(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const response = await new Promise(
            //     (resolve, reject) => {
            //         setTimeout(() => {
            //             if (randomNumber > 0) {
            //                 resolve({
            //                     status: 201,
            //                     data: payload
            //                 });
            //             } else {
            //                 reject(error);
            //             }
            //         }, 1000);
            //     }
            // );
            const response = await TempRepository({
                method: "post",
                url: `/${subSpe}`,
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post subspesialisasi");
            return this.errorHandler(error);
        }
    },
    async getOneSubpspesialisasi(id) {
        try {
            const response = await TempRepository({
                method: "get",
                url: `/${subSpe}/${id}`
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "getOneSubpspesialisasi");
            return this.errorHandler(error);
        }
    },
    async putSubpspesialisasi(payload) {
        try {
            const response = await TempRepository({
                method: "put",
                url: `/${subSpe}`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error);
        }
    },
    async delConfirmationDeleteSub(id) {
        try {
            const response = await TempRepository({
                method: "delete",
                url: `/${subSpe}/confirm`,
                data: {
                    subSpesialisasiId: id
                }
            });
            console.log(response, "response");
            if (response.status == 200 || response.status == 204) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "804 error");
            return this.errorHandler(error);
        }
    },
    async delSubspesialisasi(payload) {
        try {
            const response = await TempRepository({
                method: "delete",
                url: `/${subSpe}`,
                data: payload
            });
            console.log(response, "response");
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "208 error");
            return this.errorHandler(error);
        }
    },
    async getOneSpe(id) {
        try {
            // const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(oneSpesialisasi);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const resp = await TempRepository({
                url: `/${spe}/${id}`,
                method: "get"
            });
            console.log(resp.status, "status", resp.data);
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getOneSupspe Error -`);
                }
                return resp.data;
            }
            throw new Error(`getOneSupspe Error - Status Code: ${resp.status}`);
        } catch (err) {
            return this.errorHandler(err);
        }
    },
    async putSpe(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve({ data: payload, status: 200 });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 200);
            // });
            const resp = await TempRepository({
                url: `/${spe}`,
                method: "put",
                data: payload
            });
            console.log(resp.status, "status", resp.data);
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`putSubSpe Error -`);
                }
                return resp.data;
            }
            throw new Error(`putSubSpe Error - Status Code: ${resp.status}`);
        } catch (err) {
            return this.errorHandler(err);
        }
    },
    async delConfirmation(id) {
        try {
            // const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 0) {
            //             resolve(delConfimation);
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const resp = await TempRepository({
                url: `/${spe}/confirm`,
                method: "delete",
                data: {
                    spesialisasiId: id
                }
            });
            console.log(resp.status, "status", resp.data, resp);
            if (resp.status == 200 || resp.status == 204) {
                // if (!resp.data) {
                //     throw new Error(`delConfirmation Error -`);
                // }
                return resp.data;
            }
            throw new Error(
                `delConfirmation Error - Status Code: ${resp.status}`
            );
        } catch (err) {
            return this.errorHandler(err);
        }
    },
    async delSpesialisasi(payload) {
        try {
            // const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 9) {
            //             resolve({ data: payload, status: 200 });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const resp = await TempRepository({
                url: `/${spe}`,
                method: "delete",
                data: payload
            });
            console.log(resp.status, "status", resp.data, resp);
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`delSpesialisasi Error -`);
                }
                return resp.data;
            }
            throw new Error(
                `delSpesialisasi Error - Status Code: ${resp.status}`
            );
        } catch (err) {
            return this.errorHandler(err);
        }
    },
    async reIndexDataList() {
        try {
            // const randomNumber = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (randomNumber > 9) {
            //             resolve({ data: payload, status: 200 });
            //         } else {
            //             reject(dummyError);
            //         }
            //     }, 1000);
            // });
            const resp = await TempRepository({
                url: `/re-index-list-spesialisasi`,
                method: "get"
            });
            // console.log(resp.status, "status", resp.data, resp);
            if (resp.status == 200) {
                if (!resp.data) {
                    throw resp;
                }
                return resp.data;
            }
            throw resp;
        } catch (err) {
            return this.errorHandler(err);
        }
    }
};
