import Repository from './Repository';
import createRequestOption from './RequestParamUtil';
import paginateRepository from './AllPaginateRepository';
const tujuanRujukan = "/tujuan-rujukans";
const tujuanRujukanByDokter = "/tujuan-rujukans-by-dokter";
let defaultConfigs = { params: { "size": 1000 } };
export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(tujuanRujukan, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getTujuanRujukan(tujuanRujukanId) {
        return Repository.get(`${tujuanRujukan}/${tujuanRujukanId}`);
    },
    fetch(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${tujuanRujukanByDokter}`, defaultConfigs);
    },
    getTujuanRujukanByDokter(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(tujuanRujukanByDokter, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
        // defaultConfigs = createRequestOption(req);
        // return Repository.get(`${tujuanRujukanByDokter}`, defaultConfigs);
    },
    createTujuanRujukan(payload) {
        return Repository.post(`${tujuanRujukan}`, payload);
    },
    updateTujuanRujukan(payload) {
        return Repository.put(`${tujuanRujukan}`, payload);
    },
    deleteTujuanRujukan(tujuanRujukanId) {
        return Repository.delete(`${tujuanRujukan}/${tujuanRujukanId}`);
    },
}



