import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const tugas = "/tugases";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(tugas, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getTugas(tugasId) {
        return Repository.get(`${tugas}/${tugasId}`);
    },
    createTugas(payload) {
        return Repository.post(`${tugas}`, payload);
    },
    updateTugas(payload) {
        return Repository.put(`${tugas}`, payload);
    },
    deleteTugas(tugasId) {
        return Repository.delete(`${tugas}/${tugasId}`);
    }
}
