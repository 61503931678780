export default {
    SET_LOADING(state, data) {
        state.isLoading = data.loading;
        state.loadingMessage = data.message;
    },
    // SET_SWAL(state, data) {
    //     _.forEach(data, (i, k) => {
    //         if (!_.isUndefined(state.swalMessage[k])) {
    //             state.swalMessage[k] = i;
    //         }
    //     });
    // },
    RESET_STATE(state) {
        (state.repository = null),
        (state.isLoaded = false),
        (state.isShowModal = false),
        (state.modalName = null),
        (state.table = {
            tableName: null,
            tableDataResult: [],
            tableColumnDefs: [],
            tableColumns: [],
            tableFilter: [],
            ordering: null,
            order: null,
            dateFormat: null,
        }),
        (state.dataEdit = null);
        (state.showModalMode = null);
    },
    SET_REPOSITORY(state, data) {

        state.repository = data;

    },
    SET_SELECTED_MEMBER(state, data){
        let isIncludes = _.find(state.selectedMembers, (item)=>{
            return item.id == data.id;
        });
        if ( isIncludes !== undefined ) {
            let selectedMembers = state.selectedMembers ;
            let rejectResult = _.reject(selectedMembers,(item)=>{
                return item.id == data.id;
            });
            state.selectedMembers = rejectResult;
        }
        else {
            state.selectedMembers.push(data)
        }

    },

    SET_SELECTED_MEMBERS(state, data){
        state.selectedMembers = data;
    },

    SET_TEMPORARY_SELECTED_MEMBERS(state, data){
        state.tempSelectedMembers = data;
    },

    SET_TEMPORARY_SELECTED_MEMBER(state, data){
        if (_.includes(state.tempSelectedMembers, data)) {
            state.tempSelectedMembers = _.reject(state.tempSelectedMembers,(item)=>{
                return item.id == data.id;
            });
        }
        else {
            state.tempSelectedMembers.push(data)
        }
    },
    SET_MODAL(state, data) {
        state.modalName = data;
    },
    SET_EDIT_DATA(state, data) {
        state.showModalMode = null;
        state.dataEdit = data;
    },
    SET_VIEW_DATA(state, data) {
        state.dataEdit = data;
        state.showModalMode = 'V';
        // state.isShowModal = true;
    },
    // SETUP_TABLE(state, data) {},
    SHOW_MODAL(state, data) {
        state.isShowModal = true;
    },
    HIDE_MODAL(state, data) {
        let modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if(!_.isUndefined(modalBackdrop)){
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
        state.dataEdit = null;
        state.tempSelectedMembers = [];
        state.showModalMode = null;
        state.isShowModal = false;
    },
    CLEAR_DATA(state) {
        state.isShowModal = false;
    },
    REFRESH_TABLE(state, data) {
        state.shouldRefreshTable = (data == null) ? true : data;
        state.isLoading = state.shouldRefreshTable;
        state.loadingMessage = 'Memuat Data ...';
    }
};
