<template>
    <div class="dvh-100">
        <router-view></router-view>
        <span class="bomsu-commit"> {{versionCommit}}</span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            versionCommit: process.env.VUE_APP_COMMIT_ID
        }
    }
}
</script>
<style lang="scss">
    @import "assets/sass/app.scss";
    .bomsu-commit{
        position: fixed;
        bottom: 0;
        left: 0;
        font-size: 11px;
        font-weight: 600;
    }
    .dvh-100{
        height: 100dvh;
    }
</style>
