import axios from "axios";
import logoutHelpers from "../services/logOut.service";

const baseDomain = process.env.VUE_APP_API_URL;

const baseURL = `${baseDomain}/api`;


const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers["Authorization"] = "Bearer " + localStorage.tokens;
    request.headers["X-TenantId"] = window.tenantActive;
    request.headers.common["Access-Control-Allow-Origin"] = "*";

    return request;
});

// const refreshAuthLogic = failedRequest =>
//     window.keycloak.updateToken(-1).success(() => {
//         window.access_token = window.keycloak.access_token;
//         window.refresh_token = window.keycloak.refresh_token;
//     }).error(() => {
//         logoutHelpers.doLogout()
//     })

instance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 403  || error.response.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        window.keycloak.updateToken(-1).success(() => {
            localStorage.setItem("tokens", window.keycloak.token);
            return instance(originalRequest);
        }).error(() => {
            if(localStorage.tokens) {
                logoutHelpers.logout()
            } else {
                logoutHelpers.doLogout()
            }
        });
    } else {
        return Promise.reject(error);
    }
  });

// createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
