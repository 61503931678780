export default function FarmasiGetTodo() {
    var todaysDate = moment().format('YYYY-MM-DD');
    return {
        'status.equals': 'DONE',
        'queueUnitId.in':
            process.env.VUE_APP_PHARMACY_RACIKAN +
            ',' +
            process.env.VUE_APP_PHARMACY_NONRACIKAN,
        'queueDate.equals': todaysDate,
        sort: 'lastModifiedDate,DESC'
    };
}
