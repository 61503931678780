import Repository from './Repository';
import RequestParamUtil from './RequestParamUtil';
import paginateRepository from './AllPaginateRepository';
const todo = "/work-orders/todo";
const workOrders = "/work-orders";

export default {
    getTodo(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(todo, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
        // return Repository.get(`${todo}`, todoParam);
    },
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(workOrders, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getInProgress(inProgressParam) {
        return Repository.get(`${workOrders}`, inProgressParam);
    },
    getDone(doneParam) {
        return Repository.get(`${workOrders}`, doneParam);
    },
    postWorkOrder(payload) {
        return Repository.post(`${workOrders}`, payload);
    },
    putWorkOrder(payload) {
        return Repository.put(`${workOrders}`, payload);
    }
}
