import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const feedbacks = "/feedbacks";
const defaultConfigs = { params: { "size": 1000, "sort":"id,DESC" } };

export default {
    get(req, resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(feedbacks, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
        // return Repository.get(`${feedbacks}`, defaultConfigs);
    },
    getFeedback(feedbackId) {
        return Repository.get(`${feedbacks}/${feedbackId}`, defaultConfigs);
    },
    createFeedback(payload) {
        return Repository.post(`${feedbacks}`, payload);
    },
    updateFeedback(payload) {
        return Repository.put(`${feedbacks}`, payload);
    },
    deleteFeedback(feedbackId) {
        return Repository.delete(`${feedbacks}/${feedbackId}`);
    }
}
