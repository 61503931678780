import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const perujuks = "/internal/registrasis";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    registrasiByUserId(UserId) {
        return Repository.get(`/registrasi-by-userid/${UserId}`);
    },
    getPerujukId(id) {
        return Repository.get(`/registrasi-by-perujukid/${id}`)
    },
    register(payload) {
        return Repository.post(`${perujuks}`, payload);
    },
    update(payload) {
        return Repository.put(`${perujuks}`, payload);
    },
    linkPost(payload) {
        return Repository.post(`/registrasi-dokter`, payload)
    }
}
