import Repository from './Repository';

const schedule = "/jadwals";
const scheduleByDayOfWeek = "/jadwals-by-dayofweek";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(schedule, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getSchedule(scheduleId) {
        return Repository.get(`${schedule}/${scheduleId}`);
    },
    createSchedule(payload) {
        return Repository.post(`${schedule}`, payload);
    },
    updateSchedule(payload) {
        return Repository.put(`${schedule}`, payload);
    },
    deleteSchedule(scheduleId) {
        return Repository.delete(`${schedule}/${scheduleId}`);
    },
    getJadwalByDayOfWeek(scheduleId) {
        return Repository.get(`${scheduleByDayOfWeek}/${scheduleId}`);
    },
    appointmentMinuteBefore(){
        return Repository.get(`/appointment-minute-before`);
    }
}
