import axios from "axios";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/gallery/api`;


const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers['Authorization'] = "Bearer " + localStorage.tokens;
    request.headers['X-TenantId'] = window.tenantActive;
    request.headers["cache-control"] = "no-cache"
    return request;
});

export default instance