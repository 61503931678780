import Repository from "./Repository";
import TempRepository from "./TempRepository";
export default {
    errorHandler(error) {
        let errorM = `Error`;
        let statusErr = "";
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error) {
                    errorM = error.response.data.error;
                } else if (error.response.data.message) {
                    errorM = !_.isEmpty(error.response.data.message)
                        ? error.response.data.message
                        : "An Unknown Error Has Occured";
                }
            }
            if (error.response.status) {
                statusErr = error.response.status;
            }
        }
        return {
            error: errorM,
            status: statusErr,
            message: error.message
        };
    },
    getTarifs({ params }) {
        return TempRepository({
            method: "get",
            url: `/tarifs`,
            params: params
        });
    },
    async postTarif(payload) {
        try {
            const response = await TempRepository({
                method: "post",
                url: `/tarif`,
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post tarif");
            return this.errorHandler(error);
        }
    },
    async getOneTarif(id) {
        try {
            const response = await TempRepository({
                method: "get",
                url: `/tarif/${id}`
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "getOneTarif");
            return this.errorHandler(error);
        }
    },
    async putTarif(payload) {
        try {
            const response = await TempRepository({
                method: "put",
                url: `/tarif`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "putTarif");
            return this.errorHandler(error);
        }
    },
    async delTarifConfirmation(id) {
        try {
            const response = await TempRepository({
                method: "delete",
                url: `/tarif/confirm`,
                data: {
                    tarifId: id
                }
            });
            if (response.status == 200 && response.data) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "delTarif");
            return this.errorHandler(error);
        }
    },
    async delTarif(payload) {
        try {
            const response = await TempRepository({
                method: "delete",
                url: `/tarif`,
                data: payload
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "delTarif");
            return this.errorHandler(error);
        }
    }
};
