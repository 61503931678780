import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const queues = "/queue-units";
const queuesSlot = "/queue-unit-slots";
const countReservasi = '/queue-unit/count-reservasi';
const queuePassword = '/queue-unit-slots/password';
const queueSlotDoctor = "/queue-unit-slots/dokter";
import createRequestOption from './RequestParamUtil';
const defaultConfigs = { params: { "size": 1000 } };
import Axios from 'axios';

function AbortControllerToken() {
    let cancelToken = null
    let abortCont = new AbortController()
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    cancelToken = source
    return [abortCont , cancelToken]
}
export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queues, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    fetch(req) {    
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${queues}`, defaultConfigs);
    },
    getQueueUnit(queueUnitId) {
        return Repository.get(`${queues}/${queueUnitId}`);
    },
    createQueueUnit(payload) {
        return Repository.post(`${queues}`, payload);
    },
    updateQueueUnit(payload) {
        return Repository.put(`${queues}`, payload);
    },
    deleteQueueUnit(queueUnitId) {
        return Repository.delete(`${queues}/${queueUnitId}`);
    },
    geQueueUnitSlot(req, resolve, reject , dokterParam) {
        const tokenAbort = AbortControllerToken()
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queuesSlot, req, [], 0, resolve, reject , tokenAbort[1] ,null ,dokterParam);
            // paginateRepository.getAllPaginate(mock, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });

        tokenAbort[0].signal.addEventListener('abort', () => {
            if (tokenAbort[1]) {
                tokenAbort[1].cancel(`Another request was made`)
            }
            reject(`Another request was made`);
        });

        return tokenAbort[0]
    },
    geQueueUnitSlotWithQuery(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginateWithQueryParams(queuesSlot, req, [], 0, resolve, reject);
            // paginateRepository.getAllPaginate(mock, req, [], 0, resolve, reject);
        }).then((response) => {
            const mapperFilterQueueWalkin = helpers.mapperFilterQueueWalkIn(response)
            resolve({ data: mapperFilterQueueWalkin });
        }).catch(error => {
            reject(error);
        });
    },

    geQueueUnitSlotPassword(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queuePassword, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getCountReservasi(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queuesSlotPassword, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getCountReservasi(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(countReservasi, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getDoctorQueueSlot(req, resolve, reject, dokterParam) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queueSlotDoctor, req, [], 0, resolve, reject , null , null , dokterParam);
            // paginateRepository.getAllPaginate(mock, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    }
}
