import qz from "qz-tray";
const {  KJUR, KEYUTIL, stob64, hextorstr } = require('jsrsasign');

export default {
    async openConnectionQzTray(host) {
        var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
                    "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC7qAv8vXSzThYEYEQuMFhX2X6WPbDk9sW+vKxNgdhOy73B7IWg5vem0lOdiXzqdWauAR45F3Wti5g1PcECJL0mxIzfki9T+9wNgvsGz3r++01twOtVxv8teIEQAes4m+J/nULDWfivam2VyAWn2tpo7w56NKtHnMfeZjJ22lXjtRw6nqCxsLltk+mUwMX7X0KMOn5dzSv/99vr04UbiPNi/a1XKjPgNGS59bWeEJAy1OA5iz2/DzAHlOJn4rHliIR60eHRLGhl1rM4zni3/4bHHomL1QK1JWyHE8RndRy+equGdgqN7tbkiyLPSGyjaZ6yTP5dMbPEJnRQPOEQbN13AgMBAAECggEAEHG+cyxrAJd0KHgDIYUNCM2/YQ0Q5k+Vrow8ESKywNzryQWgBCSZSR4fQ6vbrXQSRmdErb4Xitizwt6hfj2X8cDBk4ns6dAlTW3P753Y6mU9OMPyG3cvv11S+gMhCLnoVESEqDfHKiAKcFix3i64ev+P5jedVs4vWZbdDzs80ZJTuHSQUZNp7/i61Cyyv6d8J+2jqntJvid9eaZoDzNrfpwlKp0GCoj0x3YqAoT/476eyCsZdaGxjac5sp+wjqn7imFeCNJ0t78JoyKNjbg9nEkxGpRHqLWDpAExo7FpNN39jCSb5tzFYAQg4hz2krRpsrDAoCm58jr524zhmH0ZkQKBgQD1in/yEjBoriku4DopYcEscYy9Bp/mWFqmaombxQJ3AvWbpfjGn/pIgy6Xt262dGbHT4RjNh6K0IaqOCho18wBAHzcTi+NyBGpy3EJ9Pm7AassURBVHv6yAYNS4rbSPWQuwpDGDgziH3WhXUdiIuI2zs4x9edt/VPIk2XnlIqvSQKBgQDDplhUN3kG0tKC2w+CD8cxoj5VU9XwDJGAhSwO+ka2Dh3r2573JObq2Yb+2HTmU2Feo/VlfYWQyKaQivUeHHeLnnzDzFaETuGMYkHPNk4sNdXng7ja1OgVbwQiT9+6PnAceZ/+lVaZ2CPdFTQjnPgTdQQRDeZIoaZnKAMZKTVmvwKBgQDvLPAgfnNFJJDdJnHBKYWfoyPyZOeQ2vPCMvjEEQ2rm+heqzc/U0nbJF1YT8e6VnxfHSfZ9DegBCuI15cVOU9wkmcNueM8emwqNw9vIX+5F5EDZBtCryRthGfFby2icUaK/d9D7R/Di91rR49nGo+7z4quw9ehHihksp+LCvhkcQKBgA/JVEc3UqSiTioQ0ueDrmbq2Gy6HwEHgpYEKNHsj9gQu4lUZzc52wgIFURq4sPS5oBkamCOCEDub8Ip9C1BzsONPjPuQOzcEIbrRrQZhaU7ks7jaf7+Ptu74XGDUGIOt60UgzxfgGVZc0tbcqGNANdREcrufC5QHMUmDVnyYUSxAoGBAKkO4oHHkNeIME4IKNbKytvqVlDQojA6ca+0MqB06W2R3Y3IqW6d8xGUr6UwDFqxLTcou5sGFe1WQ2XEMpSRa0XwPio/5DUr2RIxXyj1ijQ7HIT7+COKaeGFekBLcrMGx9vrNptMOmPyrGki8usjJ4QyNQ7HFwLjad/0jrIqspxa\n" +
                    "-----END PRIVATE KEY-----";

        qz.security.setCertificatePromise(function (resolve, reject) {
            resolve("-----BEGIN CERTIFICATE-----\n" +
                "MIID2DCCAsCgAwIBAgIJALt852c596t1MA0GCSqGSIb3DQEBCwUAMIGAMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20wIBcNMjAwODExMTAzNDE0WhgPMjA1MjAyMDQxMDM0MTRaMIGAMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC7qAv8vXSzThYEYEQuMFhX2X6WPbDk9sW+vKxNgdhOy73B7IWg5vem0lOdiXzqdWauAR45F3Wti5g1PcECJL0mxIzfki9T+9wNgvsGz3r++01twOtVxv8teIEQAes4m+J/nULDWfivam2VyAWn2tpo7w56NKtHnMfeZjJ22lXjtRw6nqCxsLltk+mUwMX7X0KMOn5dzSv/99vr04UbiPNi/a1XKjPgNGS59bWeEJAy1OA5iz2/DzAHlOJn4rHliIR60eHRLGhl1rM4zni3/4bHHomL1QK1JWyHE8RndRy+equGdgqN7tbkiyLPSGyjaZ6yTP5dMbPEJnRQPOEQbN13AgMBAAGjUTBPME0GA1UdEQRGMESCFmRlbW8ubWFuZGF5YWhlYWx0aC5jb22CGmludGVybmFsLm1hbmRheWFoZWFsdGguY29tgg4qLnF6dHJheS5sb2NhbDANBgkqhkiG9w0BAQsFAAOCAQEAZmjJB1RNUSk+A2xIvtLUonVsRbI8BpXh49O5Qbj5Pz5g/0mx8qq83pRq3etORBC85X3PKRU2PFmPOCiVuN8AZojXFUunDSx4pOVWypfmgirRk8l91TeCVXaAoeKsgYxg8xH7u02gIg1O5E0XZcy4KcoUCvDkqYq55IV07ZcFLFqhAxZTyH15IiJ/p9dgSgM3NWSEGp7y8MPXXtl3ODwpLlxJiawzfgwl4evjSZSst5ASOAY9PdjYuh4BKtRtRv8wmyYCHgw90ZE6aNOxhR3DDK5NxeoJcOVDNl3SZ3CZesx57tU+oGuNLJd/AiTZE8PwDljjoIQXLKx/iVi+A9xnDQ==\n" +
                "-----END CERTIFICATE-----");
            });

            qz.security.setSignaturePromise(function (toSign) {
                return function (resolve, reject) {
                    try {
                        var pk = KEYUTIL.getKey(privateKey);
                        var sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
                        sig.init(pk)
                        sig.updateString(toSign)
                        var hex = sig.sign()
                        resolve(stob64(hextorstr(hex)));
                    } catch (err) {
                        reject(err);
                    }
                };
            });

        
            
            return new Promise(async (resolve, reject) => {
                const options = {
                    host: `${host}`,
                    port: {
                        secure: [8181]
                    },
                    usingSecure: true
                }
                if (!qz.websocket.isActive()) {
                    await qz.websocket.connect(options)
                        .then(() => resolve(true))
                        .catch(err => reject(new Error(err)))
                } else {
                    resolve(false)
                }
            })
    },
}