
class logOut {
    logout(){
        const parseLob = JSON.parse(atob(window.keycloak.token.split(".")[1]));
        let url 
        if(parseLob['logout-idp']) {
          url = `${process.env.VUE_APP_SSO_LINK}/realms/${parseLob['logout-idp']}/protocol/openid-connect/logout?redirect_uri=${window.location.origin}`
        } else {
            url = window.location.origin
        }
        localStorage.clear()
        window.keycloak.logout({redirectUri: url})
    }
    doLogout(){
        localStorage.removeItem('refreshTokens')
        localStorage.removeItem('tokens')
        window.location.replace(window.location.origin + "/login");

    }
}
export default logOut = new logOut();
