import Repository from './Repository';
const link = "/elasticsearch";
import createRequestOption from './RequestParamUtil';


export default {
    get(req) {
        const defaultConfigs =  createRequestOption(req);
        return Repository.get(`${link}/entities`,defaultConfigs);
    },
    postPermission() {
        return Repository.post(`${link}/index/pemission`);
    },
    postIndex(param){
        return Repository.post(`${link}/index`+param);
    }

}
