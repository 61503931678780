import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const device = "/queue-devices";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(device, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
        // return Repository.get(`${device}`, defaultConfigs);
    },
    getQueueDevice(deviceId) {
        return Repository.get(`${device}/${deviceId}`, defaultConfigs);
    },
    createQueueDevice(payload) {
        return Repository.post(`${device}`, payload);
    },
    updateQueueDevice(payload) {
        return Repository.put(`${device}`, payload);
    },
    deleteQueueDevice(deviceId) {
        return Repository.delete(`${device}/${deviceId}`);
    }
}
