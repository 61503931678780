const dummy = {
    data: [
        {
            id: 1,
            nama: "Ketidakhadiran 1",
            tanggal: "12/MAY/2020",
            hari: "Selasa",
            jamMulai: "08:00",
            jamSelesai: "09:00",
            alasan: "Tidur"
        },
        {
            id: 1,
            nama: "Ketidakhadiran 2",
            tanggal: "12/MAY/2021",
            hari: "Rabu",
            jamMulai: "18:00",
            jamSelesai: "19:00",
            alasan: "bla bla"
        }
    ],
    statuis: 200
};
const dummy2 = {
    data: [
        {
            id: 876351,
            tanggal: "2021-07-08",
            status: "CANCELLED",
            jadwal: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:05:05.220Z",
                createdDateTime: "2021-07-06T00:05:05.22",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:08:43.463Z",
                lastModifiedDateTime: "2021-07-06T00:08:43.463",
                id: 876301,
                hari: "THURSDAY",
                jamMulai: "10:00",
                jamSelesai: "10:50",
                dokter: {
                    createdBy: "spv-fo",
                    createdDate: "2021-07-05T17:03:17.139Z",
                    createdDateTime: "2021-07-06T00:03:17.139",
                    lastModifiedBy: "spv-fo",
                    lastModifiedDate: "2021-07-05T17:04:32.990Z",
                    lastModifiedDateTime: "2021-07-06T00:04:32.99",
                    id: 875333,
                    nama: "dr.Test Permission1",
                    tanggalLahir: "1989-05-31",
                    tujuanRujukan: {
                        createdBy: "admin",
                        createdDate: "2021-06-30T04:08:42.266Z",
                        createdDateTime: "2021-06-30T11:08:42.266",
                        lastModifiedBy: "admin",
                        lastModifiedDate: "2021-06-30T04:08:42.266Z",
                        lastModifiedDateTime: "2021-06-30T11:08:42.266",
                        id: 871301,
                        nama: "Rawat Test",
                        grupRujukan: "PELAYANANMEDIS",
                        parentId: null
                    },
                    isDeleted: true,
                    isPublished: true,
                    textToSpeech: null,
                    tarif: null,
                    userId: null,
                    description: null,
                    profileCss: null,
                    profileMhg: null,
                    profileOvveride: null,
                    dokterKliniks: null,
                    dokterSpesialisasis: null,
                    dokterSubSpesialisasis: null
                },
                isDeleted: true,
                tanggalMulai: null,
                tanggalSelesai: null,
                statusJadwalDokters: [],
                durationInMinute: 10,
                slotIndexBlocked: null,
                description: "TIDAK TAHU"
            }
        },
        {
            id: 923352,
            tanggal: "2021-08-25",
            status: "CANCELLED",
            jadwal: {
                createdBy: "admin",
                createdDate: "2021-07-11T23:34:24.196Z",
                createdDateTime: "2021-07-12T06:34:24.196",
                lastModifiedBy: "admin",
                lastModifiedDate: "2021-07-11T23:34:24.196Z",
                lastModifiedDateTime: "2021-07-12T06:34:24.196",
                id: 895442,
                hari: "WEDNESDAY",
                jamMulai: "18:00",
                jamSelesai: "20:00",
                dokter: {
                    createdBy: "admin",
                    createdDate: "2021-07-11T23:34:02.898Z",
                    createdDateTime: "2021-07-12T06:34:02.898",
                    lastModifiedBy: "admin",
                    lastModifiedDate: "2021-10-04T06:20:05.891Z",
                    lastModifiedDateTime: "2021-10-04T13:20:05.891",
                    id: 895009,
                    nama: "DUMMY-dr. Suryadi Susanto, SpA",
                    tanggalLahir: "2021-05-03",
                    tujuanRujukan: {
                        createdBy: "admin",
                        createdDate: "2021-07-11T23:33:58.488Z",
                        createdDateTime: "2021-07-12T06:33:58.488",
                        lastModifiedBy: "admin",
                        lastModifiedDate: "2021-07-11T23:33:58.488Z",
                        lastModifiedDateTime: "2021-07-12T06:33:58.488",
                        id: 894959,
                        nama: "DUMMY-ANAK",
                        grupRujukan: "PELAYANANMEDIS",
                        parentId: null
                    },
                    isDeleted: false,
                    isPublished: true,
                    textToSpeech: null,
                    tarif: {
                        createdBy: "admin",
                        createdDate: "2021-07-23T08:21:37.269Z",
                        createdDateTime: "2021-07-23T15:21:37.269",
                        lastModifiedBy: "admin",
                        lastModifiedDate: "2021-07-23T08:21:37.269Z",
                        lastModifiedDateTime: "2021-07-23T15:21:37.269",
                        id: 903351,
                        nama: "0RP",
                        deskripsi: "Khusus 0 tarhf",
                        jumlah: 0,
                        deletedAt: null
                    },
                    userId: null,
                    description: null,
                    profileCss: null,
                    profileMhg: null,
                    profileOvveride: null,
                    dokterKliniks: null,
                    dokterSpesialisasis: null,
                    dokterSubSpesialisasis: null
                },
                isDeleted: false,
                tanggalMulai: null,
                tanggalSelesai: null,
                statusJadwalDokters: [],
                durationInMinute: 10,
                slotIndexBlocked: null,
                description: "CUTI"
            }
        }
    ],
    status: 200
};

const jadwalByDokter = {
    data: [
        {
            id: 876351,
            tanggal: "2021-07-08",
            status: "CANCELLED",
            jadwal: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:05:05.220Z",
                createdDateTime: "2021-07-06T00:05:05.22",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:08:43.463Z",
                lastModifiedDateTime: "2021-07-06T00:08:43.463",
                id: 876301,
                hari: "THURSDAY",
                jamMulai: "10:00",
                jamSelesai: "10:50",
                dokter: {
                    createdBy: "spv-fo",
                    createdDate: "2021-07-05T17:03:17.139Z",
                    createdDateTime: "2021-07-06T00:03:17.139",
                    lastModifiedBy: "spv-fo",
                    lastModifiedDate: "2021-07-05T17:04:32.990Z",
                    lastModifiedDateTime: "2021-07-06T00:04:32.99",
                    id: 875333,
                    nama: "dr.Test Permission1",
                    tanggalLahir: "1989-05-31",
                    tujuanRujukan: {
                        createdBy: "admin",
                        createdDate: "2021-06-30T04:08:42.266Z",
                        createdDateTime: "2021-06-30T11:08:42.266",
                        lastModifiedBy: "admin",
                        lastModifiedDate: "2021-06-30T04:08:42.266Z",
                        lastModifiedDateTime: "2021-06-30T11:08:42.266",
                        id: 871301,
                        nama: "Rawat Test",
                        grupRujukan: "PELAYANANMEDIS",
                        parentId: null
                    },
                    isDeleted: true,
                    isPublished: true,
                    textToSpeech: null,
                    tarif: null,
                    userId: null,
                    description: null,
                    profileCss: null,
                    profileMhg: null,
                    profileOvveride: null,
                    dokterKliniks: null,
                    dokterSpesialisasis: null,
                    dokterSubSpesialisasis: null
                },
                isDeleted: true,
                tanggalMulai: null,
                tanggalSelesai: null,
                statusJadwalDokters: [],
                durationInMinute: 10,
                slotIndexBlocked: null,
                description: "TIDAK TAHU"
            }
        },
        {
            id: 923352,
            tanggal: "2021-08-25",
            status: "CANCELLED",
            jadwal: {
                createdBy: "admin",
                createdDate: "2021-07-11T23:34:24.196Z",
                createdDateTime: "2021-07-12T06:34:24.196",
                lastModifiedBy: "admin",
                lastModifiedDate: "2021-07-11T23:34:24.196Z",
                lastModifiedDateTime: "2021-07-12T06:34:24.196",
                id: 895442,
                hari: "WEDNESDAY",
                jamMulai: "18:00",
                jamSelesai: "20:00",
                dokter: {
                    createdBy: "spv-fo",
                    createdDate: "2021-07-05T17:03:17.139Z",
                    createdDateTime: "2021-07-06T00:03:17.139",
                    lastModifiedBy: "spv-fo",
                    lastModifiedDate: "2021-07-05T17:04:32.990Z",
                    lastModifiedDateTime: "2021-07-06T00:04:32.99",
                    id: 875333,
                    nama: "dr.Test Permission1",
                    tanggalLahir: "1989-05-31",
                    tujuanRujukan: {
                        createdBy: "admin",
                        createdDate: "2021-06-30T04:08:42.266Z",
                        createdDateTime: "2021-06-30T11:08:42.266",
                        lastModifiedBy: "admin",
                        lastModifiedDate: "2021-06-30T04:08:42.266Z",
                        lastModifiedDateTime: "2021-06-30T11:08:42.266",
                        id: 871301,
                        nama: "Rawat Test",
                        grupRujukan: "PELAYANANMEDIS",
                        parentId: null
                    },
                    isDeleted: true,
                    isPublished: true,
                    textToSpeech: null,
                    tarif: null,
                    userId: null,
                    description: null,
                    profileCss: null,
                    profileMhg: null,
                    profileOvveride: null,
                    dokterKliniks: null,
                    dokterSpesialisasis: null,
                    dokterSubSpesialisasis: null
                },
                isDeleted: false,
                tanggalMulai: null,
                tanggalSelesai: null,
                statusJadwalDokters: [],
                durationInMinute: 10,
                slotIndexBlocked: null,
                description: "CUTI"
            }
        }
    ],
    status: 200
};

const jadwalByDokter2 = {
    data: [
        {
            createdBy: "spv-fo",
            createdDate: "2021-07-05T17:05:05.220Z",
            createdDateTime: "2021-07-06T00:05:05.22",
            lastModifiedBy: "spv-fo",
            lastModifiedDate: "2021-07-05T17:08:43.463Z",
            lastModifiedDateTime: "2021-07-06T00:08:43.463",
            id: 876301,
            hari: "THURSDAY",
            jamMulai: "10:00",
            jamSelesai: "10:50",
            dokter: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:03:17.139Z",
                createdDateTime: "2021-07-06T00:03:17.139",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:04:32.990Z",
                lastModifiedDateTime: "2021-07-06T00:04:32.99",
                id: 875333,
                nama: "dr.Test Permission1",
                tanggalLahir: "1989-05-31",
                tujuanRujukan: {
                    createdBy: "admin",
                    createdDate: "2021-06-30T04:08:42.266Z",
                    createdDateTime: "2021-06-30T11:08:42.266",
                    lastModifiedBy: "admin",
                    lastModifiedDate: "2021-06-30T04:08:42.266Z",
                    lastModifiedDateTime: "2021-06-30T11:08:42.266",
                    id: 871301,
                    nama: "Rawat Test",
                    grupRujukan: "PELAYANANMEDIS",
                    parentId: null
                },
                isDeleted: true,
                isPublished: true,
                textToSpeech: null,
                tarif: null,
                userId: null,
                description: null,
                profileCss: null,
                profileMhg: null,
                profileOvveride: null,
                dokterKliniks: null,
                dokterSpesialisasis: null,
                dokterSubSpesialisasis: null
            },
            isDeleted: true,
            tanggalMulai: null,
            tanggalSelesai: null,
            statusJadwalDokters: [],
            durationInMinute: 10,
            slotIndexBlocked: null,
            description: "TIDAK TAHU"
        },
        {
            createdBy: "spv-fo",
            createdDate: "2021-07-05T17:05:05.220Z",
            createdDateTime: "2021-07-06T00:05:05.22",
            lastModifiedBy: "spv-fo",
            lastModifiedDate: "2021-07-05T17:08:43.463Z",
            lastModifiedDateTime: "2021-07-06T00:08:43.463",
            id: 876302,
            hari: "MONDAY",
            jamMulai: "14:00",
            jamSelesai: "19:50",
            dokter: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:03:17.139Z",
                createdDateTime: "2021-07-06T00:03:17.139",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:04:32.990Z",
                lastModifiedDateTime: "2021-07-06T00:04:32.99",
                id: 875333,
                nama: "dr.Test Permission1",
                tanggalLahir: "1989-05-31",
                tujuanRujukan: {
                    createdBy: "admin",
                    createdDate: "2021-06-30T04:08:42.266Z",
                    createdDateTime: "2021-06-30T11:08:42.266",
                    lastModifiedBy: "admin",
                    lastModifiedDate: "2021-06-30T04:08:42.266Z",
                    lastModifiedDateTime: "2021-06-30T11:08:42.266",
                    id: 871301,
                    nama: "Rawat Test",
                    grupRujukan: "PELAYANANMEDIS",
                    parentId: null
                },
                isDeleted: true,
                isPublished: true,
                textToSpeech: null,
                tarif: null,
                userId: null,
                description: null,
                profileCss: null,
                profileMhg: null,
                profileOvveride: null,
                dokterKliniks: null,
                dokterSpesialisasis: null,
                dokterSubSpesialisasis: null
            },
            isDeleted: true,
            tanggalMulai: null,
            tanggalSelesai: null,
            statusJadwalDokters: [],
            durationInMinute: 10,
            slotIndexBlocked: null,
            description: "TIDAK TAHU"
        },
        {
            createdBy: "spv-fo",
            createdDate: "2021-07-05T17:05:05.220Z",
            createdDateTime: "2021-07-06T00:05:05.22",
            lastModifiedBy: "spv-fo",
            lastModifiedDate: "2021-07-05T17:08:43.463Z",
            lastModifiedDateTime: "2021-07-06T00:08:43.463",
            id: 876303,
            hari: "MONDAY",
            jamMulai: "10:00",
            jamSelesai: "10:50",
            dokter: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:03:17.139Z",
                createdDateTime: "2021-07-06T00:03:17.139",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:04:32.990Z",
                lastModifiedDateTime: "2021-07-06T00:04:32.99",
                id: 875333,
                nama: "dr.Test Permission1",
                tanggalLahir: "1989-05-31",
                tujuanRujukan: {
                    createdBy: "admin",
                    createdDate: "2021-06-30T04:08:42.266Z",
                    createdDateTime: "2021-06-30T11:08:42.266",
                    lastModifiedBy: "admin",
                    lastModifiedDate: "2021-06-30T04:08:42.266Z",
                    lastModifiedDateTime: "2021-06-30T11:08:42.266",
                    id: 871301,
                    nama: "Rawat Test",
                    grupRujukan: "PELAYANANMEDIS",
                    parentId: null
                },
                isDeleted: true,
                isPublished: true,
                textToSpeech: null,
                tarif: null,
                userId: null,
                description: null,
                profileCss: null,
                profileMhg: null,
                profileOvveride: null,
                dokterKliniks: null,
                dokterSpesialisasis: null,
                dokterSubSpesialisasis: null
            },
            isDeleted: true,
            tanggalMulai: null,
            tanggalSelesai: null,
            statusJadwalDokters: [],
            durationInMinute: 10,
            slotIndexBlocked: null,
            description: "TIDAK TAHU"
        },
        {
            createdBy: "spv-fo",
            createdDate: "2021-07-05T17:05:05.220Z",
            createdDateTime: "2021-07-06T00:05:05.22",
            lastModifiedBy: "spv-fo",
            lastModifiedDate: "2021-07-05T17:08:43.463Z",
            lastModifiedDateTime: "2021-07-06T00:08:43.463",
            id: 876321,
            hari: "FRIDAY",
            jamMulai: "5:00",
            jamSelesai: "10:50",
            dokter: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:03:17.139Z",
                createdDateTime: "2021-07-06T00:03:17.139",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:04:32.990Z",
                lastModifiedDateTime: "2021-07-06T00:04:32.99",
                id: 875333,
                nama: "dr.Test Permission1",
                tanggalLahir: "1989-05-31",
                tujuanRujukan: {
                    createdBy: "admin",
                    createdDate: "2021-06-30T04:08:42.266Z",
                    createdDateTime: "2021-06-30T11:08:42.266",
                    lastModifiedBy: "admin",
                    lastModifiedDate: "2021-06-30T04:08:42.266Z",
                    lastModifiedDateTime: "2021-06-30T11:08:42.266",
                    id: 871301,
                    nama: "Rawat Test",
                    grupRujukan: "PELAYANANMEDIS",
                    parentId: null
                },
                isDeleted: true,
                isPublished: true,
                textToSpeech: null,
                tarif: null,
                userId: null,
                description: null,
                profileCss: null,
                profileMhg: null,
                profileOvveride: null,
                dokterKliniks: null,
                dokterSpesialisasis: null,
                dokterSubSpesialisasis: null
            },
            isDeleted: true,
            tanggalMulai: null,
            tanggalSelesai: null,
            statusJadwalDokters: [],
            durationInMinute: 10,
            slotIndexBlocked: null,
            description: "TIDAK TAHU"
        },
        {
            createdBy: "spv-fo",
            createdDate: "2021-07-05T17:05:05.220Z",
            createdDateTime: "2021-07-06T00:05:05.22",
            lastModifiedBy: "spv-fo",
            lastModifiedDate: "2021-07-05T17:08:43.463Z",
            lastModifiedDateTime: "2021-07-06T00:08:43.463",
            id: 876301,
            hari: "FRIDAY",
            jamMulai: "15:00",
            jamSelesai: "20:50",
            dokter: {
                createdBy: "spv-fo",
                createdDate: "2021-07-05T17:03:17.139Z",
                createdDateTime: "2021-07-06T00:03:17.139",
                lastModifiedBy: "spv-fo",
                lastModifiedDate: "2021-07-05T17:04:32.990Z",
                lastModifiedDateTime: "2021-07-06T00:04:32.99",
                id: 875333,
                nama: "dr.Test Permission1",
                tanggalLahir: "1989-05-31",
                tujuanRujukan: {
                    createdBy: "admin",
                    createdDate: "2021-06-30T04:08:42.266Z",
                    createdDateTime: "2021-06-30T11:08:42.266",
                    lastModifiedBy: "admin",
                    lastModifiedDate: "2021-06-30T04:08:42.266Z",
                    lastModifiedDateTime: "2021-06-30T11:08:42.266",
                    id: 871301,
                    nama: "Rawat Test",
                    grupRujukan: "PELAYANANMEDIS",
                    parentId: null
                },
                isDeleted: true,
                isPublished: true,
                textToSpeech: null,
                tarif: null,
                userId: null,
                description: null,
                profileCss: null,
                profileMhg: null,
                profileOvveride: null,
                dokterKliniks: null,
                dokterSpesialisasis: null,
                dokterSubSpesialisasis: null
            },
            isDeleted: true,
            tanggalMulai: null,
            tanggalSelesai: null,
            statusJadwalDokters: [],
            durationInMinute: 10,
            slotIndexBlocked: null,
            description: "TIDAK TAHU"
        }
    ],
    status: 200
};

let dummyError = {
    response: {
        data: {
            message: {}
        }
    },
    status: 500
};
export default {
    errorHandler(error) {
        console.log(error);
        return {
            error: error.response.data.message,
            status: error.status,
            message: error.message
        };
    },
    getKetidakhadiran({ params }) {
        const randomNumber = Math.random() * 10;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (randomNumber > 0) {
                    resolve(dummy2);
                } else {
                    reject(dummyError);
                }
            }, 200);
        });
        // return TempRepository.get("/holidays", params);
        // return TempRepository({
        //     method: "get",
        //     url: `/${Holiday}s`,
        //     params: params
        // });
    },
    async postKetidakhadiran(payload) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 0) {
                        resolve({ status: 201, data: payload });
                    } else {
                        reject(dummyError);
                    }
                }, 1000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post Ketidakhadiran");
            return this.errorHandler(error);
        }
    },
    async getKetidakhadiranByDokterId(id) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 0) {
                        resolve(jadwalByDokter2);
                    } else {
                        reject(dummyError);
                    }
                }, 5000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get Ketidakhadiran By Dokter Id");
            return this.errorHandler(error);
        }
    },
    async delKetidakhadiran(id) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 9) {
                        resolve({ status: 200, data: id });
                    } else {
                        reject(dummyError);
                    }
                }, 1000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "del Ketidakhadiran");
            return this.errorHandler(error);
        }
    }
};
