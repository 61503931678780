// import axios from 'axios';
// import { RepositoryFactory } from './../repositories/RepositoryFactory';
// const AccountRepository = RepositoryFactory.get('account');

export default class CheckPermission {
    async updatePermission(isSelect = false) {
        // await AccountRepository.get()
        return new Promise((resolve, reject) => {
            window.keycloak.loadUserInfo()
            .then(response => { 
                var arrayOfPermission = [];
                window.userName = response.name
                // $.each(response.data.userGroups, function (
                //     index,
                //     valueUserGroup
                // ) {
                    $.each(response.userGroupPermissions, function (
                        index,
                        valueUserGroupPermission
                    ) {
                        arrayOfPermission.push(
                            valueUserGroupPermission
                        );
                    });
                // });
                localStorage.setItem('permission', arrayOfPermission);
                resolve(arrayOfPermission);
                // resolve(response)
            })
            .catch(error => {
                if (!isSelect) {
                        window.keycloak.updateToken(-1)
                            .success(() => {
                                localStorage.setItem("tokens", window.keycloak.token);
                                localStorage.setItem("refreshTokens", window.keycloak.refreshToken);
                                resolve(this.updatePermission(true))
                        })
                        .error(() => {
                            localStorage.removeItem('refreshTokens')
                            localStorage.removeItem('tokens')
                        })
                }
                reject(new Error(error))
            });
        })
       
    }

    isCanAccess(permissionName) {
        // return true;
        var params = [];
        if (permissionName instanceof Array) params = permissionName;
        else params = [permissionName];
        var arrayPermission = localStorage.getItem('permission').split(',');
        var result = _.intersection(arrayPermission, params).length > 0;
        if(!result && process.env.VUE_APP_DEVELOPMENT == 'development') console.log(permissionName)
        return result
    }
}
