import axios from "axios";
import logoutHelpers from "../services/logOut.service";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/commservice/api`;

// import createAuthRefreshInterceptor from 'axios-auth-refresh';

const instance4 = axios.create({
    baseURL
});

instance4.interceptors.request.use(request => {
    request.headers['Authorization'] = "Bearer " + localStorage.tokens;
    return request;
},
error => Promise.reject(error));

// const refreshAuthLogic = failedRequest => axios.post(baseDomain+'/auth/refresh-token',{}, {
//     headers: {
//         refreshToken: refresh_token
//     }
// }).then(tokenRefreshResponse => {
//     window.access_token = tokenRefreshResponse.data.access_token;
//     window.refresh_token = tokenRefreshResponse.data.refresh_token;
//     var base_url = window.location.origin +'/update_token';
//     axios.post(base_url,tokenRefreshResponse.data)
//     .then(res => {
//         failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access_token;
//         return Promise.resolve();
//     })

// });

// createAuthRefreshInterceptor(instance4, refreshAuthLogic);
instance4.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if ((error.response.status === 403  || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      window.keycloak.updateToken(-1).success(() => {
          localStorage.setItem("tokens", window.keycloak.token);
          return instance4(originalRequest);
      }).error(() => {
          if(localStorage.tokens) {
              logoutHelpers.logout()
          } else {
              logoutHelpers.doLogout()
          }
      });
  } else {
      return Promise.reject(error);
  }
});

export default instance4
