import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const link = "/transaksi-referrals";
const linkSearch = "/_search/transaksi-referrals-relational";
const defaultConfigs = { params: { "size": 1000 } };
import createRequestOption from './RequestParamUtil';

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(link, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    fetch(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${link}`, defaultConfigs);
    },
    fetchEmpty() {
        return new Promise((resolve, reject) => {
            resolve({
                data: [],
                headers: {
                    'x-total-count': 0
                }
            });
        })
    },
    search(req) {

        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${linkSearch}`, defaultConfigs);
    },
    find(id) {
        return Repository.get(`${link}/${id}`, defaultConfigs);
    },
    create(payload) {
        return Repository.post(`${link}`, payload);
    },
    update(payload) {
        return Repository.put(`${link}`, payload);
    },
    delete(id) {
        return Repository.delete(`${link}/${id}`);
    },
    addJejak(payload) {
        return Repository.post(`/jejaks`, payload);
    }
}