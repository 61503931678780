import Repository from './RepositoryChat';
import createRequestOption from './RequestParamUtil';

export default {
    get(req) {
        const defaultConfigs =  createRequestOption(req);
        return Repository.get('/list-group', defaultConfigs );
    },

}
