import { RepositoryFactory } from "../repositories/RepositoryFactory";
const ChatRepository = RepositoryFactory.get("chat");
import audioURL from '@/assets/notification/chime.mp3'
const audio = new Audio(audioURL);

export default {
    namespaced: true,
    state: {
        listGroupChat: [],
        totalUnread: 0,
        isLoginChat: false
    },
    mutations: {
        SET_IS_LOGIN_CHAT(state){
            state.isLoginChat = true;
        },
        SET_LIST_CHAT_GROUP (state, res) {
            state.listGroupChat = res.data;
            res.context.commit('SET_UNREAD_CHAT');
        },
        UPDATE_NEW_CHAT_GROUP (state, data){
            const index = state.listGroupChat.findIndex(x => x.rocketChatId == data.id);
            if(index > -1){
                state.listGroupChat[index].lastMessage.chatUserDTO.name = data.lastMessage.user.name;
                state.listGroupChat[index].lastMessage.chatUserDTO.username = data.lastMessage.user.username;
                state.listGroupChat[index].lastMessage.chatUserDTO.rocketChatId = data.lastMessage.user.id;
                state.listGroupChat[index].lastMessage.msg = data.lastMessage.msg;
                state.listGroupChat[index].lastMessage.timeStamp = moment.utc(data.lastMessage.updatedAt.$date).format("YYYY-MM-DDTHH:mm:ss.SSS");
                if(!data.lastMessage.isSelf){
                    audio.play();
                }
            }
        },
        UPDATE_UNREAD_CHAT (state, res){
            const data = res.data;
            const index = state.listGroupChat.findIndex(x => x.rocketChatId == data.id);
            if(index > -1){
                state.listGroupChat[index].userMentions = data.userMentions;
                state.listGroupChat[index].groupMentions = data.groupMentions;
                state.listGroupChat[index].unread = data.unread;
            }
            res.context.commit('SET_UNREAD_CHAT');
        },
        SET_UNREAD_CHAT (state){
            const unread = state.listGroupChat.map(x => x.unread).reduce((a, b) => a + b, 0);
            state.totalUnread = unread;
        }
    },
    actions: {
        GET_LIST_CHAT_GROUP(context, rtrc){
            rtrc.unsubNewChatChanged()
            rtrc.unsubUnreadChanged()
            ChatRepository.get({mergeWithSubscriptions:true}).then(res => {
                rtrc.subNewChatChanged(function(room){
                    // console.log('NEW CHATS');
                    context.commit('UPDATE_NEW_CHAT_GROUP', room);
                });
                rtrc.subUnreadChanged(function(room){
                    // console.log('NEW UNREAD:', room);
                    context.commit('UPDATE_UNREAD_CHAT', {
                        data: room,
                        context: context
                    });
                });
                context.commit('SET_LIST_CHAT_GROUP',  {
                    data: res.data,
                    context: context
                });
            })
        },
        GET_TOTAL_UNREAD(context){
            context.commit('SET_UNREAD_CHAT');
        },
        SET_LOGIN_CHAT(context){
            context.commit('SET_IS_LOGIN_CHAT');
        },
        INIT_CHAT(context, rtrc){
            rtrc.toggleLogging(false);
            rtrc.init(
                process.env.VUE_APP_WEB_SOCKET_CHAT,
                window.auth_token,
                function() {
                    console.log('CONNECTED');
                },
                function() {
                    // console.log('AFTER LOGIN');
                    context.dispatch('GET_LIST_CHAT_GROUP', rtrc);
                }
            );
        }
    },
    getters: {
        listChatGroup: state => {
            return state.listGroupChat;
        }
    }

}
