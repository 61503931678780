import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const appConfig = "/application-configurations";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(appConfig, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getAppConfig(appId) {
        return Repository.get(`${appConfig}/${appId}`, defaultConfigs);
    },
    createAppConfig(payload) {
        return Repository.post(`${appConfig}`, payload);
    },
    updateAppConfig(payload) {
        return Repository.put(`${appConfig}`, payload);
    },
    deleteAppConfig(appId) {
        return Repository.delete(`${appConfig}/${appId}`);
    },
    getKeys(){
        return Repository.get('/application-configurations/application-properties-keys');
    },
    refresh(){
        return Repository.post(`${appConfig}/refresh-application-properties`);
    }
}
