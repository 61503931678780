import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const guarantor = "/pics";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(guarantor, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getPic(picId) {
        return Repository.get(`${guarantor}/${picId}`, defaultConfigs);
    },
    createPic(payload) {
        return Repository.post(`${guarantor}`, payload);
    },
    updatePic(payload) {
        return Repository.put(`${guarantor}`, payload);
    },
    deletePic(picId) {
        return Repository.delete(`${guarantor}/${picId}`);
    }
}
