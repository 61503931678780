export default function FarmasiGetTodo() {
    var todaysDate = moment().format('YYYY-MM-DD');
    return {
        queueUnitIds: process.env.VUE_APP_PHARMACY_RACIKAN +
        ',' +
        process.env.VUE_APP_PHARMACY_NONRACIKAN,
        dateStart: todaysDate,
        dateEnd: todaysDate,
        sort: 'queue.id'
    };
}
