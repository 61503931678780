import { RepositoryFactory } from '../repositories/RepositoryFactory';
const PicPharmacyRepository = RepositoryFactory.get('picPharmacy');
import PicFarmasi from '../model/pic-farmasi-model';

export default {
    namespaced: true,
    state: {
        picList: []
    },
    mutations: {
        GET_PIC_PHARMACY(state) {
            new Promise((resolve, reject) => {
                PicPharmacyRepository.get({}, resolve, reject);
            })
                .then(response => {
                    state.picList = [];
                    $.each(response.data, function(key, value) {
                        let picFarmasi = new PicFarmasi();
                        picFarmasi.id = value.id;
                        picFarmasi.fullname = value.fullname;
                        picFarmasi.dateOfBirth = value.dateOfBirth;

                        state.picList.push(picFarmasi);
                    });
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                    toastr.error('Select PIC : ' + msg);
                });
        }
    },
    actions: {
        getPicFarmasiList(context) {
            context.commit('GET_PIC_PHARMACY');
        }
    },
    getters: {}
};
