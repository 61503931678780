import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
import Axios from 'axios';
const queues = "/queues";
const call = "/call-queues";
const manual = "/manual-queues";
const serving = "/serving-queues";
const done = "/done-queues";
const next = "/next-queues";
const skip = "/skip-queues";
const timeline = "/queues-walkin/timeline"
const createQueue = "/create-queues";
const recall = "/recall-queues";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queues, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    updateQueueResource(payload) {
        return Repository.put(`${queues}`, payload);
    },
    postCallResource(payload){
        return Repository.post(`${call}`, payload);
    },
    postServingResource(payload){
        return Repository.post(`${serving}`, payload);
    },
    postDoneResource(payload){
        return Repository.post(`${done}`, payload); 
    },
    postNextResource(payload){
        return Repository.post(`${next}`, payload)
    },
    postSkipResource(payload){
        return Repository.post(`${skip}`, payload);
    },
    createNewQueue(payload){
        return Repository.post(`${createQueue}`, payload)
    },
    getQueueById(id){
        return Repository.get(`${queues}/${id}`)
    },
    postManualCallResource(payload){
        return Repository.post(`${manual}`, payload);
    },
    postRecallQueue(payload){
        return Repository.post(`${recall}`, payload)
    },
    postWalkinTimeline(payload){
        return Repository.post(`${timeline}`, payload)
    }
}
