import QueueUserRepository from "./QueueUserRepository";
import QueueUnitRepository from "./QueueUnitRepository";
import UnitRepository from "./UnitRepository";
import PicPharmacyRepository from "./PicPharmacyRepository";
import ServerTimestampRepository from "./ServerTimestampRepository";
import ReservationRepository from "./ReservationRepository";
import PatientRepository from "./PatientRepository";
import UserRepository from "./UserRepository";
import ScheduleRepository from "./ScheduleRepository";
import JenisPenjaminRepository from "./JenisPenjaminRepository";
import TujuanRujukanRepository from "./TujuanRujukanRepository";
import QueueDeviceRepository from "./QueueDeviceRepository";
import PharmacyRepository from "./PharmacyRepository";
import DoctorRepository from "./DoctorRepository";
import FeedbackRepository from "./FeedbackRepository";
import UserGroupRepository from "./UserGroupRepository";
import PhotoRepository from "./PhotoRepository";
import TugasRepository from "./TugasRepository";
import TindakanRepository from "./TindakanRepository";
import KelasRepository from "./KelasRepository";
import KategoriRepository from "./KategoriRepository";
import AccountRepository from "./AccountRepository";
import ReportRepository from "./ReportRepository";
import PerujukRepository from "./PerujukRepository";
import SkemaRujukanRepository from "./SkemaRujukanRepository";
import ApplicationConfigurationRepository from "./ApplicationConfigurationRepository";
import ChatRepository from "./ChatRepository";
import StatusJadwalDokterRepository from "./StatusJadwalDokterRepository";
import MRLocationRepository from "./MRLocationRepository";
import MRTrackingRepository from "./MRTrackingRepository";
import CarouselRepository from "./CarouselRepository";
import LabelRepository from "./LabelRepository";
import RegistrasiPerujukRepository from "./RegistrasiPerujukRepository";
import PicRepository from "./PicRepository";
import TransaksiReferralRepository from "./TransaksiReferralRepository";
import ElasticRepository from "./ElasticRepository";
import GenderRepository from "./GenderRepository";
import ChatUserRepository from "./ChatUserRepository";
import SmsTemplateRepository from "./SmsTemplateRepository";
import SmsHistoryRepository from "./SmsHistoryRepository";
import UserNotificationRepository from "./UserNotificationRepository";
import SlotJadwalResource from "./SlotJadwalResourceRepository";
import QueueResourceRepository from "./QueueResourceRepository";
import PrinterResourceRepository from "./PrinterResourceRepository";
import TelekonsulRepository from "./TelekonsulRepository";
import TarifRepository from "./TarifRepository";
import PatientUserPatientRepository from "./PatientUserPatientRepository";
import UserPatientRepository from "./UserPatientRepository";
import RoomDisplayRepository from "./RoomDisplayRepository";
import TenantUserRepository from "./TenantUserRepository";
import EntityVersionRepository from "./EntityVersionData";
import DriveThruRepository from "./DriveThruRepository";
import HolidayRepository from "./HolidayRepository";
import MasterDoctorCardRepository from "./MasterDoctorCardRepository";
import KetidakhadiranRepository from "./KetidakhadiranRepository";
import DummyKetidakhadiranRepository from "./Dummy-KetidakhadiranRepository";
import MasterKlinikRepository from "./MasterKlinikRepository";
import MasterSpesialisasiRepository from "./MasterSpesialisasiRepository";
import MasterSubspesialisasiRepository from "./MasterSubspesialisasiRepository";
import DummyMasterTarifRepository from "./Dummy-MasterTarifRepository";
import MasterTarifRepository from "./MasterTarifRepository";
const repositories = {
    queueUser: QueueUserRepository,
    queueUnit: QueueUnitRepository,
    unit: UnitRepository,
    queueDevice: QueueDeviceRepository,
    reservation: ReservationRepository,
    pharmacy: PharmacyRepository,
    picPharmacy: PicPharmacyRepository,
    serverTimestamp: ServerTimestampRepository,
    patient: PatientRepository,
    user: UserRepository,
    account: AccountRepository,
    schedule: ScheduleRepository,
    jenisPenjamin: JenisPenjaminRepository,
    tujuanRujukan: TujuanRujukanRepository,
    doctor: DoctorRepository,
    feedback: FeedbackRepository,
    userGroup: UserGroupRepository,
    photo: PhotoRepository,
    carousel: CarouselRepository,
    tugas: TugasRepository,
    tindakan: TindakanRepository,
    kelas: KelasRepository,
    kategori: KategoriRepository,
    report: ReportRepository,
    perujuk: PerujukRepository,
    skemaRujukan: SkemaRujukanRepository,
    appConfig: ApplicationConfigurationRepository,
    chat: ChatRepository,
    statusJadwal: StatusJadwalDokterRepository,
    mrlocation: MRLocationRepository,
    mrtracking: MRTrackingRepository,
    label: LabelRepository,
    registrasiPerujuk: RegistrasiPerujukRepository,
    pic: PicRepository,
    transaksiReferral: TransaksiReferralRepository,
    elastic: ElasticRepository,
    gender: GenderRepository,
    chatUser: ChatUserRepository,
    smsTemplate: SmsTemplateRepository,
    smsHistory: SmsHistoryRepository,
    userNotification: UserNotificationRepository,
    SlotJadwalRes: SlotJadwalResource,
    queueResource: QueueResourceRepository,
    printerResource: PrinterResourceRepository,
    teleconsultResource: TelekonsulRepository,
    tarif: TarifRepository,
    patientUserPatient: PatientUserPatientRepository,
    userPatient: UserPatientRepository,
    roomDisplay: RoomDisplayRepository,
    tenantUser: TenantUserRepository,
    entityVersionRepository: EntityVersionRepository,
    driveThru: DriveThruRepository,
    holiday: HolidayRepository,
    doctorCards: MasterDoctorCardRepository,
    ketidakhadiran: KetidakhadiranRepository,
    dummyKetidakhadiran: DummyKetidakhadiranRepository,
    masterKlinik: MasterKlinikRepository,
    spe: MasterSpesialisasiRepository,
    subSpe: MasterSubspesialisasiRepository,
    masterTarif: MasterTarifRepository
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
