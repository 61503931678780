import SweetAlertTemplate from './../../../components/_general/SweetAlert';
import BlockUI from './../../../components/_js/BlockUI';
import Vue from 'vue';

const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    fetchData(context, data){
        context.state.repository.get(data.params, data.resolve, data.reject);
    },
    errorInfo(context, data){
        context.state.errorInfo = data;
    },
    async delete(context, data) {
        var deleteRepository = data.deleteRepository;
        var id = data.id;
        context.commit('SET_LOADING', {loading: true, message : 'Menghapus data ...'});
        await deleteRepository(id)
            .then(response => {
                let successMsg = _.isUndefined(response.data.message)?'Data berhasil terhapus.': response.data.message;
                new Promise((resolve, reject) => {
                    // setTimeout(()=>{
                    //     // context.commit('REFRESH_TABLE', true);
                    // },500);
                    resolve(response);
                }).then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Berhasil!',
                        swalText: _.isUndefined(context.state.swalMessage.deleteSuccess) ? successMsg : context.state.swalMessage.deleteSuccess,
                        swalType: 'success',
                        onConfirmButton: function() {
                            context.commit('REFRESH_TABLE', true);
                            blockUI.unblockPage();
                        }
                    });
                }).catch(error=>{
                    // blockUI.unblockPage();
                    context.commit('SET_LOADING', {loading: false, message : ''});
                    // context.commit('REFRESH_TABLE', true);
                });
            })
            .catch(error => {
                context.dispatch('errorInfo',error);
                context.commit('SET_LOADING', {loading: false, message : ''});
                let errorResponse = _.isUndefined(error.response.data.message)? '' : error.response.data.message;
                let instance = new SweetAlert().showInfo({
                    swalTitle: 'Gagal!',
                    // swalText: _.isUndefined(context.state.swalMessage.deleteFailed) ? 'Data gagal terhapus.' : context.state.swalMessage.deleteFailed,
                    swalText: _.isEmpty(errorResponse)
                        ? context.state.swalMessage.deleteFailed
                        : errorResponse,
                    swalType: 'error',
                    onConfirmButton: function() {
                        blockUI.unblockPage();
                    }
                });
            });
    },
    deleteById(context, data) {
        let message = (data.message) ? data.message : 'Data <b>' + data.name + '</b> akan terhapus.';
        let cfmConfig = {
            swalTitle: 'Apakah Anda yakin?',
            // swalText: _.isUndefined(context.state.swalMessage.deleteMessage) ? message : context.state.swalMessage.deleteMessage,
            swalText: message,
            swalType: 'warning',
            onConfirmButton: function() {
                context.dispatch('delete', data);
            },
            onCancelButton: function() {}
        };
        let instance = new SweetAlert().showConfirm(cfmConfig);
    },
}
