import Repository from './Repository';

const locations = "/mr-locations";
import paginateRepository from './AllPaginateRepository';
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(locations, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getLocation(locationId) {
        return Repository.get(`${locations}/${locationId}`);
    },
    createLocation(payload) {
        return Repository.post(`${locations}`, payload);
    },
    updateLocation(payload) {
        return Repository.put(`${locations}`, payload);
    },
    deleteLocation(locationId) {
        return Repository.delete(`${locations}/${locationId}`);
    }
}