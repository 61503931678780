export default {
    repository: null,
        isLoading: false,
        loadingMessage: 'Memuat Data ...',
        isShowModal: false,
        selectedMembers:[],
        modalName: null,
        errorInfo:false,
        shouldRefreshTable: false,
        showModalMode: null,
        swalMessage:{
            deleteSuccess:'Data berhasil terhapus.' ,
            deleteFailed:'Data gagal terhapus.' ,
            deleteMessage:'Data akan terhapus.' ,
            updateMessage: 'Perubahan data akan tersimpan.' ,
            updateSuccess:'Perubahan data berhasil disimpan.' ,
            updateFailed:'Perubahan data gagal disimpan.' ,
            createMessage:'Data akan ditambahkan.' ,
            createSuccess:'Data berhasil ditambahkan.' ,
            createFailed:'Data gagal ditambahkan.'
        },
        tempSelectedMembers:[],
        dataEdit: null
}
