import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const queues = "/queues";
const createQueues = "/create-queues";
const forwardQueues = "/forward-queues";
const defaultConfigs = { params: { "size": 1000000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(queues, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getQueueUser(queueUserId) {
        return Repository.get(`${queues}/${queueUserId}`);
    },
    createQueueUser(payload) {
        return Repository.post(`${queues}`, payload);
    },
    updateQueueUser(payload) {
        return Repository.put(`${queues}`, payload);
    },
    deleteQueueUser(queueUserId) {
        return Repository.delete(`${queues}/${queueUserId}`);
    },
    createQueueUserAuto(payload) {
        return Repository.post(`${createQueues}`, payload);
    },
    forwardQueueUser(payload) {
        return Repository.post(`${forwardQueues}`, payload);
    }
}
