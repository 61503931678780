import { RepositoryFactory } from "../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
const serverTimestamp = RepositoryFactory.get("serverTimestamp");
import CallerType from "./../constants/caller-type-enum";
import QueueDevice from "./../model/queue-device-model";
const QueueUnitRepository = RepositoryFactory.get('queueUnit')
const MasterDoctorRepo = RepositoryFactory.get("doctorCards")
function defaultSpesialisasi(array){
    let defaultSpesialisasi = null
    if (Array.isArray(array)) {
        array.forEach(item => {
            // console.log(item,'itemmss');
            if (item.isDefault) {
                if (item.spesialisasi) {
                    defaultSpesialisasi = spesialisasiMapper(item.spesialisasi) 
                }
            }
        })
    return defaultSpesialisasi
    }
}
function   spesialisasiMapper(spesialisasi){
    if(spesialisasi){
        return {
            id: spesialisasi.id,
            nama: spesialisasi.namaIndo
        }
    }
}

export default {
    namespaced: true,
    state: {
        selectedSlot: null,
        selectedDate: moment().format('YYYY-MM-DD'),
        listBookmarkDokter: [],
        dokter: null,
        jadwal: null,
        serverTime: null,
        listDataDokter: [],
        listDataDokterGroup: [],
        listOptionDokter: [],
        loadingDokter: false,
        loadingTimeLine: false,
        showModalPindah: false,
        resources: [
            { id: 'b', title: 'Booking' },
            { id: 'w', title: 'Walk-in' }
        ],
        events: [],
        moveTo: null,
        iniSlot: null,
        showModalHelp: false,
        doctorCallerName: 'POLI',
        queueUnitPassword: false,
        doctorLogin: false,
        showModalLinkTelekonsul: false,
        linkTelekonsul: ""
    },
    mutations: {
        SET_SELECTED_SLOT(state, res) {
            state.selectedSlot = res;
        },
        SET_LINK_TELEKONSUL(state, res) {
            state.linkTelekonsul = res;
        },
        SET_SHOW_LINK_TELEKONSUL(state, res) {
            state.showModalLinkTelekonsul = res;
        },
        SET_QUEUE_UNIT_PASSWORD(state, res) {
            state.queueUnitPassword = res;
        },
        SET_SHOW_MODAL_PINDAH(state, res) {
            state.showModalPindah = res;
        },
        SET_INI_SLOT(state, res) {
            state.iniSlot = res;
        },
        SET_SERVER_TIME(state, res) {
            state.serverTime = res;
        },
        SET_SELECTED_DATE(state, res) {
            state.selectedDate = res;
        },
        SET_LIST_DATA_DOKTER(state, res) {
            state.listDataDokter = res;
        },
        SET_LIST_DATA_DOKTER_GROUP(state, res) {
            state.listDataDokterGroup = res;
        },
        SET_LIST_OPTION_DOKTER(state, res) {
            state.listOptionDokter = res;
        },
        SET_LIST_BOOKMARK_DOKTER(state, res) {
            state.listBookmarkDokter = res;
            if (res.length > 0) {
                sessionStorage.setItem("doktorCallerBookmarkDokter", res.map(x => x.id));
            } else {
                sessionStorage.removeItem("doktorCallerBookmarkDokter");
            }
        },
        SET_DOKTER(state, res) {
            state.dokter = res;
            if (res != null) {
                sessionStorage.setItem("doktorCallerDokter", res.id);
            } else {
                sessionStorage.removeItem("doktorCallerDokter");
            }
        },
        SET_JADWAL(state, res) {
            state.jadwal = res;
            state.moveTo = (res) ? res.jamMulai : null;
        },
        SET_LOADING_DOKTER(state, res) {
            state.loadingDokter = res;
        },
        SET_EVENTS(state, res) {
            state.events = res;
        },
        SET_LOADING_TIMELINE(state, res) {
            state.loadingTimeLine = res
        },
        SET_SHOW_MODAL_HELP(state, res) {
            state.showModalHelp = res
        },
        SET_DOCTOR_CALLER_NAME(state, res) {
            state.doctorCallerName = res
            sessionStorage.setItem('doctorCallerName', res);
        },
        SET_DOCTOR_LOGIN(state, res) {
            state.doctorLogin = res
        }
    },
    actions: {
        async GET_SERVER_TIME({ commit, state, dispatch, rootState }) {
            try {
                const { data } = await serverTimestamp.get()
                var date = new Date(data);
                var date2 = new Date()
                let result = []

                var seconds = date.getSeconds() - date2.getSeconds();
                var minutes = date.getMinutes() - date2.getMinutes();
                var hours = date.getHours() - date2.getHours();
                result.push(hours, minutes, seconds)
                commit('SET_SERVER_TIME', result)
            } catch {
                toastr.error(err);
            }
        },
        async GET_DATA_DOCTOR({ commit, state, dispatch, rootState }) {
            try {
                var date = state.selectedDate;
                // var data = [];
                commit('SET_LOADING_DOKTER', true);
                let response
                if (state.doctorLogin) {
                    response = await new Promise((resolve, reject) => {
                        DoctorRepository.getCurrentDoctors({
                            'tanggalMulai.equals': date,
                            'tanggalSelesai.equals': date,
                        }, resolve, reject);
                    });
                } else {
                    response = await new Promise((resolve, reject) => {
                        MasterDoctorRepo.getAllDoctorList([], 1,date, resolve, reject);
                    });
                }
                let mappedData =  response.data.map(item => {
                    return {
                        ...item,
                        tujuanRujukan: defaultSpesialisasi(item.dokterSpesialisasis)
                    }
                })
                // mappedData.forEach(x => {
                //     var index = data.findIndex(y => y.id && x.tujuanRujukan && y.id == x.tujuanRujukan.id);
                //     if (index < 0) {
                //         const poly = { ...x.tujuanRujukan };
                //         poly.doctors = []
                //         data.push(poly);
                //         index = data.length - 1;
                //     }
                //     data[index].doctors.push(x);
                // })
                const data = mappedData.map(item => {
                    return {
                        ...item.tujuanRujukan,
                        doctors: [item]
                    };
                });
                commit("SET_LIST_DATA_DOKTER", mappedData);
                commit("SET_LIST_DATA_DOKTER_GROUP", data);
                commit("SET_LIST_OPTION_DOKTER", data);

                let device = (sessionStorage.getItem('queueDevice') == undefined) ? new QueueDevice() : { ...JSON.parse(sessionStorage.getItem('queueDevice')) };
                device.queueUnits = [];

                // List Bookmark dokter
                const localBookmark = sessionStorage.getItem("doktorCallerBookmarkDokter");
                if (localBookmark) {
                    let databookmark = []
                    const dataBookmarkArray = localBookmark.split(',')
                     dataBookmarkArray.forEach(item => {
                        const index = response.data.findIndex(items => items.id == item)
                        if(index > -1) {
                            databookmark.push(response.data[index])
                        }
                     })
                    commit('SET_LIST_BOOKMARK_DOKTER', databookmark);
                }

                // Selected Dokter
                const localDokter = sessionStorage.getItem("doktorCallerDokter");
                if (localDokter) {
                    const dataDokter = response.data.find(x => x.id == localDokter);
                    commit('SET_DOKTER', dataDokter);
                    device.dokters = [{
                        id: `${dataDokter.id}`
                    }];
                    const day = moment(date).locale("en").format("dddd").toUpperCase();
                    const hari = dataDokter.jadwals.find(y => y.hari == day);
                    if (hari) {
                        const found = hari.jam.find(x => {
                            const momentMulai = moment(`${date} ${x.jamMulai}`, 'YYYY-MM-DD HH:mm');
                            const momentSelesai = moment(`${date} ${x.jamSelesai}`, 'YYYY-MM-DD HH:mm');
                            if (moment().isBetween(momentMulai, momentSelesai)) {
                                return x;
                            }
                        })
                        commit('SET_JADWAL', (found) ? found : null);
                        if (found) {
                            dispatch('GET_DATA_QUEUE')
                        } else {
                            commit('SET_EVENTS', []);
                        }
                    }
                }

                if (device.id == null) {
                    device.notificationId = (rootState.CallerStore.userNotification != null) ? rootState.CallerStore.userNotification.notificationId : null;
                    device.uniqueId = rootState.CallerStore.sessionId;
                };
                dispatch('CallerStore/SAVE_QUEUE_DEVICE', device, { root: true });
                commit('SET_LOADING_DOKTER', false);

            } catch (err) {
                commit('SET_LOADING_DOKTER', false);
                toastr.error(err);
            }
        },
        async GET_DATA_QUEUE({ commit, state }) {
            try {
                commit('SET_LOADING_TIMELINE', true);
                // Ambil antrian , asumsi dapat 2 response => responseReservasi dan responseWalkin
                // validasi queueUnitPass atau bkn

                let getQueueOption
                if (state.queueUnitPassword) {
                    getQueueOption = new Promise((resolve, reject) => {
                        QueueUnitRepository.geQueueUnitSlotPassword({
                            "ids": `J${state.jadwal.id}`,
                            "tanggalKunjunganMulai": `${state.selectedDate}`,
                            "tanggalKunjunganSelesai": `${state.selectedDate}`,
                            "slotTypes": "APPOINTMENT",
                            // "dokterIds": `${state.dokter.id}`
                        }, resolve, reject);
                    })
                } else {
                    getQueueOption = new Promise((resolve, reject) => {
                        QueueUnitRepository.geQueueUnitSlot({
                            "ids": `J${state.jadwal.id}`,
                            "tanggalKunjunganMulai": `${state.selectedDate}`,
                            "tanggalKunjunganSelesai": `${state.selectedDate}`,
                            "slotTypes": "APPOINTMENT",
                            // "dokterIds": `${state.dokter.id}`
                        }, resolve, reject);
                    })
                }


                const { data } = await getQueueOption
                // console.log(data, 'ini listnya yaa dapet dong');
                // let responseWalkin = response.data

                // let responseReservasi = data.filter(x => x.queueMode == "BOOKING")
                let responseReservasi = data[0].slotJadwals
                // console.log(responseReservasi);

                // let responseWalkin = data.filter(x => x.queueMode == "WALKIN")
                let responseWalkin = data[0].queueWalkIns

                // console.log(responseWalkin, responseReservasi);

                let setTimeWalkin = helpers.setTime(responseWalkin, state.selectedDate, state.jadwal)

                // let setTimeBooking = helpers.setTime(responseReservasi, state.selectedDate, state.jadwal)

                // console.log(setTimeBooking, 'ini ini settimebooking');

                // map responseReservasi ke object event
                var typeBooking = responseReservasi.map(x => {
                    let obj = {
                        ...x,
                        queueNoFormatted: x.queue.queueNoFormatted,
                        queueStatus: x.queue.queueStatus,
                        lastModifiedDateTime: x.queue.lastModifiedDateTime,
                    }
                    if (x.slotDefect && x.slotDefect.id) {
                        obj.slotType = "DEFECT"
                        obj.queue.queueStatus = "DEFECT"
                        obj.queueStatus = "DEFECT"
                    }
                    return {
                        resourceId: 'b',
                        title: JSON.stringify(obj),
                        start: moment(`${state.selectedDate} ${x.jamMulai}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                        end: moment(`${state.selectedDate} ${x.jamSelesai}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                        editable: false,
                        backgroundColor: helpers.setColor(obj.queue.queueStatus),
                        textColor: 'white'
                    }
                });

                // fungsi dibawah sudah dipindahkan ke helpers.js
                // responseWalkin
                // Urutkan berdasarkan no Antrian
                // responseWalkin.sort((a, b) => a.queueNo.localeCompare(b.queueNo, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))
                // cari di responseWalkin yang jammulai dan jam selesainya ada null;

                // const listNullTime = responseWalkin.filter(x => x.estJamMulai == null && x.estJamSelesai == null);

                // // Kalau ada yang null maka harus diisi waktu nya
                // if(listNullTime.length > 0){
                //     // ambil duration di jadwal
                //     const duration = 15;
                //     //cari starttime untuk list yang tidak null
                //     const listMomentTime = responseWalkin.filter(x => x.estJamMulai !== null && x.estJamSelesai !== null).map(y => moment(`${state.selectedDate} ${y.estJamSelesai}`, 'YYYY-MM-DD HH:mm'));
                //     // console.log(listMomentTime, 'ini list moment time');

                //     const startMoment = (listMomentTime.length > 0) ? moment.max(listMomentTime) : moment(`${state.selectedDate} ${state.jadwal.jamMulai}`, 'YYYY-MM-DD HH:mm');
                //     // mari kita loop untuk memasukan start end time nya
                //     responseWalkin.forEach(x => {
                //         if(x.estJamMulai == null && x.estJamSelesai == null){
                //             x.estJamMulai = startMoment.clone().format('HH:mm');
                //             x.estJamSelesai = startMoment.add(duration, 'm').format('HH:mm');
                //         }
                //     })
                //     // Setelah loop lakukan put data dan set ulang di response walkin
                //     // const resp = await axios.put(responseWalkin) ....
                //     // responseWalkin = resp.data
                // }
                // Mapping response walkin ke object event
                var typeWalking = setTimeWalkin.map(x => {
                    return {
                        resourceId: 'w',
                        title: JSON.stringify(x),
                        start: moment(`${state.selectedDate} ${x.estJamMulai}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                        end: moment(`${state.selectedDate} ${x.estJamSelesai}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                        constraint: {
                            resourceIds: ['w']
                        },
                        overlap: true,
                        durationEditable: false,
                        backgroundColor: helpers.setColor(x.queueStatus),
                        textColor: 'white',
                        no: x.queueNoFormatted
                    }
                });
                commit('SET_EVENTS', [...typeBooking, ...typeWalking]);

                commit('SET_LOADING_TIMELINE', false);

            } catch (err) {
                toastr.error('Jadwal Dokter Tidak Tersedia');
                commit('SET_LOADING_DOKTER', false);
                commit('SET_LOADING_TIMELINE', false);
                // toastr.error(err);
            }
        },
    },
    getters: {
        GET_LIST_JADWAL_DOKTER: (state) => {
            if (state.dokter != null) {
                const day = moment(state.selectedDate).locale("en").format("dddd").toUpperCase();
                const hari = state.dokter.jadwals.find(y => y.hari == day);
                return (hari) ? hari.jam : []
            }
            return [];
        }
    }
}