import Repository from './RepositoryMandayauaa';
import RepositoryExternal from './Repository';
import paginateRepository from './AllPaginateRepositoryMandayauaa';
import createRequestOption from './RequestParamUtil';
const user = "/users";
const userById = "/user-by-id"
let defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(user, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
        // return Repository.get(`${user}`, defaultConfigs);
    },
    fetch(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${user}`, defaultConfigs);
    },
    getUser(login) {
        return Repository.get(`${user}/${login}`);
    },
    createUser(payload) {
        return RepositoryExternal.post('registrasis/back-office', payload);
    },
    updateUser(payload) {
        return Repository.put(`${user}`, payload);
    },
    deleteUser(login) {
        return Repository.delete(`${user}/${login}`);
    },
    getAuthorities() {
        return Repository.get(`${user}/authorities`);
    },
    search(req, param = {}) {
        // const defaultConfigs = createRequestOption(req);
        if(param.size) {
            return Repository.get(`/_search/users/${req}?page=${param.page}&size=${param.size}`);
        }
        return Repository.get(`/_search/users/${req}`);
    },
    forcePassword(payload) {
        return Repository.post('account/force-change-password', payload);
    },
    changePassword(payload) {
        return Repository.post('account/change-password', payload);
    },
    loginImpersonate(userId) {
        return Repository.post('/login/impersonate/', userId);
    },
    logoutImpersonate(userId) {
        return Repository.post('/logout/impersonate/');
    },
    getUserById(userId) {
        return Repository.get(`${userById}/${userId}`)
    }
}
