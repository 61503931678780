import { RepositoryFactory } from '../repositories/RepositoryFactory';
import FarmasiGetTodo from '../model/farmasi-get-todo-model';
import FarmasiGetInProgress from '../model/farmasi-get-inprogress-model';
import FarmasiGetDone from '../model/farmasi-get-done-model';
const PharmacyRepository = RepositoryFactory.get('pharmacy');

var idPharmacyRacikan = process.env.VUE_APP_PHARMACY_RACIKAN;
var idPharmacyNonRacikan = process.env.VUE_APP_PHARMACY_NONRACIKAN;

import notifUrl from '@/assets/notification/front-desk-bells-daniel_simon.mp3'
const audio = new Audio(notifUrl);

export default {
    namespaced: true,
    state: {
        filterLists: null,
        autoRefreshState: false,
        maxlastModifiedDate: null,
        dataToDo: [],
        dataInProgress: [],
        dataDone: [],
        pharmacyDataTodo: new FarmasiGetTodo(),
        pharmacyDataInProgress: new FarmasiGetInProgress(),
        pharmacyDataDone: new FarmasiGetDone(),
        firstLoad: false
    },
    mutations: {
        updateFirstLoad(state, firstLoad) {
            state.firstLoad = firstLoad;
        },
        updateAutoRefreshState(state, autoRefreshState) {
            state.autoRefreshState = autoRefreshState;
        },
        FILTER_CARD(state, payload) {
            state.maxlastModifiedDate = null;

            if (payload.data) {
                var boardLists = null;

                if (payload.data == 1) {
                    boardLists = idPharmacyRacikan + ',' + idPharmacyNonRacikan;
                } else if (payload.data == 2) {
                    boardLists = idPharmacyRacikan;
                } else if (payload.data == 3) {
                    boardLists = idPharmacyNonRacikan;
                }

                state.filterLists = state.pharmacyDataTodo.queueUnitIds = state.pharmacyDataInProgress[
                    'queueUnitId.in'
                ] = state.pharmacyDataDone['queueUnitId.in'] = boardLists;
                payload.resolve();
            } else {
                payload.reject();
            }
        },
        SET_TODO_BOARD(state, data) {
            state.autoRefreshState = false;

            // state.dataToDo = state.dataInProgress = state.dataDone = [];

            new Promise((resolve, reject) => {
                PharmacyRepository.getTodo(
                    state.pharmacyDataTodo,
                    resolve,
                    reject
                );
            })
                .then(response => {
                    if (response.data.length > 0) {
                        var filteredData = _.filter(response.data, function(
                            data
                        ) {
                            return data.id == null;
                        });

                        if (filteredData.length > 0) {
                            var currentLastModifiedDate = moment(
                                _.max(filteredData, function(data) {
                                    return moment(data.queue.lastModifiedDate);
                                }).queue.lastModifiedDate
                            );

                            if (
                                state.maxlastModifiedDate <
                                    currentLastModifiedDate &&
                                state.maxlastModifiedDate
                            ) {
                                audio.play();
                                toastr.info('Antrian Resep Baru.');
                            }

                            state.maxlastModifiedDate = currentLastModifiedDate;
                        }
                    }

                    state.dataToDo = response.data;
                    state.firstLoad = false;
                    data.resolve();
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                    state.autoRefreshState = true;
                    toastr.error(msg);
                    data.reject();
                });
        },
        SET_INPROGRESS_BOARD(state, data) {
            new Promise((resolve, reject) => {
                PharmacyRepository.get(
                    state.pharmacyDataInProgress,
                    resolve,
                    reject
                );
            })
                .then(response => {
                    state.dataInProgress = response.data;
                    data.resolve();
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                    state.autoRefreshState = true;
                    toastr.error(msg);
                    data.reject();
                });
        },
        SET_DONE_BOARD(state, data) {
            new Promise((resolve, reject) => {
                PharmacyRepository.get(state.pharmacyDataDone, resolve, reject);
            })
                .then(response => {
                    state.dataDone = response.data;
                    state.autoRefreshState = true;
                    data.resolve();
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                    state.autoRefreshState = true;
                    toastr.error(msg);
                    data.reject();
                });
        }
    },
    actions: {
        filteringCard(context, data) {
            return new Promise((resolve, reject) => {
                var payload = { data, resolve, reject };
                context.commit('FILTER_CARD', payload);
            })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        context.dispatch('setPharmacyBoard', {
                            resolve,
                            reject
                        });
                    });
                })
                .catch()
                .finally();
        },
        setPharmacyBoard(context, data) {
            return new Promise((resolve, reject) => {
                context.commit('SET_TODO_BOARD', { resolve, reject });
            })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        context.commit('SET_INPROGRESS_BOARD', {
                            resolve,
                            reject
                        });
                    })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                context.commit('SET_DONE_BOARD', {
                                    resolve,
                                    reject
                                });
                            }).then(() => {
                                if (data) {
                                    data.resolve();
                                }
                            });
                        })
                        .catch()
                        .finally();
                })
                .catch()
                .finally();
        },
        postCard(context, data) {
            return new Promise((resolve, reject) => {
                PharmacyRepository.postWorkOrder(data)
                    .then(response => {
                        context.dispatch('setPharmacyBoard', {
                            resolve,
                            reject
                        });
                    })
                    .catch(error => {
                        toastr.error(error);
                    });
            })
                .then()
                .catch()
                .finally();
        },
        putCard(context, data) {
            return new Promise((resolve, reject) => {
                PharmacyRepository.putWorkOrder(data)
                    .then(response => {
                        context.dispatch('setPharmacyBoard', {
                            resolve,
                            reject
                        });
                    })
                    .catch(error => {
                        toastr.error(error);
                    });
            })
                .then()
                .catch()
                .finally();
        }
    },
    getters: {}
};
