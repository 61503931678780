import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const pics = "/work-order-pics";
const defaultConfigs = { params: { "size": 1000, "sort":"fullname" } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(pics, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
        // return Repository.get(`${pics}`, defaultConfigs);
    },
    getPicPharmacy(picId) {
        return Repository.get(`${pics}/${picId}`, defaultConfigs);
    },
    createPicPharmacy(payload) {
        return Repository.post(`${pics}`, payload);
    },
    updatePicPharmacy(payload) {
        return Repository.put(`${pics}`, payload);
    },
    deletePicPharmacy(picId) {
        return Repository.delete(`${pics}/${picId}`);
    }
}
