import SweetAlertTemplate from './../components/_general/SweetAlert';
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from '../components/_js/BlockUI';
const blockUI = new BlockUI();
import Vue from 'vue';
import { RepositoryFactory } from "../repositories/RepositoryFactory";
const PatientUserPatientRepository = RepositoryFactory.get("patientUserPatient");

export default {
    namespaced: true,
    state: {
        repository: null,
        isLoaded: false,
        isShowModal: false,
        modalName: null,
        errorInfo: false,
        swalMessage: {
            deleteSuccess: 'Data berhasil terhapus.',
            deleteFailed: 'Data gagal terhapus.',
            deleteMessage: 'Data akan terhapus.',

            updateMessage: 'Perubahan data akan tersimpan.',
            updateSuccess: 'Perubahan data berhasil disimpan.',
            updateFailed: 'Perubahan data gagal disimpan.',

            createMessage: 'Data akan ditambahkan.',
            createSuccess: 'Data berhasil ditambahkan.',
            createFailed: 'Data gagal ditambahkan.'
        },
        table: {
            tableName: null,
            tableDataResult: [],
            tableColumnDefs: [],
            tableColumns: [],
            tableFilter: [],
            ordering: null,
            order: null,
            dateFormat: null
        },
        dataEdit: null,
        patientUserPatient: []
    },
    mutations: {
        SET_LOADED(state, data) {
            state.isLoaded = data;
        },
        SET_SWAL(state, data) {
            _.forEach(data, (i, k) => {
                if (!_.isUndefined(state.swalMessage[k])) {
                    state.swalMessage[k] = i;
                }
            })
        },
        RESET_STATE(state) {
            (state.repository = null),
                (state.isLoaded = false),
                (state.isShowModal = false),
                (state.modalName = null),
                (state.table = {
                    tableName: null,
                    tableDataResult: [],
                    tableColumnDefs: [],
                    tableColumns: [],
                    tableFilter: [],
                    ordering: null,
                    order: null,
                    dateFormat: null
                }),
                (state.dataEdit = null);
        },
        SET_REPOSITORY(state, data) {
            state.repository = data;
        },
        SET_MODAL(state, data) {
            state.modalName = data;
        },
        SET_EDIT_DATA(state, data) {
            state.dataEdit = data;
        },
        LOAD_DATA(state, resp) {
            var datatable = $('#' + state.table.tableName).DataTable();
            new Promise((resolve, reject) => {
                state.repository.get({}, resolve, reject);
            })
                .then(response => {
                    const data = response.data;

                    if (state.isLoaded) {
                        datatable.clear();
                        datatable.rows.add(data);
                        datatable.draw('full-hold');
                    } else {
                        state.table.tableDataResult = data;
                        state.isLoaded = true;
                    }
                    if (resp !== undefined) {
                        resp(data);
                    }
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                    toastr.error(msg);
                });
        },
        SETUP_TABLE(state, data) {
            state.table.tableName = data.tableName;
            state.table.tableDataResult = data.tableDataResult;
            state.table.tableColumnDefs = data.tableColumnDefs;
            state.table.tableColumns = data.tableColumns;
            state.table.tableFilter = data.tableFilter;
            state.table.ordering = (data.ordering) ? data.ordering : null;
            state.table.order = (data.order) ? data.ordering : null;
            state.table.dateFormat = (data.dateFormat) ? data.dateFormat : null;
        },
        SHOW_MODAL(state, data) {
            state.isShowModal = true;
        },
        CLEAR_DATA(state) {
            $('#' + state.modalName).modal('hide');
            state.patientUserPatient = []
            state.isShowModal = false;
        },
        SET_PATIENT_USER_PATIENT(state, res) {
            state.patientUserPatient = res
        }
    },
    actions: {
        fetch(context, resolve) {
            context.commit('LOAD_DATA', resolve);
        },
        errorInfo(context, data) {
            context.state.errorInfo = data;
        },
        async deleteServer(context, data) {
            var deleteRepository = data.deleteRepository;
            var id = data.id;
            var table = data.table;
            await deleteRepository(id)
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Berhasil!',
                        swalText: _.isUndefined(context.state.swalMessage.deleteSuccess) ? 'Data berhasil terhapus.' : context.state.swalMessage.deleteSuccess,
                        swalType: 'success',
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                            if (table) table.getData()
                        }
                    });
                })
                .catch(error => {
                    context.dispatch('errorInfo', error);
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Gagal!',
                        swalText: _.isUndefined(context.state.swalMessage.deleteFailed) ? 'Data gagal terhapus.' : context.state.swalMessage.deleteFailed,
                        swalType: 'error',
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
        },
        async delete(context, data) {
            var deleteRepository = data.deleteRepository;
            var id = data.id;
            var table = data.table;
            await deleteRepository(id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        context.dispatch('fetch', resolve);
                    }).then(response => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: 'Berhasil!',
                            swalText: _.isUndefined(context.state.swalMessage.deleteSuccess) ? 'Data berhasil terhapus.' : context.state.swalMessage.deleteSuccess,
                            swalType: 'success',
                            onConfirmButton: function () {
                                blockUI.unblockPage();
                                if (table) table.getData()
                            }
                        });
                    });
                })
                .catch(error => {
                    context.dispatch('errorInfo', error);
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Gagal!',
                        swalText: _.isUndefined(context.state.swalMessage.deleteFailed) ? 'Data gagal terhapus.' : context.state.swalMessage.deleteFailed,
                        swalType: 'error',
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
        },
        deleteById(context, data) {

            let cfmConfig = {
                swalTitle: 'Apakah Anda yakin?',
                swalText: _.isUndefined(context.state.swalMessage.deleteMessage) ? 'Data akan terhapus.' : context.state.swalMessage.deleteMessage,
                swalType: 'warning',
                onConfirmButton: function () {
                    blockUI.blockPage();
                    context.dispatch('delete', data);
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            };
            let instance = new SweetAlert().showConfirm(cfmConfig);
        },
        deleteByIdServer(context, data) {

            let cfmConfig = {
                swalTitle: 'Apakah Anda yakin?',
                swalText: _.isUndefined(context.state.swalMessage.deleteMessage) ? 'Data akan terhapus.' : context.state.swalMessage.deleteMessage,
                swalType: 'warning',
                onConfirmButton: function () {
                    blockUI.blockPage();
                    context.dispatch('deleteServer', data);
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            };
            let instance = new SweetAlert().showConfirm(cfmConfig);
        },
        updateById(context, data) {
            let instance = new SweetAlert().showConfirm({
                swalTitle: 'Apakah Anda yakin?',
                swalText: _.isUndefined(context.state.swalMessage.updateMessage) ? 'Perubahan data akan tersimpan.' : context.state.swalMessage.updateMessage,
                swalType: 'info',
                onConfirmButton: function () {
                    blockUI.blockModal();
                    context.dispatch('update', {data});
                },
                onCancelButton: function () {
                    blockUI.unblockModal();
                }
            });
        },
        updateByIdServer(context, data) {
            let instance = new SweetAlert().showConfirm({
                swalTitle: 'Apakah Anda yakin?',
                swalText: _.isUndefined(context.state.swalMessage.updateMessage) ? 'Perubahan data akan tersimpan.' : context.state.swalMessage.updateMessage,
                swalType: 'info',
                onConfirmButton: function () {
                    blockUI.blockModal();
                    context.dispatch('updateServer', {data});
                },
                onCancelButton: function () {
                    blockUI.unblockModal();
                }
            });
        },
        async updateServer(context, {data}){
            var repository = data.repository;
            var table = data.table;
            await repository(data.data)
                .then(response => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: 'Berhasil!',
                            swalText: _.isUndefined(context.state.swalMessage.updateSuccess) ? 'Perubahan data berhasil disimpan.' : context.state.swalMessage.updateSuccess,
                            swalType: 'success',
                            onConfirmButton: function () {
                                blockUI.unblockModal();
                                context.commit('CLEAR_DATA');
                                if (table) table.fetch()
                            }
                        });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Gagal!',
                        swalText: _.isUndefined(context.state.swalMessage.updateFailed) ? 'Perubahan data gagal disimpan.' : context.state.swalMessage.updateFailed,
                        swalType: 'error',
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async update(context, {data}) {
            var repository = data.repository;
            var table = data.table;
            await repository(data.data)
                .then(response => {
                    new Promise((resolve, reject) => {
                        context.dispatch('fetch', resolve);
                    }).then(response => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: 'Berhasil!',
                            swalText: _.isUndefined(context.state.swalMessage.updateSuccess) ? 'Perubahan data berhasil disimpan.' : context.state.swalMessage.updateSuccess,
                            swalType: 'success',
                            onConfirmButton: function () {
                                blockUI.unblockModal();
                                context.commit('CLEAR_DATA');
                                if (table) table.fetch()
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Gagal!',
                        swalText: _.isUndefined(context.state.swalMessage.updateFailed) ? 'Perubahan data gagal disimpan.' : context.state.swalMessage.updateFailed,
                        swalType: 'error',
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        createNew(context, data) {
            let instance = new SweetAlert().showConfirm({
                swalTitle: 'Apakah Anda yakin?',
                swalText: _.isUndefined(context.state.swalMessage.createMessage) ? 'Data akan ditambahkan.' : context.state.swalMessage.createMessage,
                swalType: 'info',
                onConfirmButton: function () {
                    blockUI.blockModal();
                    context.dispatch('create', {data});
                },
                onCancelButton: function () {
                    blockUI.unblockModal();
                }
            });
        },
        createNewServer(context, data) {
            let instance = new SweetAlert().showConfirm({
                swalTitle: 'Apakah Anda yakin?',
                swalText: _.isUndefined(context.state.swalMessage.createMessage) ? 'Data akan ditambahkan.' : context.state.swalMessage.createMessage,
                swalType: 'info',
                onConfirmButton: function () {
                    blockUI.blockModal();
                    context.dispatch('createServerSide', {data});
                },
                onCancelButton: function () {
                    blockUI.unblockModal();
                }
            });
        },
        async createServerSide(context, {data}) {
            var repository = data.repository;
            var table = data.table;
            await repository(data.data)
                .then(() => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Berhasil!',
                        swalText: _.isUndefined(context.state.swalMessage.createSuccess) ? 'Data berhasil ditambahkan.' : context.state.swalMessage.createSuccess,
                        swalType: 'success',
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                            context.commit('CLEAR_DATA');
                            if (table) table.fetch()
                        }
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Gagal!',
                        swalText: _.isUndefined(context.state.swalMessage.createFailed) ? 'Data gagal ditambahkan.' : context.state.swalMessage.createFailed,
                        swalType: 'error',
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async create(context, {data}) {
            var repository = data.repository;
            var table = data.table;
            await repository(data.data)
                .then(() => {
                    return new Promise((resolve, reject) => {
                        context.dispatch('fetch', resolve);
                    })
                })
                .then(() => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Berhasil!',
                        swalText: _.isUndefined(context.state.swalMessage.createSuccess) ? 'Data berhasil ditambahkan.' : context.state.swalMessage.createSuccess,
                        swalType: 'success',
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                            context.commit('CLEAR_DATA');
                            if (table) table.fetch()
                        }
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: 'Gagal!',
                        swalText: _.isUndefined(context.state.swalMessage.createFailed) ? 'Data gagal ditambahkan.' : context.state.swalMessage.createFailed,
                        swalType: 'error',
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        getPatientUserPatient(context, data) {
            new Promise((resolve, reject) => {
                PatientUserPatientRepository.get({ 'patientId.equals': data.id }, resolve, reject)
            })
                .then(({ data }) => {
                    context.commit('SET_PATIENT_USER_PATIENT', data)
                })
                .catch(error => {
                    toastr.error(error)
                })
        }
    },
    getters: {}
};
