import Repository from "./Repository";
// import Repository from "./RepositoryRizLocal";
const pcr = "/pcr";
const paketTest = `${pcr}/paket-pemeriksaan/valid`;
const nations = `${pcr}/country`;
const orderDetail = `${pcr}/order`;
const patientPcr = `${pcr}/update-patient-booking`;
const updatePaketBooking = `${pcr}/update-paket-booking`;
const inputNoHis = `${pcr}/inputNoRegistrasi`;
const inputPlatNo = `${pcr}/platNo`;
const getPlat = `${pcr}/plat`;
const pasienDatang = `${pcr}/arrive`;
const sampleDiterima = `${pcr}/collect`;
const kirimHasil = `${pcr}/send-result`;
const ubahPembayaran = `${pcr}/change-payment-type`;
const cancel = `${pcr}/cancel`;
const manualPaidOffline = `${pcr}/manual-paid-offline`;
const paketPetugasLab = `${pcr}/list-register-lab`;
const holiday = `${pcr}/holiday`;
const Ocr = "/ocr/v2";
import createRequestOption from "./RequestParamUtil";
const defaultConfigs = { params: { size: 10000 } };

export default {
    getPaket() {
        return Repository.get(`${paketTest}`);
    },
    getNations() {
        return Repository.get(`${nations}`);
    },
    getPcr() {
        return Repository.get(`${pcr}`, defaultConfigs);
    },
    getPcrPagination(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${pcr}`, defaultConfigs);
    },
    pcrBookingSearch(req, statusPasien) {
        const defaultConfigs = createRequestOption(req);
        if (statusPasien) {
            statusPasien.forEach(ele => {
                defaultConfigs.params.append("statusFlowTypes", ele);
            });
        }
        return Repository.get(`${pcr}`, defaultConfigs);
    },
    getPaketPetugasLab(req, statusPasien) {
        const defaultConfigs = createRequestOption(req);
        if (statusPasien) {
            statusPasien.forEach(ele => {
                defaultConfigs.params.append("statusFlowTypes", ele);
            });
        }
        return Repository.get(`${paketPetugasLab}`, defaultConfigs);
    },
    getPcrOrder(id) {
        return Repository.get(`${orderDetail}?orderId=${id}`);
    },
    putPatientPcr(payload) {
        return Repository.post(`${patientPcr}/${payload.id}`, payload.data, {
            params: { testId: payload.testId }
        });
    },
    updatePaketBooking(payload) {
        return Repository.post(`${updatePaketBooking}/${payload.id}`, [
            payload.data
        ]);
    },
    postNoHis(payload) {
        return Repository.post(`${inputNoHis}`, payload);
    },
    postPcr(payload) {
        return Repository.post(`${pcr}`, payload);
    },
    postPlatNo(payload) {
        return Repository.post(`${inputPlatNo}/${payload.id}`, payload.data);
    },
    getPhotoPlat(payload) {
        return Repository.get(`${getPlat}/${payload}`);
    },
    postPasienDatang(payload) {
        return Repository.post(`${pasienDatang}/${payload}`);
    },
    postSampelDiterima(payload) {
        return Repository.post(`${sampleDiterima}/${payload}`);
    },
    postKirimHasil(payload) {
        return Repository.post(
            `${kirimHasil}/${payload.orderId}`,
            payload.data
        );
    },
    postUbahPembayaran(payload) {
        return Repository.post(`${ubahPembayaran}/${payload.encId}`, null, {
            params: payload.data
        });
    },
    postCancelBooking(payload) {
        return Repository.post(`${cancel}/${payload}`);
    },
    postOcr(payload) {
        return Repository.post(`${Ocr}`, payload.data, {
            headers: {
                "content-type": "application/json",
                sig: payload.headers
            },
            params: {
                time: payload.time
            },
            cancelToken: payload.source.token
        });
    },
    manualPaidOff(payload) {
        return Repository.post(`${manualPaidOffline}`, null, {
            params: { orderId: payload }
        });
    },
    getHolidayDates() {
        return Repository.get(`${holiday}`);
    },
    async getEpidemiologi(payload) {
        const { testId } = payload;

        try {
            const resp = await Repository.get(
                `${pcr}/test-pemeriksaan-detail/${testId}`
            );
            console.log(resp, "getEpidemiologi");
            if (resp.status == 200) {
                return resp.data;
            } else {
                throw new Error("Error");
            }
        } catch (error) {
            console.log(error, "getEpidemiologi Error");
            return { error };
        }
    }
};
