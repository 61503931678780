export default function QueueDevice() {
    return {
        id: null,
        notificationId: null,
        uniqueId: null,
        queueUnits: [],
        dokters:[],
        friendlyName: null
    };
}
