import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const label = "/labels";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(label, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getLabel(labelId) {
        return Repository.get(`${label}/${labelId}`, defaultConfigs);
    },
    createLabel(payload) {
        return Repository.post(`${label}`, payload);
    },
    updateLabel(payload) {
        return Repository.put(`${label}`, payload);
    },
    deleteLabel(labelId) {
        return Repository.delete(`${label}/${labelId}`);
    }
}
