import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const teleconsult = "/teleconsult-room";
const genKey = "/generate-key";
const ready = "/ready"
const link = "/teleconsult-link-url"
const defaultConfigs = { params: { "size": 1000 } };

export default {
    generateRoomKey(payload) {
        return Repository.post(`${teleconsult}/generate-key`, payload);
    },
    generateKey(payload) {
        return Repository.post(`${genKey}`, payload)
    },
    generateLink(payload) {
        return Repository.get(`${link}`, payload)
    }
}
