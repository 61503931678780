// "id",
//     "icon",
//     "namaIndonesia",
//     "namaAwam",
//     "namaInggris",
//     "namaAwamInggris",
//     "tipeKlinik",
//     "status";

const dummy = {
    data: [
        {
            id: 1,
            nama: "tarif 1",
            deskripsi: "ini deskripsi tarif 1",
            jumlah: 10000
        },
        {
            id: 2,
            nama: "tarif 2",
            deskripsi: "ini deskripsi tarif 2",
            jumlah: 35000
        }
    ],
    statuis: 200
};
const dummy2 = {
    data: [
        {
            ID: 870951,
            CreatedAt: "2022-07-29T15:47:44.700181+07:00",
            UpdatedAt: "2022-07-29T15:47:44.700181+07:00",
            DeletedAt: null,
            tenant_id: 2,
            created_by: "admin",
            last_modified_by: "admin",
            nama: "MCU",
            description: "Medical Check Up",
            jumlah: 400000,
            dokters: null
        },
        {
            ID: 901801,
            CreatedAt: "2022-07-29T15:47:44.700181+07:00",
            UpdatedAt: "2022-07-29T15:47:44.700181+07:00",
            DeletedAt: null,
            tenant_id: 2,
            created_by: "admin",
            last_modified_by: "admin",
            nama: "ginanjar",
            description: "",
            jumlah: 0,
            dokters: null
        },
        {
            ID: 870351,
            CreatedAt: "2022-07-29T15:47:44.700181+07:00",
            UpdatedAt: "2022-07-29T15:47:44.700181+07:00",
            DeletedAt: null,
            tenant_id: 2,
            created_by: "admin",
            last_modified_by: "system",
            nama: "JiwaSrayaa",
            description: "",
            jumlah: 200000,
            dokters: null
        },
        {
            ID: 2,
            CreatedAt: "2022-08-02T14:21:04.128147+07:00",
            UpdatedAt: "2022-08-02T14:21:04.128147+07:00",
            DeletedAt: null,
            tenant_id: null,
            created_by: "",
            last_modified_by: "",
            nama: "Tarif Post 1 HOST",
            description: "Tarif Post 1 HOST",
            jumlah: 5000,
            dokters: null
        }
    ],
    status: 200
};
let dummyError = {
    response: {
        data: {
            message: {}
        }
    },
    status: 500
};
export default {
    errorHandler(error) {
        console.log(error);
        return {
            error: error.response.data.message,
            status: error.status,
            message: error.message
        };
    },
    getTarifs({ params }) {
        const randomNumber = Math.random() * 10;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (randomNumber > 0) {
                    resolve(dummy2);
                } else {
                    reject(dummyError);
                }
            }, 200);
        });
        // return TempRepository.get("/holidays", params);
        // return TempRepository({
        //     method: "get",
        //     url: `/${Holiday}s`,
        //     params: params
        // });
    },
    async postTarif(payload) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 0) {
                        resolve({ status: 201, data: payload });
                    } else {
                        reject(dummyError);
                    }
                }, 1000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post tarif");
            return this.errorHandler(error);
        }
    },
    async getOneTarif(id) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 0) {
                        resolve({
                            status: 200,
                            data: {
                                ID: 2,
                                CreatedAt: "2022-08-02T14:21:04.128147+07:00",
                                UpdatedAt: "2022-08-02T14:21:04.128147+07:00",
                                DeletedAt: null,
                                tenant_id: null,
                                created_by: "",
                                last_modified_by: "",
                                nama: "Tarif Post 1 HOST",
                                description: "Tarif Post 1 HOST",
                                jumlah: 5000,
                                dokters: [
                                    {
                                        ID: 2,
                                        CreatedAt:
                                            "2022-08-02T14:21:56.968925+07:00",
                                        UpdatedAt:
                                            "2022-08-02T14:21:56.968925+07:00",
                                        DeletedAt: null,
                                        tenant_id: 311884,
                                        created_by: "",
                                        last_modified_by: "",
                                        nama: "Dokter Golang",
                                        tanggalLahir: null,
                                        isDeleted: false,
                                        textToSpeech: "",
                                        isPublish: true,
                                        isBookAllow: true,
                                        jadwals: null,
                                        tarif: null,
                                        userId: 0,
                                        description: "",
                                        jenisKelamin: "MALE",
                                        dokterKliniks: null,
                                        dokterSpesialisasis: null,
                                        dokterSubSpesialisasis: null,
                                        profileCss: "",
                                        profileMhg: "",
                                        profileOvveride: ""
                                    },
                                    {
                                        ID: 3,
                                        CreatedAt:
                                            "2022-08-02T14:23:24.686037+07:00",
                                        UpdatedAt:
                                            "2022-08-02T14:23:24.686037+07:00",
                                        DeletedAt: null,
                                        tenant_id: 311884,
                                        created_by: "",
                                        last_modified_by: "",
                                        nama: "Dokter Golang",
                                        tanggalLahir: null,
                                        isDeleted: false,
                                        textToSpeech: "",
                                        isPublish: true,
                                        isBookAllow: true,
                                        jadwals: null,
                                        tarif: null,
                                        userId: 0,
                                        description: "",
                                        jenisKelamin: "MALE",
                                        dokterKliniks: null,
                                        dokterSpesialisasis: null,
                                        dokterSubSpesialisasis: null,
                                        profileCss: "",
                                        profileMhg: "",
                                        profileOvveride: ""
                                    },
                                    {
                                        ID: 5,
                                        CreatedAt:
                                            "2022-08-02T14:45:33.24698+07:00",
                                        UpdatedAt:
                                            "2022-08-02T14:45:33.24698+07:00",
                                        DeletedAt: null,
                                        tenant_id: 311884,
                                        created_by: "",
                                        last_modified_by: "",
                                        nama: "ABI RADITYA PUTRA FALAKI",
                                        tanggalLahir: null,
                                        isDeleted: false,
                                        textToSpeech: "",
                                        isPublish: true,
                                        isBookAllow: true,
                                        jadwals: null,
                                        tarif: null,
                                        userId: 0,
                                        description: "",
                                        jenisKelamin: "MALE",
                                        dokterKliniks: null,
                                        dokterSpesialisasis: null,
                                        dokterSubSpesialisasis: null,
                                        profileCss: "",
                                        profileMhg: "",
                                        profileOvveride: ""
                                    }
                                ]
                            }
                        });
                    } else {
                        reject(dummyError);
                    }
                }, 1000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "getOneTarif");
            return this.errorHandler(error);
        }
    },
    async putTarif(payload) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 0) {
                        resolve({ status: 200, data: payload });
                    } else {
                        reject(dummyError);
                    }
                }, 1000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "putTarif");
            return this.errorHandler(error);
        }
    },
    async delTarif(id) {
        try {
            const randomNumber = Math.random() * 10;
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (randomNumber > 0) {
                        resolve({ status: 200, data: id });
                    } else {
                        reject(dummyError);
                    }
                }, 1000);
            });
            // return TempRepository({
            //     method: "get",
            //     url: `/${Holiday}s`,
            //     params: params
            // });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "delTarif");
            return this.errorHandler(error);
        }
    }
};
