import state from "./crudState";
import mutations from "./crudMutations";
import actions from "./crudActions";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters : {},
};
