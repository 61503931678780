import axios from "axios";
import logoutHelpers from "@/services/logOut.service";
const dokterService = `dokter-service`;
const baseDomain = `${process.env.VUE_APP_API_URL}/${dokterService}/api`;
const baseURL = `${baseDomain}/v1`;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers["Authorization"] = "Bearer " + localStorage.tokens;
    request.headers["x-tenantid"] = window.tenantActive;
    request.headers.common["Access-Control-Allow-Origin"] = "*";

    return request;
});

instance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if ((error.response.status === 403  || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      window.keycloak.updateToken(-1).success(() => {
        localStorage.setItem("tokens", window.keycloak.token);
        return instance(originalRequest);
      }).error(() => {
          if(window.keycloak.token) {
              logoutHelpers.logout()
          } else {
              logoutHelpers.doLogout()
          }
      });
  } else {
      return Promise.reject(error);
  }
});

export default instance;
