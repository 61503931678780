import Repository from './RepositoryGallery';
const photo = '/photos';
const photo_wo_img = '/photos_wo_img';

export default {
    get(params) {
        return Repository.get(`${photo}`, { params: params });
    },
    getPhotoWOImg(params) {
        return Repository.get(`${photo_wo_img}`, { params: params });
    },
    getPhoto(photoId) {
        return Repository.get(`${photo}/${photoId}`);
    },
    createPhoto(payload) {
        return Repository.post(`${photo}`, payload);
    },
    updatePhoto(payload) {
        return Repository.put(`${photo}`, payload);
    },
    deletePhoto(photoId) {
        return Repository.delete(`${photo}/${photoId}`);
    }
};
