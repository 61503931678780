import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const roomDisplay = "/room-displays";
const changeDokter = "/room-displays/change-dokter";
const linkSearch = "/_search/room-displays";
import createRequestOption from './RequestParamUtil';

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(roomDisplay, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    fetch(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${roomDisplay}`, defaultConfigs);
    },
    search(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${linkSearch}`, defaultConfigs);
    },
    getRoom(id) {
        return Repository.get(`${roomDisplay}/${id}`);
    },
    createRoom(payload) {
        return Repository.post(`${roomDisplay}`, payload);
    },
    updateRoom(payload) {
        return Repository.put(`${roomDisplay}`, payload);
    },
    deleteRoom(id) {
        return Repository.delete(`${roomDisplay}/${id}`);
    },
    changeDokter(payload) {
        return Repository.post(`${changeDokter}`, payload);
    }
}