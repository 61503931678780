import axios from "axios";
import logoutHelpers from "../services/logOut.service";

const baseDomain = process.env.VUE_APP_API_URL;
// const baseDomain = 'http://192.168.110.114:8082'
const baseURL = `${baseDomain}/api`;

import createAuthRefreshInterceptor from "axios-auth-refresh";

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers["Authorization"] = `Bearer ` + localStorage.tokens
    request.headers.common["Access-Control-Allow-Origin"] = "*";
    request.headers['X-TenantId'] = window.tenantActive
    return request;
});

const refreshAuthLogic = failedRequest =>
window.keycloak.updateToken(1).success(() => {
    localStorage.setItem("tokens", window.keycloak.token);;
})

createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
