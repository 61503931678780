import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const patientUserpatient = "/patient-user-patients";
const userPatient = "/user-patients";
const defaultConfigs = { params: { "size": 1000 } };
import createRequestOption from './RequestParamUtil';
export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(patientUserpatient, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getUserPatient(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(userPatient, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    postPatientUserPatient(payload) {
        return Repository.post(`${patientUserpatient}`, payload);
    },
    // getPatient(patientId) {
    //     return Repository.get(`${patient}/${patientId}`);
    // },
    // updatePatient(payload) {
    //     return Repository.put(`${patient}`, payload);
    // },
    // patchPatient(payload) {
    //     return Repository.patch(`${patient}`, payload);
    // },
    // deletePatient(patientId) {
    //     return Repository.delete(`${patient}/${patientId}`);
    // },
    // search(payload) {
    //     const config = createRequestOption(payload);
    //     return Repository.get(`/_search/patients`, config);
    // },
    // fetch(req) {
    //     const defaultConfigs = createRequestOption(req);
    //     return Repository.get(`${patient}`, defaultConfigs);
    // },
}
