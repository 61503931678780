import Repository from './RepositoryChat';
import createRequestOption from './RequestParamUtil';
import paginateRepository from './AllPaginateChatRepository';
export default {
    get(req, resolve, reject){
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('chat-users', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    update(payload){
        return Repository.put('chat-users', payload);
    },
    create(payload){
        return Repository.post('chat-users', payload);
    },
    delete(id){
        return Repository.delete(`chat-users/${id}`);
    }
}
