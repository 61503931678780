export default class BlockUI {
    blockModal() {
        KTApp.block(".modal-content", {
            overlayColor: "#000000",
            state: "primary",
            message: "Dalam Proses ..."
        });
        return this.unblockModal;
    }

    unblockModal() {
        KTApp.unblock(".modal-content");
    }

    blockPage() {
        KTApp.blockPage({
            overlayColor: "#000000",
            type: "v2",
            state: "primary",
            message: "Dalam proses..."
        });
        return this.unblockPage;
    }

    unblockPage() {
        KTApp.unblockPage();
    }
}
