import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const doctors = "/dokters";
const linkSearch = "/_search/dokters-complete";
const tarifs = "/tarifs";
const doctorsCurrent = "/dokters/current";
const change = "/change-userid"
const doktersDropdown = "/dokters-dropdown"
const doktersReservasi = "/dokters-reservasi"
const dokterPostLogin = '/registrasi-dokter'
const defaultConfigs = { params: { "size": 1000000 } };
import createRequestOption from './RequestParamUtil';
import Holiday from "../services/doctor-holiday-service.js";

import Axios from "axios"// axios

export default {
    get(req, resolve, reject ,  dokterParam) {
        let cancelToken = null
        let abortCont = new AbortController()
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        cancelToken = source
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(doctors, req, [], 0, resolve, reject , source, null ,dokterParam);
        }).then((response) => {
                return Holiday.doctorHolidayMap(response,req["tanggalMulai.equals"]);
        }).then((data) => {
            resolve({ data: data });
        }).catch(error => {
            reject(error);
        });
        abortCont.signal.addEventListener( 'abort', () => { 
            if (cancelToken) {
                cancelToken.cancel()
                cancelToken = null
            }
            reject(); 
        });
        
        return abortCont
    },
    getWithConfig(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginateWithConfigOld(doctors, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getHolidayInHolday(req, resolve, reject, data) {
        new Promise((resolved, reject) => {
            const reduceItem = data.reduce((item, prev) => {
                const dokter = {
                    description: prev.jadwal.dokter.description,
                    dokterSpesialisasis: prev.jadwal.dokter.dokterSpesialisasis,
                    id: prev.jadwal.dokter.id,
                    isPublished: prev.jadwal.dokter.isPublished,
                    jadwals: [
                        {
                            hari: prev.jadwal.hari,
                            jam: [
                                {...prev.jadwal}
                            ]
                        }
                    ],
                    nama: prev.jadwal.dokter.nama,
                    tarif: prev.jadwal.dokter.tarif,
                    tanggalLahir: prev.jadwal.dokter.tanggalLahir,
                    textToSpeech: prev.jadwal.dokter.textToSpeech,
                    tujuanRujukan: prev.jadwal.dokter.tujuanRujukan
                }
                const index = item.findIndex(dokterItem => dokterItem.id === dokter.id)
                if(index >= 0) {
                    item[index].jadwals[0].jam.push({...dokter.jadwals[0].jam[0]})
                } else {
                    item.push(dokter)
                }
                return item
            }, [])
            resolved(reduceItem)
        }).then((response) => {
                return Holiday.doctorHolidayMap(response,req["tanggalMulai.equals"]);
        }).then((data) => {
            resolve({ data: data });
        }).catch(error => {
            reject(error);
        });
    },
    fetchAll(req, resolve, reject , commit ) {
        let cancelToken = null
        let abortCont = new AbortController()
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        cancelToken = source
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(doktersDropdown, req, [], 0, resolve, reject , source ,commit);
        }).then((response) => {
            return Holiday.doctorHolidayMap(response,req["tanggalMulai.equals"]);
        })
        .then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
        abortCont.signal.addEventListener( 'abort', () => { 
            if (cancelToken) {
                cancelToken.cancel()
                cancelToken = null
            }
            reject(); 
        });
        return abortCont
    },
    getDokterModalReservasi (req, resolve, reject, commit) {
        let cancelToken = null
        let abortCont = new AbortController()
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        cancelToken = source
        const baseConfig = {
            method:'get',
            baseURL: `${process.env.VUE_APP_API_URL}/dokter-service/api/v1`,
            // url: `/dokters/dropdown-by-sp-sb`,
        }
        const getAllDokter = '/dokters/dropdown-by-sp-sb'
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginateWithConfig(getAllDokter, req, [], 1, resolve, reject ,source, baseConfig );
        })
        .then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
        abortCont.signal.addEventListener( 'abort', () => { 
            if (cancelToken) {
                cancelToken.cancel()
                cancelToken = null
            }
            reject(); 
        });
        return abortCont
    },
    getDokterDropdown: async function*(req) {
        const defaultConfigs = createRequestOption(req);
        let abortCont = new AbortController()
        yield abortCont
        abortCont.signal.addEventListener( 'abort', () => { 
            if (cancelToken) {
                cancelToken.cancel('Another request was made')
            }
        });
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        let cancelToken = source
        
        if (source) {
            defaultConfigs.cancelToken = source.token
        }

        const config = {
            method:'get',
            baseURL: `${process.env.VUE_APP_API_URL}/dokter-service/api/v1`,
            url: `/dokters/dropdown-by-sp-sb`,
            params: defaultConfigs.params,
            cancelToken: source.token,
        }
        const response = await Repository(config)
        

        // const response = await Repository(config)
        // const response = await Temprepository.get(`/dokters/dropdown-by-sp-sb`, defaultConfigs)

        yield response.data
    },
    fetchDokterReservasi(req, resolve, reject , dokterParam) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(doktersReservasi, req, [], 0, resolve, reject, null , null , dokterParam);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    fetch(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${doctors}`, defaultConfigs);
    },
    search(req) {
        // const defaultConfigs = createRequestOption(req);
        return Repository.get(`${linkSearch}?query=${req}`, defaultConfigs);
    },
    getDoctor(doctorId) {
        return Repository.get(`${doctors}/${doctorId}`);
    },
    // getDoctorByPoli(tujuanRujukanId) {
    //     return Repository.get(`${doctors}`, { params: { "tujuanRujukanId": tujuanRujukanId } });
    // },
    createDoctor(payload) {
        return Repository.post(`${doctors}`, payload);
    },
    updateDoctor(payload) {
        return Repository.put(`${doctors}`, payload);
    },
    deleteDoctor(doctorId) {
        return Repository.delete(`${doctors}/${doctorId}`);
    },
    getAllTarif(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(tarifs, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getCurrentDoctors(req, resolve, reject , dokterParam) {
        // return Repository.get(`${doctors}/${current}`);
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(doctorsCurrent, req, [], 0, resolve, reject , null , null , dokterParam);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        })
    },
    changeUserId(payload) {
        return Repository.put(`${doctors}${change}`, payload);
    },
    async getSpecialties(req){
        // Temprepository
        const defaultConfigs = createRequestOption(req);
        const config = {
            method:'get',
            baseURL: `${process.env.VUE_APP_API_URL}/dokter-service/api/v1`,
            url: '/spesialisasi-dropdown',
            params: defaultConfigs.params,
        }
        const response = await Repository(config)
        // const response = await Temprepository.get('/spesialisasi-dropdown', defaultConfigs)
        if (response.data) {
            return response.data
        }
        return []
    },
    postDokterLogin (query) {
        return Repository.post(`${dokterPostLogin}`, {
            ...query
        })
    }
}