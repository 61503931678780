import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const backOfficeEnv = "/back-office-environment";

const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(backOfficeEnv, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getBackOfficeEnv() {
        return Repository.get(backOfficeEnv);
    }
}
