import { RepositoryFactory } from "../repositories/RepositoryFactory";
const UnitRepository = RepositoryFactory.get("unit");
const QueueResourceRepository = RepositoryFactory.get('queueResource')
import CallerType from "./../constants/caller-type-enum";

export default {
    namespaced: true,
    state: {
        unitOptions: [],
        loadingUnit: false,
        unitCallerName: 'COUNTER',
        unit: null,
        checkedinQueueResource: [],
        callingQueueResource: [],
        showModalPindah: false,
        showHistory: false,
        historyUnitQueue: [],
        doneQueue: [],
        skippedQueue: [],
        selectedQueue: null,
        showModalHelp: false,
        showModalNote: false
    },
    mutations: {
        SET_UNIT_CALLER_NAME(state, res){
            state.unitCallerName = res;
            sessionStorage.setItem('unitCallerName', res);
        },
        SET_SELECTED_UNIT(state, res){
            state.unit = res;
            if(res) {
                sessionStorage.setItem('selectedUnit', res);
            }
        },
        SET_LOADING_UNIT(state, res){
            state.loadingUnit = res;
        },
        SET_UNIT_OPTIONS(state, res){
            state.unitOptions = res;
        },
        SET_CHECKEDIN_QUEUE_RESOURCE(state, res){
            state.checkedinQueueResource = res
        },
        SET_CALLING_QUEUE_RESOURCE(state, res){
            state.callingQueueResource = res
        },
        SET_SHOW_MODAL_PINDAH(state, res){
            state.showModalPindah = res;
        },
        SET_SHOW_HISTORY(state, res){
            state.showHistory = res
        },
        SET_HISTORY_UNIT_QUEUE(state, res){
            state.historyUnitQueue = res
        },
        SET_CURRENT_SERVING(state, res){
            state.currentServing = res
        },
        SET_SELECTED_QUEUE(state, res){
            state.selectedQueue = res
        },
        SET_SHOW_MODAL_HELP(state, res){
            state.showModalHelp = res
        },
        SET_SHOW_MODAL_NOTE(state, res){
            state.showModalNote = res
        }
    },
    actions: {
        async GET_ALL_UNITS_OPTION({commit},res){
            try{
                commit('SET_LOADING_UNIT', true);
                const response = await new Promise((resolve, reject) => {
                    UnitRepository.get({}, resolve, reject );
                })
                // list option di select option unit caller 
                const unit = response.data.filter(x => x.tujuanRujukan == null)
                commit('SET_UNIT_OPTIONS', unit);
                commit('SET_LOADING_UNIT', false);

            }catch(error){
                 toastr.error('Failed fetch unit option');
            }
        },
        UPDATE_QUEUE_STATUS({ commit, state, dispatch}, res){
            let payload;
            switch (res.type) {
                case 1:
                    payload = {
                        note: res.data.note,
                        queueId: res.data.queueId,
                        uniqueId: res.data.uniqueId
                    }
                    dispatch('POST_CALL_QUEUE', payload)
                    break;
                case 2:
                    dispatch('POST_SERVING_QUEUE', res.data)
                    break;
                case 3:
                    payload = {
                        note: res.data.note,
                        queueId: res.data.queueId,
                        uniqueId: res.data.uniqueId
                    }
                    dispatch('POST_DONE_QUEUE', payload)
                    break;
                case 4:
                    payload = {
                        note: res.data.note,
                        prevQueueId: res.data.queueId,
                        queueUnitId: res.data.unitId,
                        uniqueId: res.data.uniqueId
                    }
                    dispatch('POST_SKIP_QUEUE', payload)
                    break;
            }
        },
        async POST_CALL_QUEUE({commit, state, dispatch}, res){
            const { data } = await QueueResourceRepository.postCallResource(res)
            dispatch('POST_RECALL_QUEUE', {queueId:  res.queueId});
            dispatch('GET_DATA_FROM_API', state.unit);
        },
        async POST_SERVING_QUEUE({commit, state, dispatch}, res){
            const { data } = await QueueResourceRepository.postServingResource(res)
            dispatch('GET_DATA_FROM_API', state.unit);
        },
        async POST_DONE_QUEUE({commit, state, dispatch}, res){
            const response = await QueueResourceRepository.postDoneResource(res)
            dispatch('GET_DATA_FROM_API', state.unit);
        },
        async POST_SKIP_QUEUE({commit, state, dispatch}, res){
            const response = await QueueResourceRepository.postSkipResource(res)
            dispatch('GET_DATA_FROM_API', state.unit);
        },
        async GET_DATA_FROM_API({commit, state, dispatch}, res){
            try{
                const tanggalHariIni = new Date().toISOString().split('T')
                const checkedIn = new Promise((resolve, reject) => {
                    QueueResourceRepository.get({
                        "date.equals": `${tanggalHariIni[0]}`,
                        "queueStatus.in": "CHECKEDIN",
                        "queueUnitId.in": `U${res}`,
                        "page" : 0
                    }, resolve, reject);
                });
                const calling = new Promise((resolve, reject) => {
                    QueueResourceRepository.get({
                        "date.equals": `${tanggalHariIni[0]}`,
                        "queueStatus.in": ["CALLING", "SERVING"],
                        "queueUnitId.in": `U${res}`,
                        "page" : 0
                    }, resolve, reject);
                });
                const [responseCheckedIn, responseCalling] = await Promise.all([checkedIn, calling]);

                // sorting berdasarkan queueNo
                responseCheckedIn.data.sort((a,b) => a.queueNo - b.queueNo)
                responseCalling.data.sort((a,b) => a.queueNo - b.queueNo)

                commit('SET_CHECKEDIN_QUEUE_RESOURCE', responseCheckedIn.data);
                commit('SET_CALLING_QUEUE_RESOURCE', responseCalling.data);
            }catch(err){
                toastr.error('error get data');
            }
        },
        async GET_HISTORY_QUEUE({commit, state, dispatch}, res){
            try{
                const tanggalHariIni = new Date().toISOString().split('T')
                const { data } = await new Promise((resolve, reject) => {
                        QueueResourceRepository.get({
                            "date.equals": `${tanggalHariIni[0]}`,
                            "queueStatus.in": ["DONE", "SKIPPED"],
                            "queueUnitId.in": res,
                            "page" : 0
                        }, resolve, reject);
                    });
                commit('SET_HISTORY_UNIT_QUEUE', data)
            }catch(err){
                toastr.error('error get data');
            }
        },
        async POST_RECALL_QUEUE({commit, state, dispatch}, res){
            const response = await QueueResourceRepository.postRecallQueue(res)
        },
        async POST_MANUAL_QUEUE({commit, state, dispatch}, res) {
            const response = await QueueResourceRepository.postManualCallResource(res)
        },
    }
}
