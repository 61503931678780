import { RepositoryFactory } from "../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
const ReservationRepository = RepositoryFactory.get("reservation");
import CheckPermission from "./../services/checkPermission.service";
const checkPermission = new CheckPermission();
import createRequestOption from "../repositories/RequestParamUtil"

import CallerType from "./../constants/caller-type-enum";

function defaultSpesialisasi(array){
    let defaultSpesialisasi = null
    if (Array.isArray(array)) {
        array.forEach(item => {
            if (item.default) {
                if (item.spesialisasi) {
                    defaultSpesialisasi = spesialisasiMapper(item.spesialisasi) 
                }
            }
        })
    return defaultSpesialisasi
    }
}
function   spesialisasiMapper(spesialisasi){
    if(spesialisasi){
        return {
            id: spesialisasi.id,
            nama: spesialisasi.namaIndo
        }
    }else {
        return {
            id: '',
            nama: ''
        }
    }
}

export default {
    namespaced: true,
    state: {
        datesInMonth: [],
        listDokter: [],
        listReservasi: [],
        events: [],
        loadingApi: false,
        showModalHelp: false,
    },
    mutations: {
        SET_DATES_IN_MONTH(state, res) {
            state.datesInMonth = res;
        },
        SET_LIST_DOKTER(state, res) {
            state.listDokter = res;
        },
        SET_LIST_RESERVASI(state, res) {
            state.listReservasi = res;
        },
        SET_EVENTS(state, res) {
            state.events = res;
        },
        SET_LOADING_API(state, res) {
            state.loadingApi = res;
        },
        SET_SHOW_MODAL_HELP(state, res) {
            state.showModalHelp = res
        },
    },
    actions: {
        async GET_EVENT({ commit, state, dispatch, rootState } ,  {resolve, reject}) {
            try {
                commit('SET_LOADING_API', true);
                commit('SET_EVENTS', []);
                // Buat list tanggal dalam satu bulan
                dispatch('GET_DATES_IN_MONTH');

                //init state dari storenya DoctorAppointmentStore
                const momentDay = moment(rootState.DoctorAppointmentStore.date);
                const dokters = rootState.DoctorAppointmentStore.dokterArray;
                const poli = rootState.DoctorAppointmentStore.poli
                const startOf = momentDay.clone().startOf('month').format('YYYY-MM-DD')
                const endOf = momentDay.clone().endOf('month').format('YYYY-MM-DD')
                // Buat parameter untuk hit api dokter
                const paramDokter = {
                    'tanggalMulai.equals': startOf,
                    'tanggalSelesai.equals': endOf,
                };
                // Buat paramater untuk hit api reservasi
                const paramReservasi = {
                    // "status.in": "NEW, CONFIRM, ARRIVED",
                    // "status.in": "NEW, CONFIRM, ARRIVED",
                    "tanggalMulai.greaterOrEqualThan": startOf,
                    "tanggalSelesai.lessOrEqualThan": endOf
                };
                if (poli) {
                    paramDokter.tujuanRujukanId = poli.id;
                    paramReservasi['tujuanRujukanId.in'] = poli.id;
                }
                // Kalau sudah ada filter id.in nya
                // let paramDokterReq =  createRequestOption(paramDokter)
                // let paramReservasiReq =  createRequestOption(paramReservasi)
                const dokterIds = []
                if (dokters.length) {
                    dokters.forEach(dokter => {
                        dokterIds.push(dokter.id)
                    });
                    // paramDokter['id.in'] = dokter.id;
                    // paramReservasi['dokterId.in'] = dokter.id;
                } else {
                    commit('SET_LOADING_API', false);
                    return
                }
                // console.log(paramDokterReq.params.toString() ,'paramDokterReq.params', createRequestOption(paramDokterReq.params));
                const doctorLogin = checkPermission.isCanAccess('DokterResource.GET.Dokter.Current')
                let apiDokter
                let apiReservasi
                // if (doctorLogin) {
                //     apiDokter = new Promise((resolve, reject) => {
                //         DoctorRepository.getCurrentDoctors(paramDokter, resolve, reject , { type: "id.in" , dokterIds});
                //     });
                //     apiReservasi = new Promise((resolve, reject) => {
                //         ReservationRepository.getReservasisDoctor(paramReservasi, resolve, reject , {type: "dokterId.in",dokterIds});
                //     });
                // } else {
                    // apiDokter = new Promise((resolve, reject) => {
                    //     DoctorRepository.fetchDokterReservasi(paramDokter, resolve, reject , { type: "id.in" , dokterIds});
                    // });
                    apiReservasi = new Promise((resolve, reject) => {
                        ReservationRepository.getReservasisBookingNew(paramReservasi, resolve, reject , {type: "id.in",dokterIds});
                        // ReservationRepository.getReservasisBooking(paramReservasi, resolve, reject , {type: "dokterId.in",dokterIds});
                    });
                    // apiReservasi = ReservationRepository.getReservasisBookingNew(paramReservasi, resolve, reject , {type: "id.in",dokterIds});
                // }
                // Hit API
                // Time to hit api, jangan lupa klau api reservasi sudah benar tambah apiReservasi dan responseReservasi
                const [responseReservasi] = await Promise.all([apiReservasi]);

                // sementara buat mockup klau dokter terpilih maka list dokter nya
                // let listdokter = responseDokter.data.map(item => {
                //     console.log(item)
                //     return {
                //         ...item,
                //         tujuanRujukan: defaultSpesialisasi(item.dokterSpesialisasis)
                //     }
                // })
                // console.log(listdokter , "listdokter");

                // let mappedData =  responseDokter.data.map(item => {
                //     return {
                //         ...item,
                //         tujuanRujukan: defaultSpesialisasi(item.dokterSpesialisasis)
                //     }
                // })

                // commit('SET_LIST_DOKTER', listdokter);
                // anggap responseReservasi seperti berikut baik defack atau normal dijoin dl yak
                commit('SET_LIST_RESERVASI', responseReservasi.data);
                dispatch('CONSTRUCT_EVENT');
                commit('SET_LOADING_API', false);
                resolve()

            } catch (err) {
                toastr.error('Error get data')
                console.log(err , "error get event");
                reject(err)
            }
        },
        async CONSTRUCT_EVENT({ commit, state, dispatch, rootState }) {
            // function check data is expired
            // const checkDateExpired = ({ start, end, now }) => {
            //     let isExpired = true
            //     if (start || end) {
            //         var startDate = start ? moment(start) : moment('1990-01-01');
            //         var endDate = end ? moment(end) : moment('9999-12-12');
            //         var target = moment(now)
            //         isExpired = target.isBetween(startDate, endDate, 'days', true)
            //     }
            //     return isExpired

            // }

            // let listEvent = [];
            // Loop setiap tanggal pada bulan terpilih

            // state.datesInMonth.forEach(x => {
            //     // Lakukan loop dokter pada tiap tanggal
            //     state.listDokter.forEach(y => {
            //         // Cari apakah dokter mempunyai jadwal pada tanggal / hari tersebut
            //         const jadwal = y.jadwals.find(z => (z.hari == x.day));
            //         if (jadwal) { // kalau ada jadwalnya maka
            //             const jadwals = [];
            //             jadwal.jam.forEach(j => {
            //                 // cari reservasi ditanggal dan di jam tersebut
            //                 const reservasiInThisDay = state.listReservasi.filter(k => {
            //                     if (k.jadwal && k.jadwal.id == j.id && x.date == k.tanggalKunjungan) return k;
            //                 })
            //                 let dataTIme = checkDateExpired({ start: j.tanggalMulai, end: j.tanggalSelesai, now: x.date })
            //                 if (dataTIme) {
            //                     jadwals.push({
            //                         jadwal: j,
            //                         reservasis: reservasiInThisDay
            //                     })
            //                 }
            //             })
            //             // buat object untuk data yg dibutuhkan
            //             if (jadwals.length > 0) {
            //                 const objectdata = {
            //                     dokter: y,
            //                     jadwals: jadwals
            //                 }
            //                 //    cek tanggal mulai dan tanggal selesai 

            //                 const event = {
            //                     start: x.date,
            //                     title: JSON.stringify(objectdata),
            //                 }
            //                 listEvent.push(event);
            //             }
            //         }
            //     })
            // });
            // console.log(listEvent , "listEvent");
            // commit('SET_EVENTS', listEvent);
            const monthReservation = state.listReservasi.map((reserv) => {
                return {
                    title: JSON.stringify(reserv),
                    start: reserv.date,
                }
            })
            commit('SET_EVENTS', monthReservation);
        },
        async GET_DATES_IN_MONTH({ commit, state, dispatch, rootState }) {
            var momentDay = moment(rootState.DoctorAppointmentStore.date);
            var startMoment = moment(momentDay.startOf('month').format('YYYY-MM-DD'));
            var momentEnd = moment(momentDay.endOf('month').format('YYYY-MM-DD'));
            var listDayOfMonth = [];
            while (startMoment.isSameOrBefore(momentEnd)) {
                var value = {
                    date: startMoment.format('YYYY-MM-DD'),
                    day: startMoment.locale('en').format('dddd').toUpperCase()
                }
                listDayOfMonth.push(value);
                startMoment.add(1, 'days');
            }
            commit('SET_DATES_IN_MONTH', listDayOfMonth);
        },


    }
}
