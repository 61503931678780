import Repository from './Repository'
import createRequestOption from './RequestParamUtil';
const slotJadwals = '/slot-jadwals'
const validatAndSave = '/validate-and-save-defect'
const correction = '/correction'

export default{
  createSlotJadwals(payload) {
    return Repository.post(`${slotJadwals}${validatAndSave}`, payload);
  },
  getSlotJadwal(slotId) {
    return Repository.get(`${slotJadwals}?${slotId}`);
  },
  updateSlotJadwal(payload) {
    return Repository.put(`${slotJadwals}`, payload);
  },
  deleteSlotJadwal(slotId) {
    return Repository.delete(`${slotJadwals}/${slotId}`);
  },
  updateInternalEvent(payload){
    return Repository.post(`${slotJadwals}${correction}`, payload)
  },
  deleteInternalEvent(slotId){
    return Repository.delete(`${slotJadwals}${validatAndSave}/${slotId}`);
  }
}