export default {
    SET_LOADING(state, data) {
        state.isLoading = data.loading;
        state.loadingMessage = data.message;
    },
    // SET_SWAL(state, data) {
    //     _.forEach(data, (i, k) => {
    //         if (!_.isUndefined(state.swalMessage[k])) {
    //             state.swalMessage[k] = i;
    //         }
    //     });
    // },
    RESET_STATE(state) {
        (state.repository = null),
        (state.isLoaded = false),
        (state.isShowModal = false),
        (state.modalName = null),
        (state.table = {
            tableName: null,
            tableDataResult: [],
            tableColumnDefs: [],
            tableColumns: [],
            tableFilter: [],
            ordering: null,
            order: null,
            dateFormat: null,
        }),
        (state.dataEdit = null);
        (state.showModalMode = null);
    },
    SET_REPOSITORY(state, data) {
        state.repository = data;
    },
    SET_MODAL(state, data) {
        state.modalName = data;
    },
    SET_EDIT_DATA(state, data) {
        state.showModalMode = null;
        state.dataEdit = data;
    },
    SET_VIEW_DATA(state, data) {
        state.dataEdit = data;
        state.showModalMode = 'V';
        // state.isShowModal = true;
    },
    // SETUP_TABLE(state, data) {},
    SHOW_MODAL(state, data) {
        state.isShowModal = true;
    },
    HIDE_MODAL(state, data) {
        let modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if(!_.isUndefined(modalBackdrop)){
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
        state.dataEdit = null;
        state.showModalMode = null;
        state.isShowModal = false;
    },
    CLEAR_DATA(state) {
        state.isShowModal = false;
    },
    REFRESH_TABLE(state, data) {
        state.shouldRefreshTable = (data == null) ? true : data;
        state.isLoading = state.shouldRefreshTable;
        state.loadingMessage = 'Memuat Data ...';
    }
};
