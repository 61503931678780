import Repository from './Repository';
import createRequestOption from './RequestParamUtil';
const user = "/user-notifications";

export default {

    create(payload) {
        return Repository.post(`${user}`, payload );
    },
    update(payload) {
        return Repository.put(`${user}`, payload);
    },
    delete(login) {
        return Repository.delete(`${user}/${login}`);
    },
}
