import Repository from './RepositoryCommservice';
import paginateRepository from './AllPaginateComserviceRepository';
const SmsTemplate = "/sms-templates";
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(SmsTemplate, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    find(appId) {
        return Repository.get(`${SmsTemplate}/${appId}`);
    },
    create(payload) {
        return Repository.post(`${SmsTemplate}`, payload);
    },
    update(payload) {
        return Repository.put(`${SmsTemplate}`, payload);
    },
    delete(appId) {
        return Repository.delete(`${SmsTemplate}/${appId}`);
    },
}
