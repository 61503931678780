import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default class i18n {
    internationalization() {
        const messages = {
            en: {
                EnumStage: {
                    PASIEN_BELUM_MASUK: "Pasien belum masuk",
                    PASIEN_DATANG: "Pasien datang",
                    TIDAK_JADI_DATANG: "Tidak jadi datang",
                    DIRUJUK_KETEMPAT_LAIN: "Dirujuk ke tempat lain",
                    DALAM_PERAWATAN: "Dalam perawatan",
                    PASIEN_PULANG: "Pasien pulang",
                    DALAM_PROSES_PEMBAYARAN: "Dalam proses pembayaran",
                    SUDAH_TERBAYARKAN: "Sudah terbayarkan",
                    CLOSED: "Closed",
                    PELAYANANMEDIS: "Pelayanan Medis",
                    PENUNJANGMEDIS: "Penunjang Medis",
                    ALL: "Semuanya",
                    DITERIMA: "Diterima",
                    AUTO: "Auto",
                    MANUAL: "Manual",
                    SUDAH_DIBAYAR: "Sudah dibayar",
                    BELUM_DIBAYAR: "Belum dibayar",
                    HP_PRIMARY: "Handphone Utama",
                    RUMAH: "Rumah",
                    HP: "Handphone",
                    KERJA: "kantor",
                    LAINNYA: "Lainnya",
                    RUMAH: "Rumah",
                    KERJA: "Kerja",
                    PRAKTEK_PRIBADI: "Praktek Pribadi",
                    KLINIK: "Klinik",
                    RUMAH_BERSALIN: "Rumah Bersalin",
                    RUMAH_SAKIT: "Rumah Sakit"
                },
                button: {
                    search: "Search",
                    cancel: "Cancel",
                    add: "Add",
                    reset: "Reset",
                    addFormula: "Add Formula",
                    autoRefresh: "Auto Refresh",
                    detail: "Detail",
                    delete: "Delete",
                    previous: "Previous",
                    nextStep: "Next Step",
                    submit: "Submit",
                    close: "Close",
                    save: "Save",
                    addPointType: "Add Point Type",
                    addPhone: "Add Phone Number",
                    addAddress: "Add Address",
                    addPerujuk: "Add Perujuk",
                    addKategori: "Add Category",
                    hideClosed: "Hide Closed",
                    showClosed: "Show Closed",
                    edit: "Edit",
                    crop: "Crop",
                    print: "Print",
                    addKelas: "Add Kelas",
                    addTindakan: "Add Tindakan",
                    updateLokasi: "Update Lokasi"
                },
                module: {
                    transactionPointType: "Transaction Point Type",
                    formulaLoyalty: "Formula Loyalitas",
                    customPoint: "Custom Point",
                    claim_point: "Claim Point",
                    coupon_usages: "Coupon Usages",
                    history_point: "Point Transaction History",
                    memberLoyalty: "Data Member",
                    tierLoyalty: "Tier Loyalty",
                    coupons: "Coupons",
                    dashboard: "Dashboard",
                    reservation: "Reservation",
                    medicalRecord: "Medical Record",
                    pharmacy: "Pharmacy",
                    queue: "Queue",
                    promoCat: "Promotion Categories",
                    doctor: "Doctor",
                    picOfPharmacy: "Pic of Pharmacy",
                    patient: "Patient",
                    unit: "Unit",
                    queueUnit: "Queue Unit",
                    tujuanRujukan: "Poly",
                    jenisPenjamin: "Guarantor",
                    userGroup: "User Group",
                    tugas: "Task",
                    tindakan: "Treatment",
                    kelas: "Class",
                    kategori: "Category",
                    userManagement: "User Management",
                    skemaRujukan: "Skema Rujukan",
                    appConfig: "Application Configuration",
                    diskusi: "Diskusi (Chat)",
                    perujuk: "Perujuk",
                    pic: "PIC",
                    transaksiReferral: "Transaksi Referral",
                    elastic: "Elastic Configuration",
                    carousel: "Carousel",
                    rekamMedik: "Medical Record",
                    chatUser: "Chat User",
                    smsTemplate: "Sms Template",
                    smsHistory: "Sms History",
                    tarif: "Pricing"
                },

                formulaLoyalty: {
                    priority: "Priority",
                    formula_name: "Formula Name",
                    start_date: "Start Date",
                    end_date: "End Date",
                    transaction_amount_min: "Min Amount Transaction",
                    transaction_amount_max: "Max Amount Transaction",
                    point: "Poin",
                    point_percentage: "Point Percentage",
                    transaction_min: "Transaction Minimum",
                    transaction_max: "Transaction Maximum",
                    point_min: "Point Minimum",
                    point_max: "Point Maximum",
                    point_type: "Point Type",
                    tiers: "Tiers"
                },
                coupons: {
                    add_coupon_usage: "Add Coupon Usage",
                    transaction_date: "Transaction Date",
                    title: "Coupon",
                    verify_coupon_msg:
                        "Only Character a-z, A-Z, 0-9, and - are allowed",
                    add: "Add Coupon",
                    valid_until: "Valid Until",
                    usage_limit: "Usage Limit",
                    usage_limit_member: "Usage Limit Per Member",
                    tiered_boundaries: "Tiered Boundaries",
                    terms_and_conditions: "Terms and Conditions",
                    exchange_points: "Exchange Points",
                    coupon_code: "Coupon Code",
                    how_to_use: "How to use"
                },
                history_point: {
                    transaction_type: "Transaction Type"
                },
                loyaltyTier: {
                    title: "Tier Loyalty",
                    add: "Tambah TierLoyalty",
                    // add:'Tambah TierLoyalty',
                    minimum_loyalty: "Minimum Loyalty",
                    description: "Deskripsi",
                    color: "Warna",
                    card_content: "Kartu",
                    card_thumbnail: "Thumbnail",
                    point_duration: "Durasi Poin"
                },
                customPoint: {
                    add: "Add Custom Point",
                    basic_information: "Basic Information",
                    type: "Type",
                    theme: "Theme",
                    selected_members: "Selected Members"
                },

                claimPoint: {
                    attachments: "Attachments",
                    scan_code: "Scan Code",
                    add: "Add Claim",
                    title: "Point Claim",
                    transactionCode: "Transaction Code",
                    trPointTypeName: "Point Type",
                    transactionAmount: "Transaction Amount"
                },
                member: {
                    member_information: "Member Information",
                    base_information: "Base Information",
                    title: "Members",
                    add: "Add Member",
                    // add:'Tambah TierLoyalty',
                    name_on_card: "Name On Card",
                    member_no: "Member Num",
                    member_id: "Member ID",
                    member_number: "Member Number",
                    point: "Point",
                    loyalty: "Loyalty",
                    joined_date: "Joined Date",
                    patient_id: "Patient ID",
                    loyalty_tier_id: "Tier ID",
                    loyalty_tier: "Tier"
                },
                sentences: {
                    card_is_required: "Card is required",
                    fullname_is_required: "Full Name is required",
                    user_id_is_required: "User ID is required",
                    failed_to_get_member_data_please_contact_:
                        "Failed to get this member data. Please contact Administrator",
                    prepare_to_delete: "Click to delete on save",
                    undo_delete_prepare: "Cancel delete",
                    not_enugh_point_member: "Not Enugh Point Member",
                    coupon_expired: "Coupon has expired",
                    invalid_coupon_code: "Invalid Coupon Code",
                    invalid_attachments: "Invalid attachments",
                    invalid_end_date:
                        "End Date cannot be lower than Start Date",
                    drag_image_here_or_click_to_:
                        "Drag the image here or click to upload."
                },
                title: {
                    list: "List of",
                    ketidakhadiranDokter: "Ketidakhadiran Dokter",
                    jadwal: "Schedule",
                    lokasiRekamMedik: "Lokasi Rekam Medik",
                    riwayatLokasi: "Riwayat Lokasi"
                },
                datatables: {
                    showing: "Showing",
                    showingResult:
                        "Showing {start} - {end} from _TOTAL_ results",
                    showingResultFiltered:
                        "(filtered from {max} total entries)",
                    showingInfoEmpty: "No entries",
                    showingZeoRecords: "No matching records found"
                },
                reservation: {
                    patientInfo: "Patient Info",
                    updateStatus: "Update Status"
                },
                common: {
                    title: "Title",
                    transactionDate: "Transaction Date",
                    mariage_status: "Mariage Status",
                    birthdate: "Birth Date",
                    fullname: "Full Name",
                    address: "Address",
                    minimum_loyalty: "Minimum Loyalty",
                    color: "Color",
                    point_duration: "Point Duration",
                    point: "Point",
                    card: "Card",
                    card_thumbnail: "Thumbnail",
                    description: "Description",
                    promoCatName: "Promotion Category Name",
                    name: "Name",
                    dob: "Date of Birth",
                    gender: "Gender",
                    phoneNo: "Phone No.",
                    email: "Email",
                    guarantor: "Guarantor",
                    doctor: "Doctor",
                    poly: "Poly",
                    schedule: "Schedule",
                    queueNo: "Queue No",
                    message: "Message",
                    confirm: "Confirm",
                    cancel: "Cancel",
                    patientComing: "Patient Coming",
                    printTicket: "Print Ticket",
                    time: "Time",
                    medicalNo: "Medical Record No.",
                    parentTindakan: "Parent Tindakan",
                    latest: "Latest",
                    kode: "Code",
                    firstName: "First Name",
                    lastName: "Last Name",
                    username: "Username",
                    password: "Password",
                    rePassword: "Password Confirmation",
                    kategori: "Category",
                    alamat: "Address",
                    tanggalLahir: "Date of birth",
                    perujuk: "Perujuk",
                    diagnosa: "Diagnosa / Keluhan",
                    dataPenunjang: "Data Penunjang",
                    tindakan: "Tindakan",
                    kelas: "Kelas",
                    totalBiaya: "Total Biaya",
                    fee: "Fee",
                    jejaks: "Jejaks",
                    tanggalDaftar: "Tanggal Daftar",
                    catatan: "Notes",
                    grupRujukan: "Grup Rujukan",
                    pilihan: "Pilihan",
                    displayName1: "Display Name 1",
                    displayName2: "Display Name 2",
                    tts: "Text to Speech",
                    waktuTunggu: "Waktu Tunggu",
                    userId: "User Id",
                    tanggalKetidakhadiran: "Tanggal Ketidakhadiran",
                    hari: "Days",
                    jamMulai: "Start Periode",
                    jamSelesai: "End Periode",
                    tanggalMulai: "Start Date",
                    tanggalSelesai: "End Date",
                    tugas: "Task",
                    periode: "Period",
                    tanggalRujukan: "Tanggal Rujukan",
                    status: "Status",
                    year: "Year",
                    month: "Month",
                    label: "Label",
                    judul: "Title",
                    subJudul: "Sub Title",
                    keterangan: "Description",
                    gambar: "Picture",
                    antrianManual: "Antrian Manual",
                    tanggal: "Tanggal",
                    queueType: "Queue Type",
                    perangkatAntrian: "Perangkat",
                    auto: "Auto",
                    manual: "Manual",
                    periodeAwal: "Periode Awal",
                    periodeAkhir: "Periode Akhir",
                    filterTanggal: "Filter Tanggal",
                    username: "Username",
                    rocketChatId: "Rocket Chat Id",
                    nameTemplate: "Template Name",
                    content: "Content",
                    type: "Type",
                    templateId: "Template Id",
                    transactionId: "Transaction Id",
                    sendStatus: "Send Status",
                    deliveryStatus: "Delivery Status",
                    recipient: "Recipient",
                    createdDate: "Created Date",
                    lastModifiedDate: "Last Modified Date",
                    amount: "Amount"
                },
                report: {
                    statusRujukan: "Status Rujukan",
                    statusFeeKeuangan: "Status Referral Fee Keuangan",
                    detailTransaksi: "Detail Transaksi",
                    pendapatanReferral: "Pendapatan Referral",
                    printTicket: "Print Ticket",
                    medicalNo: "Medical Record Number",
                    timeTrackingReservasi: "Time Tracking Reservation"
                },
                formInternal: {
                    title: "Internal Event",
                    date: "Date",
                    select: "Select Docter",
                    pilihJam: "Select Hour",
                    jamMulai: "Starting Hour",
                    jamSelesai: "Finish Hour",
                    remark: "Remark",
                    slotType: "Slot Type",
                    medicalNo: "Medical Record Number"
                }
            },
            id: {
                EnumStage: {
                    PASIEN_BELUM_MASUK: "Pasien belum masuk",
                    PASIEN_DATANG: "Pasien datang",
                    TIDAK_JADI_DATANG: "Tidak jadi datang",
                    DIRUJUK_KETEMPAT_LAIN: "Dirujuk ke tempat lain",
                    DALAM_PERAWATAN: "Dalam perawatan",
                    PASIEN_PULANG: "Pasien pulang",
                    DALAM_PROSES_PEMBAYARAN: "Dalam proses pembayaran",
                    SUDAH_TERBAYARKAN: "Sudah terbayarkan",
                    CLOSED: "Closed",
                    PELAYANANMEDIS: "Pelayanan Medis",
                    PENUNJANGMEDIS: "Penunjang Medis",
                    ALL: "Semuanya",
                    DITERIMA: "Diterima",
                    AUTO: "Auto",
                    MANUAL: "Manual",
                    SUDAH_DIBAYAR: "Sudah dibayar",
                    BELUM_DIBAYAR: "Belum dibayar",
                    HP_PRIMARY: "Handphone Utama",
                    RUMAH: "Rumah",
                    HP: "Handphone",
                    KERJA: "kantor",
                    LAINNYA: "Lainnya",
                    RUMAH: "Rumah",
                    KERJA: "Kerja",
                    PRAKTEK_PRIBADI: "Praktek Pribadi",
                    KLINIK: "Klinik",
                    RUMAH_BERSALIN: "Rumah Bersalin",
                    RUMAH_SAKIT: "Rumah Sakit"
                },
                button: {
                    search: "Cari",
                    cancel: "Batalkan",
                    addFormula: "Tambah Formula",
                    add: "Tambah",
                    reset: "Reset",
                    autoRefresh: "Auto Refresh",
                    detail: "Rincian",
                    delete: "Hapus",
                    previous: "Sebelumnya",
                    nextStep: "Selanjutnya",
                    submit: "Kirim",
                    close: "Tutup",
                    save: "Simpan",
                    addPointType: "Tambah Tipe Poin",
                    addPhone: "Tambah Telepon",
                    addAddress: "Tambah Alamat",
                    addPerujuk: "Tambah Perujuk",
                    addKategori: "Tambah Kategori",
                    hideClosed: "Sembunyikan Closed",
                    showClosed: "Tampilkan Closed",
                    edit: "Ubah",
                    print: "Print",
                    crop: "Potong",
                    addKelas: "Tambah Kelas",
                    addTindakan: "Tambah Tindakan",
                    updateLokasi: "Update Lokasi"
                },
                module: {
                    transactionPointType: "Master Tipe Poin Transaksi",
                    formulaLoyalty: "Formula Loyalitas",
                    customPoint: "Custom Poin",
                    claim_point: "Klaim Poin",
                    coupon_usages: "Penggunaan Kupon",
                    history_point: "Histori Poin",
                    memberLoyalty: "Member",
                    tierLoyalty: "Loyalty Tier",
                    coupons: "Data Kupon",
                    dashboard: "Dasbor",
                    reservation: "Reservasi",
                    medicalRecord: "Rekam Medik",
                    pharmacy: "Farmasi",
                    queue: "Antrian",
                    doctor: "Dokter",
                    picOfPharmacy: "PIC Farmasi",
                    patient: "Pasien",
                    promoCat: "Kategori Promosi",
                    unit: "Unit",
                    queueUnit: "Unit Antrian",
                    tujuanRujukan: "Poli",
                    jenisPenjamin: "Jenis Penjamin",
                    userGroup: "Grup User",
                    tugas: "Tugas",
                    tindakan: "Tindakan",
                    kelas: "Kelas",
                    kategori: "Kategori",
                    userManagement: "Manajemen User",
                    skemaRujukan: "Skema Rujukan",
                    appConfig: "Application Configuration",
                    diskusi: "Diskusi (Chat)",
                    perujuk: "Perujuk",
                    pic: "PIC",
                    transaksiReferral: "Transaksi Referral",
                    elastic: "Elastic Configuration",
                    carousel: "Carousel",
                    rekamMedik: "Rekam Medik",
                    chatUser: "Chat User",
                    smsTemplate: "Sms Template",
                    smsHistory: "Sms History",

                    member: "Data Member",
                    loyaltyTier: "Master Tier Loyalty",
                    promotionCategories: "Master Kategori Promosi",
                    tarif: "Tarif"
                },
                formulaLoyalty: {
                    priority: "Prioritas",
                    formula_name: "Nama Formula",
                    start_date: "Tanggal Mulai",
                    end_date: "Tanggal Selesai",
                    transaction_amount_min: "Minimum Jumlah Transaksi",
                    transaction_amount_max: "Maksimum Jumlah Transaksi",
                    point: "Jumlah Poin",
                    point_percentage: "Persentase Poin",
                    transaction_min: "Batas Minimum Transaksi",
                    transaction_max: "Batas Maksimum Transaksi",
                    point_min: "Batas Minimum Poin",
                    point_max: "Batas Maksimum Poin",
                    point_type: "Batasan Tipe Transaksi Poin",
                    tiers: "Batasan Tier"
                },
                coupons: {
                    add_coupon_usage: "Tambah Penggunaan Kupon",
                    title: "Kupon",
                    transaction_date: "Tanggal Transaksi",
                    verify_coupon_msg:
                        "Hanya boleh diisi dengan karakter a-z, A-Z, 0-9 dan tanda -",
                    add: "Tambah Kupon",
                    valid_until: "Berlaku Hingga",
                    usage_limit: "Batasan Pemakaian",
                    usage_limit_member: "Batasan Pemakaian Per User",
                    tiered_boundaries: "Batasan Tier",
                    terms_and_conditions: "Ketentuan",
                    exchange_points: "Poin Penukaran",
                    coupon_code: "Kode Kupon",
                    how_to_use: "Cara Pakai"
                },
                loyaltyTier: {
                    title: "Tier Loyalty",
                    add: "Tambah TierLoyalty",
                    // add:'Tambah TierLoyalty',
                    minimum_loyalty: "Minimum Loyalty",
                    description: "Deskripsi",
                    color: "Warna",
                    card_content: "Kartu",
                    card_thumbnail: "Thumbnail",
                    point_duration: "Durasi Poin"
                },

                history_point: {
                    transaction_type: "Jenis Transaksi"
                },
                customPoint: {
                    add: "Tambah Custom Point",
                    basic_information: "Informasi Dasar",
                    type: "Tipe",
                    theme: "Tema",
                    selected_members: "Member Dipilih"
                },
                claimPoint: {
                    attachments: "Lampiran",
                    scan_code: "Scan Kode",
                    add: "Tambah Klaim",
                    title: "Klaim Poin",
                    transactionCode: "Kode Transaksi",
                    trPointTypeName: "Jenis Poin Transaksi",
                    transactionAmount: "Jumlah Transaksi"
                },
                member: {
                    member_information: "Informasi Member",
                    base_information: "Informasi Dasar",
                    title: "Data Member",
                    add: "Tambah Member",
                    // add:'Tambah TierLoyalty',
                    name_on_card: "Nama Pada Kartu",
                    member_no: "No Member",
                    member_number: "Nomor Member",
                    member_id: "ID Member",
                    point: "Poin",
                    loyalty: "Loyalty",
                    joined_date: "Tanggal Bergabung",
                    patient_id: "Pasien ID",
                    loyalty_tier_id: "Tier ID",
                    loyalty_tier: "Tier"
                },
                sentences: {
                    card_is_required: "Kartu Wajib diisi",
                    fullname_is_required: "Nama Lengkap Wajib diisi",
                    user_id_is_required: "ID User Wajib diisi",
                    failed_to_get_member_data_please_contact_:
                        "Gagal mengambil data pada member ini, silakan hubungi Administrator",
                    prepare_to_delete:
                        "Klik untuk persiapan penghapusan saat simpan",
                    undo_delete_prepare: "Batalkan hapus",
                    not_enugh_point_member: "Point Member Tidak Cukup",
                    coupon_expired: "Kupon sudah kadaluarsa",
                    invalid_coupon_code: "Kode kupon tidak Valid",
                    invalid_attachments: "Lampiran tidak valid",
                    invalid_end_date:
                        "Tanggal Akhir tidak boleh lebih kecil dari Tanggal Awal",
                    drag_image_here_or_click_to_:
                        "Tarik gambar ke sini atau klik untuk upload."
                },
                title: {
                    list: "Daftar",
                    ketidakhadiranDokter: "Ketidakhadiran Dokter",
                    jadwal: "Jadwal",
                    lokasiRekamMedik: "Lokasi Rekam Medik",
                    riwayatLokasi: "Riwayat Lokasi"
                },
                datatables: {
                    showing: "Menampilkan",
                    showingResult:
                        "Menampilkan {start} - {end} dari _TOTAL_ hasil",
                    showingResultFiltered: "(difilter dari {max} total hasil)",
                    showingInfoEmpty: "Tidak ada hasil",
                    showingZeoRecords: "Tidak ada hasil yang ditemukan"
                },
                reservation: {
                    patientInfo: "Info Pasien",
                    updateStatus: "Ubah Status"
                },
                common: {
                    title: "Judul",
                    transactionDate: "Tanggal Transaksi",
                    mariage_status: "Status Perkawinan",
                    birthdate: "Tanggal Lahir",
                    fullname: "Nama Lengkap",
                    address: "Alamat",
                    minimum_loyalty: "Minimum Loyalty",
                    color: "Warna",
                    point_duration: "Durasi Poin",
                    point: "Poin",
                    card: "Kartu",
                    card_thumbnail: "Thumbnail",
                    description: "Deskripsi",

                    promoCatName: "Kategori Promo",
                    name: "Nama",
                    dob: "Tanggal Lahir",
                    gender: "Jenis Kelamin",
                    phoneNo: "No. Telepon",
                    email: "Email",
                    guarantor: "Penanggung Jawab Pembayaran",
                    doctor: "Dokter",
                    poly: "Poli",
                    schedule: "Jadwal",

                    namaDepan: "Nama Depan",
                    namaBelakang: "Nama Belakang",
                    nik: "NIK",
                    telp: "No. Telepon",
                    tglLahir: "Tanggal Lahir",
                    alamat: "Alamat",
                    kelurahan: "Kelurahan",
                    kecamatan: "Kecamatan",
                    kabupaten: "Kabupaten",
                    kewarganegaraan: "Kewarganegaraan",
                    jenisKelamin: "Jenis Kelamin ",
                    rt: "RT",
                    rw: "RW",

                    queueNo: "Nomor Antrian",
                    message: "Pesan",
                    confirm: "Konfirmasi",
                    cancel: "Batalkan",
                    patientComing: "Pasien Datang",
                    printTicket: "Cetak Tiket",
                    time: "Waktu",
                    medicalNo: "No. Rekam Medik",
                    parentTindakan: "Parent Tindakan",
                    latest: "Latest",
                    kode: "Kode",
                    firstName: "Nama Depan",
                    lastName: "Nama Belakang",
                    username: "Username",
                    password: "Password",
                    rePassword: "Konfirmasi Password",
                    kategori: "Kategori",
                    alamat: "Alamat",
                    tanggalLahir: "Tanggal Lahir",
                    perujuk: "Perujuk",
                    diagnosa: "Diagnosa / Keluhan",
                    dataPenunjang: "Data Penunjang",
                    tindakan: "Tindakan",
                    kelas: "Kelas",
                    totalBiaya: "Total Biaya",
                    fee: "Fee",
                    jejaks: "Jejak",
                    tanggalDaftar: "Tanggal Daftar",
                    catatan: "Catatan",
                    grupRujukan: "Grup Rujukan",
                    pilihan: "Pilihan",
                    displayName1: "Display Name 1",
                    displayName2: "Display Name 2",
                    tts: "Text to Speech",
                    waktuTunggu: "Waktu Tunggu",
                    userId: "User Id",
                    tanggalKetidakhadiran: "Tanggal Ketidakhadiran",
                    hari: "Hari",
                    jamMulai: "Jam Mulai",
                    jamSelesai: "Jam Selesai",
                    tanggalMulai: "Tanggal Mulai",
                    tanggalSelesai: "Tanggal Selesai",
                    tugas: "Tugas",
                    periode: "Periode",
                    tanggalRujukan: "Tanggal Rujukan",
                    status: "Status",
                    year: "Tahun",
                    month: "Bulan",
                    label: "Label",
                    judul: "Judul",
                    subJudul: "Sub Judul",
                    keterangan: "Keterangan",
                    gambar: "Gambar",
                    antrianManual: "Antrian Manual",
                    tanggal: "Tanggal",
                    queueType: "Queue Type",
                    perangkatAntrian: "Perangkat",
                    auto: "Auto",
                    manual: "Manual",
                    periodeAwal: "Periode Awal",
                    periodeAkhir: "Periode Akhir",
                    filterTanggal: "Filter Tanggal",
                    username: "Username",
                    rocketChatId: "Rocket Chat Id",
                    nameTemplate: "Nama Template",
                    content: "Content",
                    type: "Tipe",
                    templateId: "Id Template",
                    transactionId: "Id Transaksi",
                    sendStatus: "Send Status",
                    deliveryStatus: "Delivery Status",
                    recipient: "Penerima",
                    createdDate: "Tanggal Buat",
                    lastModifiedDate: "Tanggal Update",
                    nomorRegistrasi: "Nomor Registrasi",
                    historyReservasi: "Riwayat",
                    durationInMinute: "Durasi",
                    required: "Dibutuhkan",
                    less: "Kurang",
                    amount: "Jumlah"
                },
                report: {
                    daftarPerujuk: "Daftar Perujuk",
                    statusRujukan: "Status Rujukan",
                    statusFeeKeuangan: "Status Referral Fee Keuangan",
                    detailTransaksi: "Detail Transaksi",
                    pendapatanReferral: "Pendapatan Referral",
                    printTicket: "Cetak Tiket",
                    medicalNo: "Nomor Rekam Medik",
                    timeTrackingReservasi: "Time Tracking Reservation"
                },
                formInternal: {
                    title: "Kejadian Internal",
                    date: "Tanggal",
                    select: "Pilih Dokter",
                    pilihJam: "Pilih Jam",
                    jamMulai: "Jam Mulai",
                    jamSelesai: "Jam Selesai",
                    remark: "Catatan",
                    slotType: "Tipe Slot",
                    medicalNo: "Nomor Rekam Medik"
                }
            }
        };

        const i18n = new VueI18n({
            locale: "id",
            messages
        });

        return i18n;
    }
}
