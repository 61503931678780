import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const userPatient = "/user-patients";
const defaultConfigs = { params: { "size": 1000 } };
import createRequestOption from './RequestParamUtil';
export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(userPatient, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    fetch(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${userPatient}`, defaultConfigs);
    },
    search(payload) {
        const config = createRequestOption(payload);
        return Repository.get(`/_search/user-patients`, config);
    },
}
