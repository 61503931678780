import { RepositoryFactory } from "../repositories/RepositoryFactory";
const ResourceRepository = RepositoryFactory.get("printerResource");
const DriveThruRepository = RepositoryFactory.get("driveThru");
const HolidayRepository = RepositoryFactory.get("holiday")
import Axios from "axios";
import BlockUI from "../components/_js/BlockUI";
const blockUI = new BlockUI();

export default {
    namespaced: true,
    state: {
        isModalVisible: false,
        isForm: false,
        formCount: null,
        isQrCodeScan: false,
        isFormSave: false,
        isBooking: false,
        patientForms: [],
        validPatientForms: [],
        optionsPaket: [],
        primaryPatients: {},
        totalPrice: 0,
        nations: [],
        dtBooking: {},
        qrCodeLink: null,
        isLoading: true,
        isShowPatientModal: false,
        isBookingLoading: true,
        isShowRequiredModal: false,
        tglKunjungan: moment().format("YYYY-MM-DD"),
        isFotoPlat: false,
        fotoPlat: null,
        paketTestUpload: {},
        imagePlatDetail: null,
        uploadModal: false,
        dataBooking: {},
        isCropperModal: false,
        defaultRangeDateBooking: null,
        holidayDates: [],
        offDays: [],
        pastienModalData: {}
    },
    mutations: {
        SET_OFF_DAYS(state, payload) {
            state.offDays = payload;
        },
        SET_DEFAULT_HOLIDAY_DATES(state, payload) {
            state.holidayDates = [...payload, ...state.holidayDates ];
        },
        SET_DEFAULT_RANGE_DATE_BOOKING(state, payload) {
            state.defaultRangeDateBooking = payload;
        },
        SET_FOTO_PLAT(state, payload) {
            state.fotoPlat = payload;
            state.isFotoPlat = true;
        },
        SET_IS_CROPPER_MODAL(state, payload) {
            state.isCropperModal = payload;
        },
        SET_DATA_BOOKING(state, payload) {
            state.dataBooking.data = payload.booking;
        },
        SET_UPLOAD_MODAL(state, payload) {
            state.uploadModal = payload;
        },
        SET_IMAGE_PLAT_DETAIL(state, payload) {
            state.imagePlatDetail = payload;
        },
        SET_PAKET_TEST_UPLOAD(state, payload) {
            state.paketTestUpload = payload;
        },
        SET_IS_FOTO_PLAT(state, payload) {
            state.isFotoPlat = payload;
        },
        SET_TGL_KUNJUNGAN(state, payload) {
            state.tglKunjungan = payload;
        },
        SET_IS_BOOKING_LOADING(state, payload) {
            state.isBookingLoading = payload;
        },
        SET_IS_SHOW_REQUIRED_MODAL(state, payload) {
            state.isShowRequiredModal = payload;
        },
        SET_PATIENT_FORMS(state, payload) {
            state.patientForms = payload;
        },
        SET_IS_SHOW_PATIENT_MODAL(state, payload) {
            state.isShowPatientModal = payload;
        },
        SET_IS_LOADING(state, payload) {
            state.isLoading = payload;
        },
        SET_QR_CODE_LINK(state, payload) {
            state.qrCodeLink = payload;
        },
        SET_IS_BOOKING(state, payload) {
            state.isBooking = payload;
        },
        SET_MODAL_VISIBLE(state, payload) {
            state.isModalVisible = payload;
        },
        SET_FORM(state, payload) {
            state.isForm = payload;
        },
        SET_FORM_SAVE(state, payload) {
            state.isFormSave = payload;
        },
        SET_FORM_COUNT(state, payload) {
            state.formCount = payload;
        },
        CAL_FORM_COUNT(state, payload) {
            if (payload == "add") {
                state.formCount++;
            }
            if (payload == "sub") {
                state.formCount--;
            }
        },
        SET_QR_CODE_SCAN(state, payload) {
            state.isQrCodeScan = payload;
        },
        DELETE_PATIENT_FORM(state, payload) {
            state.patientForms[payload].isDeleted = true;
        },
        RESTORE_PATIENT_FORM(state, payload) {
            state.patientForms[payload].isDeleted = false;
        },
        FILTER_IS_DELETED_PATIENT_FORM(state) {
            state.validPatientForms = state.patientForms.filter(
                patientForm => patientForm.isDeleted == false
            );
        },
        SET_OPTIONS_PAKET(state, payload) {
            state.optionsPaket = payload;
        },
        SET_VALID_PATIENT_FORMS(state) {
            state.totalPrice = 0;
            state.primaryPatients = {};
            if (state.validPatientForms.length > 0) {
                state.validPatientForms.forEach((e, index) => {
                    state.primaryPatients[index] = `${e.dataPasien.namaDepan}${
                        e.dataPasien.namaBelakang
                            ? " " + e.dataPasien.namaBelakang
                            : ""
                    }`;
                    e.dataKlinis.tipe_test.forEach(el => {
                        state.totalPrice += el.price;
                    });
                });
            }
        },
        SET_NATIONS(state, payload) {
            state.nations = payload;
        },
        SET_PATIENT_MODAL_DATA(state, payload) {
            state.pastienModalData = payload;
        },
        SET_DT_BOOKING(state, payload) {
            state.dtBooking = payload;
        }
    },
    actions: {
        async getDefaultRangeDate({ commit }) {
            try {
                const promisess = await  Promise.all([
                    await ResourceRepository.getBackOfficeEnv(),
                    await DriveThruRepository.getHolidayDates(),
                    // await HolidayRepository.getHolidays()
                ])
                const globalEnv = promisess[0];
                const holidayDates = promisess[1]
                // const holidays = promisess[2]
                let holidayDatesArray = [];
                holidayDates.data.forEach(el => {
                    if (el.startDate && el.endDate) {
                        let startDate = moment(el.startDate, "YYYY-MM-DD");
                        let endDate = moment(el.endDate, "YYYY-MM-DD");
                        let now = moment(startDate);
                        while (now.isSameOrBefore(endDate)) {
                            holidayDatesArray.push(now.format("DD-MM-YYYY"));
                            now.add(1, "days");
                        }
                    } else if (el.startDate && !el.endDate) {
                        holidayDatesArray.push(
                            moment(el.startDate).format("DD-MM-YYYY")
                        );
                    }
                });
                if (holidayDatesArray.length > 0) {
                    commit("SET_DEFAULT_HOLIDAY_DATES", holidayDatesArray);
                }
                //offDays
                commit("SET_OFF_DAYS", globalEnv.data.offDays);
                commit(
                    "SET_DEFAULT_RANGE_DATE_BOOKING",
                    globalEnv.data.defaultRangeDatePcrBooking
                );
            } catch (error) {
                console.log(error);
            }
        },
        async getPaket({ commit }) {
            try {
                const paket = await DriveThruRepository.getPaket();
                commit("SET_OPTIONS_PAKET", paket.data);
            } catch (error) {
                console.log(error);
            }
        },
        async getNations({ commit }) {
            try {
                const nationsData = await DriveThruRepository.getNations();
                commit("SET_NATIONS", nationsData.data);
            } catch (error) {
                console.log(error);
            }
        },
        async getBooking({ commit, dispatch }, payload) {
            try {
                commit("SET_IS_BOOKING_LOADING", true);
                const data = await DriveThruRepository.getPcrOrder(payload);

                commit("SET_DT_BOOKING", data.data);
                dispatch("getPhotoPlat", data.data.encryptedId);
                commit("SET_IS_BOOKING_LOADING", false);
                setTimeout(() => {
                    if (data.data.testPemeriksaan.length > 3) {
                        let container = document.getElementsByClassName(
                            "booking-test-cards"
                        );
                        container[0].style.justifyContent = "unset";
                    }
                }, 200);
            } catch (error) {
                commit("SET_IS_BOOKING_LOADING", true);
                console.log(error);
            }
        },
        async updatePatientPcr({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.putPatientPcr(
                    payload.data
                );
                swal.fire(`Data Pasien Berhasil Di Perbarui`, "success");
                setTimeout(() => {
                    blockUI.unblockPage();
                    dispatch("getBooking", payload.orderId);
                    swal.fire(`Paket Berhasil Di Perbarui`, "success");
                }, 100);
            } catch (error) {
                blockUI.unblockPage();
                console.log(error);
                swal.fire(`Data Pasien Gagal Di Perbarui`, "error");
            }
        },
        async updatePaketPatient({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                await DriveThruRepository.updatePaketBooking(payload);
                setTimeout(() => {
                    blockUI.unblockPage();
                    dispatch("getBooking", payload.orderId);
                    swal.fire(`Paket Berhasil Di Perbarui`, "success");
                }, 100);
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Paket Gagal Di Perbarui`, "error");
                console.log(error);
            }
        },
        async postNoHis({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postNoHis(payload.data);
                if (data.status == 200) {
                    setTimeout(() => {
                        dispatch("getBooking", payload.id);
                        swal.fire(`Nomor HIS Berhasil Di Perbarui`, "success");
                        blockUI.unblockPage();
                    }, 100);
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Nomor HIS Gagal Di Perbarui`, "error");
                console.log(error);
            }
        },
        async postPcr({ commit }, payload) {
            const pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                    "(\\#[-a-z\\d_]*)?$",
                "i"
            );
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postPcr(payload.data);
                if (
                    data.status == 200 &&
                    (pattern.test(data.data.data) || data.data.data === "")
                ) {
                    localStorage.removeItem("bulkForm");
                    blockUI.unblockPage();
                    Swal.fire({
                        title: "Success"
                    }).then(() => {
                        payload.router.push({
                            name: "drive-thru-view",
                            params: { id: data.data.orderId }
                        });
                        commit("SET_FORM_COUNT", 0);
                        commit("SET_FORM", false);
                        commit("SET_FORM_SAVE", false);
                    });
                } else {
                    blockUI.unblockPage();
                    Swal.fire("Error", data.data.data, "error");
                }
                return;
            } catch (error) {
                blockUI.unblockPage();
                let arr = error.response.data.detail.split(" ");
                let errorMsg = "";
                arr.forEach(el => {
                    if (el.match(/\"/)) {
                        errorMsg += el;
                    }
                });
                let errArr = errorMsg.split(":");
                let mySubString = errArr[errArr.length - 1].substring(
                    errArr[errArr.length - 1].indexOf("[") + 1,
                    errArr[errArr.length - 1].lastIndexOf("]")
                );
                let errorMessage = errArr[0] + " " + mySubString;
                errorMessage = errorMessage.replace(/"/g, "");
                Swal.fire(error.response.data.title, errorMessage, "error");
            }
        },
        async getOrderId({ commit }, payload) {
            try {
                return Axios.get(payload);
            } catch (error) {
                console.log(error);
            }
        },
        async postNoPlat({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postPlatNo(payload);
                if (data.status == 200) {
                    blockUI.unblockPage();
                    dispatch("getBooking", payload.id);
                    swal.fire(`Plat Nomor Berhasil Di Perbarui`, "success");
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Plat Nomor Gagal Di Perbarui`, "error");
                console.log(error);
            }
        },
        async getPhotoPlat({ commit, dispatch }, payload) {
            try {
                const getPlat = "/api/pcr/plat";
                const baseUrl = process.env.VUE_APP_API_URL;

                commit("SET_FOTO_PLAT", `${baseUrl}${getPlat}/${payload}`);
            } catch (error) {
                console.log(error);
            }
        },
        async pasienDatang({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postPasienDatang(
                    payload.orderId
                );
                if (data.status == 200) {
                    setTimeout(() => {
                        blockUI.unblockPage();
                        dispatch("getBooking", payload.orderId);
                        swal.fire(`Pasien Berhasil Datang`, "success");
                    }, 100);
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Gagal Update Pasien Datang`, "error");
                console.log(error);
            }
        },
        async sampelDiterima({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postSampelDiterima(
                    payload.data
                );
                if (data.status == 200) {
                    blockUI.unblockPage();
                    payload.table.getData();
                    swal.fire(`Sampel Berhasil Diterima`, "success");
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Sampel Gagal Diterima`, "error");
                console.log(error);
            }
        },
        async postResult({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postKirimHasil(payload);

                if (data.status == 200) {
                    setTimeout(() => {
                        commit("SET_MODAL_VISIBLE", false);
                        blockUI.unblockPage();
                        payload.table.getData();
                        swal.fire(`Hasil Berhasil Di Upload`, "success");
                    }, 100);
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Hasil Gagal Di Upload`, "error");
                console.log(error);
            }
        },
        async postUbahMetodePembayaran({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postUbahPembayaran(
                    payload
                );
                if (data.status == 200) {
                    setTimeout(() => {
                        blockUI.unblockPage();
                        dispatch("getBooking", payload.orderId);
                        swal.fire(
                            `Metode Pembayaran Berhasil Di Perbarui`,
                            "success"
                        );
                    }, 500);
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Metode Pembayaran Gagal Di Perbarui`, "error");
                console.log(error);
            }
        },
        async postBookingCancel({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.postCancelBooking(
                    payload.data
                );
                if (data.status == 200) {
                    blockUI.unblockPage();
                    dispatch("getBooking", payload.orderId);
                    swal.fire(`Booking Berhasil Dibatalkan`, "success");
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Booking Gagal Dibatalkan`, "error");
                console.log(error);
            }
        },
        async postPatientOcr({ commit, dispatch }, payload) {
            try {
                const data = await DriveThruRepository.postOcr(payload);
                data.formIndex = payload.formIndex;
                return data;

                // if (data.status == 200) {
                //     dispatch("getBooking", payload.orderId);
                //     swal.fire(`Patient Berhasil Di Perbarui`, "success");
                // }
            } catch (error) {
                console.log(error);
            }
        },
        async postManualPaidOffline({ commit, dispatch }, payload) {
            try {
                blockUI.blockPage();
                const data = await DriveThruRepository.manualPaidOff(payload);
                if (data.status == 200) {
                    setTimeout(() => {
                        blockUI.unblockPage();
                        dispatch("getBooking", payload);
                        swal.fire(
                            `Status Pembayaran Berhasil Di Perbarui`,
                            "success"
                        );
                    }, 500);
                }
            } catch (error) {
                blockUI.unblockPage();
                swal.fire(`Status Pembayaran Gagal Di Perbarui`, "error");
                console.log(error);
            }
        }
    }
};
